import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import { RenderCurrency } from "../../../../Utils/renderField/renderReadField";
import { translator as t } from "../../../../../../utility/traducer";
import Contacto from "../Contacto/index";


class Precio extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,    
    idioma: PropTypes.string.isRequired,
    envioContacto: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { open: false, precio: false, };
    this.closeModal = this.closeModal.bind(this);
    this.precioModal = this.precioModal.bind(this);
  }

  closeModal(data) {
    if (data) {
      this.props.envioContacto({...data}, this.props.data);
    }
    this.setState({open: false});    
  }

  precioModal(data){
    this.setState({precio: false});
  }

  verPrecio(){
    this.setState({precio: true});
  }

  reservar() {
    this.props.cargaStand();
    this.setState({open: true});
  }


  render() {
    const { data, idioma } = this.props;

    return (
      <div className="d-flex flex-column align-items-center w-100">
        <div className="d-flex flex-row flex-lg-column justify-content-center w-100">
          <div className="d-flex flex-row flex-sm-column justify-content-center align-items-center">
            <div className="d-flex flex-column align-items-center">
              <h1><span className="precio">{t.translate('Area')}&nbsp; {data.area} {t.translate('Mts')}</span></h1>
            </div>
            <div className="d-flex d-sm-none separador" />
            <button onClick={() => this.verPrecio()} className="btn btn-primary m-3">{t.translate('Consultar precio')}</button>
          </div>
          <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={this.state.open} onClose={this.closeModal} classNames={{modal:"modal-lg p-0"}} >
            <div className="">
              <div className="grid-titulo d-flex align-items-center"><i className="fa fa-3x fa-info-circle" />{t.translate('Completa el formulario para solicitar la reserva')}</div>
              <Contacto styles={{modal: {minWidth: "100%"}}} onSubmit={this.closeModal} close={() => this.closeModal()}/>
            </div>
          </Modal>
          <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={this.state.precio} onClose={this.precioModal} classNames={{modal:"modal-lg p-0 m-0 w-100 m-sm-auto"}} >
            <div className="">
              <div className="grid-titulo d-flex align-items-center">
                <i className="fa fa-3x fa-info-circle" />
                  <div className="db-flex flex-column">{t.translate('Stand No ')}{data.id}
                    <div className="font-weight-light">{t.translate('Area ')}{data.area}{t.translate(' metros')}</div>
                  </div>                
              </div>
              <div styles={{modal: {minWidth: "100%"}}} className="d-flex flex-column p-2 p-sm-4 justify-content-between">
                  <div className="d-flex flex-column">                  
                    <div  className="p-2 font-weight-bold">{t.translate('TARIFA PARA SOCIOS EN QUETZALES')}</div>
                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa productor post lanzamiento')}</div>
                          <h2><RenderCurrency className="precio p-2" value={data.so_productor_q}  prefix={"Q" } /></h2>                      
                      </div>
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa casa comercial post lanzamiento')}</div>
                        <h2><RenderCurrency className="precio p-2" value={data.so_casa_q}  prefix={"Q" } /></h2>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">                  
                    <div  className="p-2 font-weight-bold mt-4">{t.translate('TARIFA PARA SOCIOS EN DÓLARES')}</div>
                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa productor post lanzamiento')}</div>
                        <h2><RenderCurrency className="precio p-2" value={data.so_productor_d}  prefix={"$" } /></h2>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa casa comercial post lanzamiento')}</div>
                        <h2><RenderCurrency className="precio p-2" value={data.so_casa_d}  prefix={"$" } /></h2>
                      </div>
                    </div>
                    </div>
                  <div className="d-flex  flex-column">                  
                    <div  className="p-2 font-weight-bold mt-4">{t.translate('TARIFA EN QUETZALES')}</div>
                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa productor post lanzamiento')}</div>
                        <h2><RenderCurrency className="precio p-2" value={data.ns_productor_q}  prefix={"Q" } /></h2>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa casa comercial post lanzamiento')}</div>
                        <h2><RenderCurrency className="precio p-2" value={data.ns_casa_q}  prefix={"Q" } /></h2>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">                  
                    <div  className="p-2 font-weight-bold mt-4">{t.translate('TARIFA EN DÓLARES')}</div>
                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa productor post lanzamiento')}</div>
                        <h2><RenderCurrency className="precio p-2" value={data.ns_productor_d}  prefix={"$" } /></h2>                      
                      </div>
                      <div className="d-flex flex-column">
                        <div className="p-2">{t.translate('Tarifa casa comercial post lanzamiento')}</div>
                        <h2><RenderCurrency className="precio p-2" value={data.ns_casa_d}  prefix={"$" } /></h2>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button onClick={() => this.precioModal()} className="btn btn-secondary m-3">{t.translate('Cerrar')}</button>
                    <button onClick={() => this.reservar()} className="btn btn-default m-3">{t.translate('Reservar stand')}</button>
                  </div>
                </div>                           
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Precio;

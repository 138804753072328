import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";
import "./entradas.css";
import {logo_en, logo_es} from "utility/variables";
import {
  APOYO,
  ESTUDIANTE,
  ENTRADA_VIRTUAL_CONGRESO_AGUACATE,
  FULL_ACCESS_CONGRESO_AGUACATE,
  APOYO_CONGRESO_AGUACATE,
  FULL_ACCESS,
  UN_DIA,
  MARBETES_PARQUEO,
  PISO_EXHIBICION,
  PISO_EXHIBICION_SABADO,
  CONFERENCIA,
  STAFF,
  PRENSA,
  INVITADO_ESPECIAL,
  COMITE_ORGANIZADOR,
  COMPRADOR,
  CONFERENCISTA,
  PRODUCTOR
} from "utility/variables";
import swal from 'sweetalert2';
import {
  full_access,
  un_dia,
  estudiante,
  full_access_congreso_aguacate,
  apoyo_congreso_aguacate,
  apoyo,
  marbetes_parqueo,
  piso_exhibicion,
  piso_exhibicion_sabado,
  conferencia,
  confenrencista,
  staff,
  prensa,
  invitado_especial,
  comite_organizador,
  comprador,
  productor,
  foto_un_dia,
  foto_estudiante,
  foto_full_access_congreso_aguacate,
  foto_apoyo_congreso_aguacate,
  foto_apoyo ,
  foto_full_access,
  foto_marbetes_parqueo,
  foto_piso_exhibicion,
  foto_piso_exhibicion_sabado,
  foto_conferencia,
  foto_staff,
  foto_prensa,
  foto_invitado_especial,
  foto_comite_organizador,
  foto_comprador,
  foto_productor,
  foto_full_access_blanco,
  foto_un_dia_blanco,
  foto_estudiante_blanco,
  foto_apoyo_blanco,
  foto_marbetes_parqueo_blanco,
  foto_piso_exhibicion_blanco,
  foto_piso_exhibicion_sabado_blanco,
  foto_conferencia_blanco,
  foto_staff_blanco,
  foto_prensa_blanco,
  foto_invitado_especial_blanco,
  foto_comite_organizador_blanco,
  foto_comprador_blanco,
  foto_productor_blanco
} from "utility/variables";



class Oportunidades extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.ventanaCarrito = this.ventanaCarrito.bind(this);
  }

  componentWillMount() {
    this.props.getProductos();
  }

  ventanaCarrito(){
    swal.fire({
      title: t.translate('Agregado al carrito'),
      type: 'info',
      html:
        t.translate('El producto se ha agregado')+
        '<br />'+t.translate('al carrito  exitosamente'),
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<a style="color: white"href="/#/tienda/carrito">'+t.translate('Ir al Carrito')+'</a>',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        t.translate('Seguir comprando'),
      cancelButtonAriaLabel: 'Thumbs down',
    })
  }

  render() {
    const { data, idioma, usuario } = this.props;
    const logo = idioma === "es" ? logo_es : logo_en;

    return (
      <div id="entradas">
        <div className="difuminado" />
        <div className="w-100 pt-4 pb-4 pl-2 pr-2 p-sm-5 d-flex d-sm-none flex-wrap justify-content-around position-relative">
          {data.results.map((entrada, index) => {
            let style;
            let foto;
            let fondo;
            switch (entrada.tipo_entrada) {
              case ENTRADA_VIRTUAL_CONGRESO_AGUACATE:
                style = full_access_congreso_aguacate;
                foto = foto_full_access_congreso_aguacate;
                fondo = "fondo_full_access_congreso_aguacate"
                break;
              case FULL_ACCESS_CONGRESO_AGUACATE:
                style = full_access_congreso_aguacate;
                foto = foto_full_access_congreso_aguacate;
                fondo = "fondo_full_access_congreso_aguacate"
                break;
              case APOYO_CONGRESO_AGUACATE:
                style = apoyo_congreso_aguacate;
                foto = foto_apoyo_congreso_aguacate;
                fondo = "fondo_apoyo_congreso_aguacate"
                break;
              case FULL_ACCESS:
                style = full_access;
                foto = foto_full_access_blanco;
                fondo = "fondo_full_access";
                break;
              case UN_DIA:
                style = un_dia;
                foto = foto_un_dia_blanco;
                fondo = "fondo_un_dia";
                break;
              case ESTUDIANTE:
                style = estudiante;
                foto = foto_estudiante_blanco;
                fondo = "fondo_estudiante";
                break;
              case APOYO:
                style = apoyo;
                foto = foto_apoyo_blanco;
                fondo = "fondo_apoyo";
                break;
              case MARBETES_PARQUEO:
                style = marbetes_parqueo;
                foto = foto_marbetes_parqueo_blanco;
                fondo = "fondo_marbetes_parqueo";
                break;
              case PISO_EXHIBICION:
                style = piso_exhibicion;
                foto = foto_piso_exhibicion_blanco;
                fondo = "fondo_piso_exhibicion";
                break;
              case PISO_EXHIBICION_SABADO:
                style = piso_exhibicion_sabado;
                foto = foto_piso_exhibicion_sabado_blanco;
                fondo = "fondo_piso_exhibicion_sabado";
                break;
              case CONFERENCIA:
                style = conferencia;
                foto = foto_conferencia_blanco;
                fondo = "fondo_conferencia";
                break;
              case CONFERENCISTA:
                style = confenrencista;
                foto = foto_conferencia_blanco;
                fondo = "fondo_conferencia";
                break;
              case STAFF:
                style = staff;
                foto = foto_staff_blanco;
                fondo = "fondo_staff";
                break;
              case PRENSA:
                style = prensa;
                foto = foto_prensa_blanco;
                fondo = "fondo_prensa";
                break;
              case INVITADO_ESPECIAL:
                style = invitado_especial;
                foto = foto_invitado_especial_blanco;
                fondo = "fondo_invitado_especial";
                break;
              case COMITE_ORGANIZADOR:
                style = comite_organizador;
                foto = foto_comite_organizador_blanco;
                fondo = "fondo_comite_organizador";
                break;
              case COMPRADOR:
                style = comprador;
                foto = foto_comprador_blanco;
                fondo = "fondo_comprador";
                break;
              case PRODUCTOR:
                style = productor;
                foto = foto_productor_blanco;
                fondo = "fondo_productor";
                break;
            }

            const precios = [];
            const precios_socios = [];
            entrada.precios.forEach((precio) => {
              if (precio.lista_precios.toLowerCase().includes("no"))
                precios.push(precio);
              else
                precios_socios.push(precio);
            });
            return (<div key={entrada.id} className={`${fondo} d-flex entrada-small flex-column p-4 mt-3 principal mb-3`}>
              <div className="contenedor-imagen align-self-center mt-5 mb-3">
                <img src={foto} onError={(e)=>{e.target.onerror = null; e.target.src=logo}} alt=""/>
              </div>
              <div className="d-flex p-2 flex-column align-items-center h-100">
                <center><h5><strong>{idioma === "es" ? entrada.texto_comercial : entrada.texto_comercial_ingles}</strong></h5></center>
                <small style={{lineHeight:"14px", maxHeight: "112px", overflow: "hidden"}}>{idioma === "es" ? entrada.descripcion : entrada.descripcion_ingles}</small>
                
                <div className="d-flex flex-row flex-wrap precios-entrada mt-auto mb-3">
                  {usuario.es_socio ? precios_socios.map((precio, index) => {
                    let clase = "pr-2";
                    if (index !== (precios.length - 1)) {
                      clase = clase + " precio-entrada";
                    }
                    if (index > 0) {
                      clase = clase + " pl-2 precio-entrada-fin"
                    }
                    return (
                      <h3 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h3>
                    )
                  }) : precios.map((precio, index) => {
                    let clase = "pr-2";
                    if (index !== (precios.length - 1)) {
                      clase = clase + " precio-entrada";
                    }
                    if (index > 0) {
                      clase = clase + " pl-2 precio-entrada-fin"
                    }
                    return (
                      <h3 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h3>
                    )
                  })}
                </div>
                <button className="btn btn-default mt-2" onClick={() => {
                  this.props.agregarCarrito(entrada)
                }}>{t.translate("Agregar al carrito")}</button>
              </div>
            </div>)
          })}
        </div>
        <div className="w-100 pt-4 pb-4 pl-2 pr-2 p-sm-5 d-none d-sm-flex flex-wrap justify-content-around position-relative">
          {data.results.map((entrada, index) => {
            let style;
            let foto;
            switch (entrada.tipo_entrada) {
              case ENTRADA_VIRTUAL_CONGRESO_AGUACATE:
                style = full_access_congreso_aguacate;
                foto = foto_full_access_congreso_aguacate;
                break;
              case FULL_ACCESS_CONGRESO_AGUACATE:
                style = full_access_congreso_aguacate;
                foto = foto_full_access_congreso_aguacate;
                break;
              case APOYO_CONGRESO_AGUACATE:
                style = apoyo_congreso_aguacate;
                foto = foto_apoyo_congreso_aguacate;
                break;
              case FULL_ACCESS:
                style = full_access;
                foto = foto_full_access;
                break;
              case UN_DIA:
                style = un_dia;
                foto = foto_un_dia;
                break;
              case ESTUDIANTE:
                style = estudiante;
                foto = foto_estudiante;
                break;
              case APOYO:
                style = apoyo;
                foto = foto_apoyo;
                break;
              case MARBETES_PARQUEO:
                style = marbetes_parqueo;
                foto = foto_marbetes_parqueo;
                break;
              case PISO_EXHIBICION:
                style = piso_exhibicion;
                foto = foto_piso_exhibicion;
                break;
              case PISO_EXHIBICION_SABADO:
                style = piso_exhibicion_sabado;
                foto = foto_piso_exhibicion_sabado;
                break;
              case CONFERENCIA:
                style = conferencia;
                foto = foto_conferencia;
                break;
              case CONFERENCISTA:
                style = confenrencista;
                foto = foto_conferencia;
                break;
              case STAFF:
                style = staff;
                foto = foto_staff;
                break;
              case PRENSA:
                style = prensa;
                foto = foto_prensa;
                break;
              case INVITADO_ESPECIAL:
                style = invitado_especial;
                foto = foto_invitado_especial;
                break;
              case COMITE_ORGANIZADOR:
                style = comite_organizador;
                foto = foto_comite_organizador;
                break;
              case COMPRADOR:
                style = comprador;
                foto = foto_comprador;
                break;
              case PRODUCTOR:
                style = productor;
                foto = foto_productor;
                break;
            }

            const precios = [];
            const precios_socios = [];
            entrada.precios.forEach((precio) => {
              if (precio.lista_precios.toLowerCase().includes("no"))
                precios.push(precio);
              else
                precios_socios.push(precio);
            });
            return (
              <div style={style} key={entrada.id} className="entrada flex-1 d-flex flex-column flex-sm-row align-items-start m-2 m-sm-2 p-0 pl-sm-3 pt-sm-3 pr-sm-3">
                <div className="contenedor-imagen align-self-end">
                  <img src={foto} onError={(e)=>{e.target.onerror = null; e.target.src=logo}} alt=""/>
                </div>
                <div className="d-flex p-2 flex-column align-self-stretch">
                  <div><strong>{idioma === "es" ? entrada.texto_comercial : entrada.texto_comercial_ingles}</strong></div>
                  <small style={{lineHeight:"14px"}}>{idioma === "es" ? entrada.descripcion : entrada.descripcion_ingles}</small>
                  <div className="d-flex flex-row flex-wrap precios-entrada mt-3 mb-3">
                    {usuario.es_socio ? precios_socios.map((precio, index) => {
                      let clase = "pr-2";
                      if (index !== (precios.length - 1)) {
                        switch(entrada.tipo_entrada){
                          case PISO_EXHIBICION: clase = clase + " precio-entrada-gris";break;
                          case PISO_EXHIBICION_SABADO: clase = clase + " precio-entrada-gris";break;
                          case STAFF: clase = clase + " precio-entrada-gris";break;
                          default: clase = clase + " precio-entrada"; break;
                        }
                      }
                      if (index > 0) {
                        clase = clase + " pl-2 precio-entrada-fin"
                      }
                      return (
                        <h4 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h4>
                      )
                    }) : precios.map((precio, index) => {
                      let clase = "pr-2";
                      if (index !== (precios.length - 1)) {
                        switch(entrada.tipo_entrada){
                          case PISO_EXHIBICION: clase = clase + " precio-entrada-gris";break;
                          case PISO_EXHIBICION_SABADO: clase = clase + " precio-entrada-gris";break;
                          case STAFF: clase = clase + " precio-entrada-gris";break;
                          default: clase = clase + " precio-entrada"; break;
                        }
                      }
                      if (index > 0) {
                        clase = clase + " pl-2 precio-entrada-fin"
                      }
                      return (
                        <h4 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h4>
                      )
                    })}
                  </div>
                  <button className="btn btn-default align-self-start mt-auto" onClick={() => {
                    this.props.agregarCarrito(entrada);
                    this.ventanaCarrito();
                  }}>{t.translate("Agregar al carrito")}</button>
                </div>
              </div>
            )
          })}
        </div>
        <img className="verduras" src={require("assets/img/marco_verduras.png")} alt=""/>
      </div>
    );
  }
}

export default Oportunidades;

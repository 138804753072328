import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/administracion/productos';
import EdicionProducto from './EdicionProducto';


const ms2p = (state) => {
  const loader = state.productos !== 0;
  let photo;
  let es_entrada = false;
  try {
    photo = state.form.EdicionProducto.values.foto;
    es_entrada = state.form.EdicionProducto.values.es_entrada;
  } catch (e) {}
  return {
    ...state.productos,
    loader,
    es_entrada,
    photo
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EdicionProducto);

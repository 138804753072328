import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/tienda';
import Entradas from './Entradas';


const ms2p = (state) => {
  const { idioma } = state.planta;
  return {
    ...state.tienda,
    usuario: state.cuenta.usuario,
    idioma: idioma.codigo
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Entradas);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "utility/traducer";
import { RESERVADO,COMPRADO } from "utility/variables";
import { Link } from "react-router-dom";
import _ from "lodash";

const placeholder = require('assets/img/img_placeholder.png');
const reservado = require('assets/img/reservado.png');

class Reserva extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,    
  };

  
  render() {
    const { data } = this.props;
    let { ocupante } = data;
    
            
    if (ocupante)
          
      return (
        <div className="d-flex flex-row flex-lg-column align-items-center">
          <div className="ocupante d-flex flex-column justify-content-center align-items-center m-2">
            <img src={ocupante.tiene_imagen ? ocupante.url : placeholder} alt="" />
          </div>
          <div className="d-flex flex-column flex-2 align-items-center">
            <div className="p-2 slogan">{ocupante.Nombre}</div>
            {ocupante.SitioWeb ? <h4 className="m-2">
              <a href={ocupante.SitioWeb.includes('http') ? ocupante.SitioWeb : `http://${ocupante.SitioWeb}`}>{t.translate('Ver página')}</a>
            </h4>: ""}
          </div>
        </div>
      );
             
    
      
    if (data.estado === RESERVADO)
      return (
        <div className="d-flex flex-row flex-lg-column align-items-center">
          <div className="ocupante d-flex flex-column justify-content-center align-items-center m-2">
            <img style={{maxWidth: '75%'}} src={reservado} alt="" />
          </div>
          <div className="d-flex flex-column flex-2 align-items-center">
            <h2 className="m-2">{t.translate('Espacio reservado')}</h2>
          </div>
        </div>
      );
    return (
      <div className="d-flex flex-row flex-lg-column align-items-center">
        <div className="ocupante d-flex flex-column justify-content-center align-items-center m-2">
          <img src={placeholder} alt="" />
        </div>
        <div className="d-flex flex-column flex-2 align-items-center">
          <div className="p-2 slogan">{data.empresa}</div>
          <div className="m-2 actualizar">{t.translate('Si eres dueño de este espacio, porfavor actualiza tus datos')}</div>
          <a href="http://connecting.export.com.gt/" target="blank" className="btn btn-primary m-1 m-sm-3">{t.translate('Actualizar datos')}</a>
        </div>
      </div>
    );
  }
}

export default Reserva;

import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/login';
import { getMe,cerrarSesion } from "../../../../redux/modules/tienda/cuenta";
import Navbar from './Navbar';


const ms2p = (state) => {
  const url = window.location.href;
  return {
    ...state.login,
    url,
  };
};

const md2p = { getMe, cerrarSesion, ...actions };

export default connect(ms2p, md2p)(Navbar);

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/tienda';
import Carrito from './Carrito';


const ms2p = (state) => {
  const { carrito, animar } = state.tienda;
  let en_carrito = 0;
  carrito.forEach((item) => {
    en_carrito += item.cantidad;
  });
  return {
    en_carrito,
    animar,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Carrito);

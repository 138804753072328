import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "./usuarios.css";
import { Link } from "react-router-dom";
import swal from 'sweetalert2';

const logo_es = require("assets/img/logo_blanco_es.png");


class Usuarios extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {stand:null};
    this.editar = this.editar.bind(this);
    this.borrar = this.borrar.bind(this);           
  }

  editar(stand) {
    this.props.editar(stand);
    this.setState({stand: stand.id});
  }

  componentWillMount() {
    this.props.getUsuarios();
  }

  borrar(id){
    swal({
      title: 'Borrar',
      text: 'Esta seguro que quiere borrar al usuario',
      type: 'warning',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        this.props.deleteUsuario(id)          
      }
    })
  }

  render() {
    const { loading, data, search } = this.props;
    const options = {
      hideSizePerPage: true,
      noDataText: 'No hay registros',             
      prePage: 'Anterior', 
      nextPage: 'Siguiente', 
      firstPage: 'Primero', 
      lastPage: 'Último', 
    };
    options.page = this.props.page;
    options.onPageChange = this.props.getUsuarios;
    options.noDataText = loading ? "Cargando..." : options.noDataText;
    return (
      <div id="usuarios">
        <div className="">
          <div className="pt-5 pb-5 pl-1 pr-1 p-sm-5">
            <Link to="/usuarios/nuevo" className="btn btn-agregar m-1 mb-3">Agregar</Link><br />
            <div className="color-tabla">              
              <div className="buscador d-flex flex-row justify-content-between p-3 encabezado-azul">
                <div className="d-flex align-items-center mr-2">
                  <h2 className="d-none d-sm-inline-block m-0">Usuarios Administradores</h2>
                  <h4 className="d-inline-block d-sm-none m-0">Usuarios Administradores</h4>
                </div>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="toolbar-search">
                    <input className="form-control" type="text" placeholder="Buscar"
                      ref={node => {
                        this.buscar = node;
                        if (this.buscar) {
                          this.buscar.value = search;
                        }
                      }}
                      onChange={() => {
                        if (this.buscar) {
                          this.props.searchChange(this.buscar.value);
                        }
                      }}
                      autoComplete="off"
                    />
                    <span className="fa fa-search icon-search" />
                  </div>
                </div>
              </div>
              <BootstrapTable
                data={loading ? [] : data.results}
                remote
                hover
                fetchInfo={{ dataTotalSize: data.count }}
                options={options}
                pagination
              >
                <TableHeaderColumn
                  dataField="username"
                  dataSort
                  
                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return "--------"
                  }}
                >
                Usuario
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="first_name"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return "--------"
                  }}
                >
                  Nombre de usuario
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataAlign="center"
                  isKey
                  dataFormat ={(cell,row) =>{
                    return <div>
                        <Link to={"/usuarios/editar/" + cell}>
                          <img className="action-img" title="Editar" alt="Editar" src={require("assets/img/editar.png")}/>
                        </Link>
                        <img className="action-img" title="Borrar" alt="Borrar" src={require("assets/img/borrar.png")} 
                          onClick={()=>
                            this.borrar(cell)
                          }
                        />
                      </div>
                  }}
                >
                  Acciones
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
        </div>
    );
  }
}

export default Usuarios;

import { handleActions } from 'redux-actions';
import { api } from "api";
import { push } from "react-router-redux";
import {initialize as initializeForm} from 'redux-form';
import {ToastStore} from "react-toasts";
import {TIPOS_ENTRADA, UN_DIA} from "../../../utility/variables";
import { actions as printActions } from "Utils/Print";
import { constants as cuentaConstants } from "../tienda/cuenta";
import uuidv1  from "uuid/v1";
import _ from "lodash";
import swal from "../tienda/cuenta";

const DATA = 'ADMIN_ENTRADAS_DATA';
const PAGE = 'ADMIN_ENTRADAS_PAGE';
const SEARCH = 'ADMIN_ENTRADAS_SEARCH';
const LOADING = 'ADMIN_ENTRADAS_LOADING';
const UUID = 'ADMIN_ENTRADAS_UUID';
const FILTRO = 'ADMIN_ENTRADAS_FILTRO';

export const constants = {
};

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page=1) => (dispatch, getStore) => {
  dispatch(setLoading(true));
  const params = {page};
  const filtro = getStore().entradas.filtro;
  if (filtro !== undefined)
    params.estado = filtro;
  const { search } = getStore().entradas;
  const uuid = uuidv1();
  dispatch(setUuid(uuid));
  api.get('/entradas', {...params, search}).then((data) => {
    if (getStore().entradas.uuid === uuid) {
      dispatch(setData(data));
      dispatch(setPage(page));
    }
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const leer = (id) => (dispatch) => {
  dispatch(setLoading(true));
  api.get(`/entradas/${id}`).then((data) => {
    data.tipo_entrada = _.find(TIPOS_ENTRADA, {id: data.tipo_entrada});
    dispatch(initializeForm('EdicionProducto', data));
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const searchChange = search => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(listar());
};

export const eliminar = id => (dispatch) => {
  api.eliminar(`/entradas/${id}`).then((data) => {
    ToastStore.success("Entrada eliminada");
      dispatch(listar());
  }).catch(error => {
    ToastStore.error("Ha ocurrido un error, vuelva a intentar");
  }).finally(() => {});
};

export const editar = (id, data, imprimir) => (dispatch) => {
  dispatch(setLoading(true));
  api.put(`entradas/${id}`, {...data}).then((response) => {
    dispatch({type: ENTRADA, entrada: response});
    if (imprimir) {
      setTimeout(() => {
        printActions.print('reciboIngreso')
      }, 300);
    } else {
      dispatch(push("/tienda/perfil"));
      ToastStore.success("Edición exitosa");
    }
  }).catch(() => {
    swal(
      "ERROR",
      "Ha ocurrido un error al actualizar la información, por favor espera un momento y vuelve a intentar",
      "error"
    );
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const crear = (data, imprimir) => (dispatch) => {
  dispatch(setLoading(true));
  api.post('entradas', {...data}).then((response) => {
    dispatch({type: cuentaConstants.ENTRADA, entrada: response});
    if (imprimir) {
      setTimeout(() => {
        printActions.print('reciboIngreso');
        dispatch(push(`/administrar_entradas/${response.id}`));
      }, 300);
    } else {
      dispatch(push("/administrar_entradas"));
      ToastStore.success("Almacenamiento exitoso");
    }
  }).catch(() => {
    swal(
      "ERROR",
      "Ha ocurrido un error al actualizar la información, por favor espera un momento y vuelve a intentar",
      "error"
    );
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const setFiltro = (filtro) => (dispatch) => {
  dispatch({type: FILTRO, filtro});
  dispatch(listar(1 ));
};

export const setLoading = load => (dispatch, getStore) => {
  const loading = getStore().entradas.loading;
  if (load) {
    dispatch({type: LOADING, loading: loading + 1 });
  } else {
    dispatch({type: LOADING, loading: loading - 1 });
  }
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setSearch = search => ({
  type: SEARCH,
  search,
});

export const setData = data => ({
  type: DATA,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

export const setUuid = uuid => ({
  type: UUID,
  uuid,
});


export const actions = {
  listar,
  leer,
  editar,
  crear,
  eliminar,
  searchChange,
  setFiltro,
};

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [UUID]: (state, { uuid }) => {
    return {
      ...state,
      uuid,
    };
  },
  [FILTRO]: (state, { filtro }) => {
    return {
      ...state,
      filtro,
    };
  },
};

export const initialState = {
  data: {
  },
  loading: 0,
  search: '',
  uuid: '',
  page: 1,
  filtro: undefined,
};

export default handleActions(reducers, initialState);

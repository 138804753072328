import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NuevoForm from './form';
import { Redirect } from 'react-router-dom';
import "../forms.css"
const logo_es = require("assets/img/logo_blanco_es.png");

class Nuevo extends Component {
     
    constructor(props) {
      super(props);
      this.nuevo = this.nuevo.bind(this);
    }
  
    nuevo(data){
        if(data){
          this.props.newUsuario(data);
        }            
    }      
    componentWillMount() {
    }
  
    render() {
        return (
            <div id="formulario">
                <div className="d-flex flex-column">
                    <div className="p-2 p-sm-3 encabezado-azul">
                        <h1>Nuevo Usuario</h1>                        
                    </div>
                    <div className="p-2 p-sm-3 ml-3 mr-3">
                        <NuevoForm onSubmit={this.nuevo} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Nuevo

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/tienda';
import { login } from "../../../../../redux/modules/tienda/cuenta";
import Carrito from './Carrito';

const ms2p = (state) => {
  const { idioma } = state.planta;
  const { carrito } = state.tienda;
  const loginLoader = state.cuenta.loading;
  let en_carrito = 0;
  carrito.forEach((item) => {
    en_carrito += item.cantidad;    
  });      
  return {
    en_carrito,
    ...state.tienda,
    idioma: idioma.codigo,
    loginLoader,
    usuario: state.cuenta.usuario,
    loading: state.tienda.loading !== 0,
  };
};

const md2p = { ...actions, login };

export default connect(ms2p, md2p)(Carrito);
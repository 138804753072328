import React from 'react';
import { Field, reduxForm } from 'redux-form'
import {validate, validators} from 'validate-redux-form';
import { translator as t } from "traducer";
import { renderField, renderImagePicker, renderCurrency, renderTextArea, renderSwitch } from "Utils/renderField/renderField";
import Loader from "Utils/Loader";


const Form = props =>{
  const { handleSubmit, loader } = props;
  return(

    <form className="d-flex flex-column flex-md-row pt-3 align-items-start" onSubmit={handleSubmit}>
      <div className="mr-0 mr-md-4 mb-1">
        <Field name="codigoCrm" component={renderField} type="text" label={t.translate("Código de oportunidad*")} className="form-control"/>
      </div>
      <div className="mr-0 mr-md-4 mb-1">
        <Field name="codigoCliente" component={renderField} type="text" label={t.translate("Código de cliente*")} className="form-control"/>
      </div>
      <button className="btn btn-success mt-2 mt-md-0">
        <Loader active={loader} small>
          <strong>{t.translate('Ingresar')}</strong>
        </Loader>
        </button>
    </form>
  )
};

const CodigoOportunidadForm = reduxForm({
  // a unique name for the form
  form : 'CodigoOportunidadForm',
  validate: (data, props) => {
    return validate(data, {
      codigoCrm: validators.exists()(t.translate("Campo requerido")),
      codigoCliente: validators.exists()(t.translate("Campo requerido")),
    })
  }
})(Form);

export default CodigoOportunidadForm;

import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/tienda/cuenta';
import DetalleCompra from './DetalleCompra';


const ms2p = (state) => {
  const { idioma } = state.planta;
  return {
    ...state.cuenta,
    idioma: idioma.codigo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleCompra);
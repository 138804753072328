import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import _ from "lodash";
import {
  logo_blanco_es,
  logo_negro_es,
  logo_es,
  logo_agexport_blanco,
  logo_agexport_azul,
  UN_DIA,
  MARBETES_PARQUEO,
  PISO_EXHIBICION,
  PISO_EXHIBICION_SABADO,
  FULL_ACCESS,
  FULL_ACCESS_CONGRESO_AGUACATE,
  APOYO_CONGRESO_AGUACATE,
  ENTRADA_VIRTUAL_CONGRESO_AGUACATE,
  CONFERENCISTA,
  STAFF
} from "variables";
import { QRCode } from 'react-qr-svg';
import { PrintContainer, actions as printActions } from "Utils/Print";
import {Link} from "react-router-dom";


const logo4 = require("assets/img/gafete/logo4.png");

const nombre_icon = require("assets/img/gafete/nombre.png");
const empresa_icon = require("assets/img/gafete/empresa.png");
const correo_icon = require("assets/img/gafete/correo.png");

class DetalleEntrada extends Component {
  static propTypes = {
    entrada: PropTypes.object.isRequired,
  };

  componentWillMount() {
    try {
      this.props.getEntrada(this.props.match.params.id);
    } catch (e) {}
  }

  render() {
    const { entrada, edicion } = this.props;
    let logo_agritrade = logo_blanco_es;
    let logo_agexport = logo_agexport_blanco;
    let clase = "text-uppercase text-white texto-entradas";
    switch(entrada.tipo_entrada.id){
      case UN_DIA:
        clase = "text-uppercase text-white texto-un-dia";
        break;
      case MARBETES_PARQUEO:
        clase= "text-uppercase text-gris texto-entradas";
        logo_agritrade = logo_negro_es;
        logo_agexport = logo_agexport_azul;
        break;
      case PISO_EXHIBICION:
        clase= "text-uppercase text-gris texto-entradas";
        logo_agritrade = logo_negro_es;
        logo_agexport = logo_agexport_azul;
        break;
      case CONFERENCISTA:
        clase= "text-uppercase text-gris texto-entradas";
        logo_agritrade = logo_negro_es;
        logo_agexport = logo_agexport_azul;
        break;
      case FULL_ACCESS:
        clase= "text-uppercase text-gris texto-entradas";
        logo_agritrade = logo_negro_es;
        logo_agexport = logo_agexport_azul;
        break;
      case STAFF:
        clase= "text-uppercase text-gris texto-entradas";
        logo_agritrade = logo_negro_es;
        logo_agexport = logo_agexport_azul;
        break;
    }
    return (
      <div id="perfil-entrada" className="flex-1 m-1 recuadro p-3">
        <h3><strong>{t.translate('Vista Previa')}</strong></h3>
        <PrintContainer name="reciboIngreso">
          <div className="gafete">
            <img className="img-gafete top" src={entrada.tipo_entrada.top} alt=""/>
            <img className="img-gafete bottom" src={entrada.tipo_entrada.bottom} alt=""/>
            <div className="d-flex flex-row position-relative align-items-center">
              <div className="qr m-4">
                <QRCode
                  bgColor={"#FFFFFF"}
                  fgColor={"#000000"}
                  level={"H"}
                  style={{width: "3.5cm"}}
                  value={!!entrada.qr ? entrada.qr : "vista previa agritradecentralamerica"}
                />
              </div>
              <div className="d-flex flex-column align-items-center flex-1">
                {entrada.tipo_entrada.value != FULL_ACCESS_CONGRESO_AGUACATE && 
                  entrada.tipo_entrada.value != APOYO_CONGRESO_AGUACATE &&
                  entrada.tipo_entrada.value != ENTRADA_VIRTUAL_CONGRESO_AGUACATE &&
                  <h3 className={clase}><center><strong>{t.translate(entrada.tipo_entrada.label)}</strong></center></h3>
                }
              </div>
            </div>
            <div className="d-flex flex-column mt-4 justify-content-around" style={{height: "4cm"}} >
              <div className="d-flex align-items-center p-3 ml-4">
                <img style={{maxWidth:"1cm"}} className="mr-2" src={nombre_icon} alt=""/>
                <div style={{fontSize: "18px"}} className="text-uppercase"><strong>{!!entrada.nombre ? `${entrada.nombre} ${!!entrada.apellido ? entrada.apellido : ""}` : t.translate("Nombre aquí")}</strong></div>
              </div>
              <div className="d-flex align-items-center p-3 ml-4">
                <img style={{maxWidth:"1cm"}} className="mr-2" src={empresa_icon} alt=""/>
                <div className="d-flex flex-column">
                  <strong>{t.translate("Empresa")}</strong>
                  <div>{!!entrada.empresa ? entrada.empresa : t.translate("Empresa aquí")}</div>
                </div>
              </div>
              <div className="d-flex align-items-center p-3 ml-4">
                <img style={{maxWidth:"1cm"}} className="mr-2" src={correo_icon} alt=""/>
                <div className="d-flex flex-column">
                  <strong>{t.translate("Correo")}</strong>
                  <div>{!!entrada.correo ? entrada.correo : t.translate("Correo aquí")}</div>
                </div>
              </div>
            </div>
            {entrada.tipo_entrada.value != FULL_ACCESS_CONGRESO_AGUACATE && 
              entrada.tipo_entrada.value != APOYO_CONGRESO_AGUACATE && 
              entrada.tipo_entrada.value != ENTRADA_VIRTUAL_CONGRESO_AGUACATE && 
              <React.Fragment>
                <img src={logo_agritrade} className="img-1 logo" alt=""/>
                <small className="texto-patrocinador">{t.translate("Patrocinador de gafete")}</small>
                <img src={logo_agexport} className="img-3 logo-2" alt=""/>
                <img src={logo4} className="img-2 patrocinador" alt=""/>
              </React.Fragment>
            }
          </div>
        </PrintContainer>
        {!edicion && (
          <div className="mt-3 text-center">
            <Link to={"/tienda/perfil"} className={"btn btn-secondary m-1"} ><strong>Cancelar</strong></Link>
            <button
              onClick={(e) => {
                e.preventDefault();
                printActions.print('reciboIngreso')
                // window.print();
              }}
              className="btn btn-default "
              type={'button'}
            >
              <em className="fa fa-print"/> Imprimir
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default DetalleEntrada;

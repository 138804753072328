import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/tienda';
import { login } from "../../../../../redux/modules/tienda/cuenta";
import { setModal, setAccionModal } from "../../../../../redux/modules/tienda/cuenta";
import Carito from './Carito';


const ms2p = (state) => {
  const { idioma } = state.planta;
  const { carrito } = state.tienda;
  const loginLoader = state.cuenta.loading;
  let en_carrito = 0;
  carrito.forEach((item) => {
    en_carrito += item.cantidad;    
  });      
  return {
    en_carrito,
    ...state.tienda,
    idioma: idioma.codigo,
    loginLoader,
    usuario: state.cuenta.usuario,
  };
};

const md2p = { ...actions, login, setModal, setAccionModal };

export default connect(ms2p, md2p)(Carito);
import React, { Component } from 'react';
import EdicionForm from './EdicionProductoForm';
import LoadMask from "Utils/LoadMask";


class Edicion extends Component {
  constructor(props) {
    super(props);
    this.editar = this.editar.bind(this);
    this.setFile = this.setFile.bind(this);
    this.state = {foto: null};
  }

  editar(data) {
    if(data){
      this.props.editar(data, this.props.match.params.id, {name: "foto", file: this.state.foto});
    }
  }

  setFile(foto) {
    this.setState({foto});
  }

  componentWillMount() {
    this.props.getProducto(this.props.match.params.id);
  }

  render() {
    const {loading} = this.props
    return (
      <div id="usuarios">
        <div className="">
          <div className="p-2 p-sm-4">
            <h1>Editar Producto</h1>
            <LoadMask loading={loading} light color={"#424D88"}>
              <EdicionForm onSubmit={this.editar} setFile={this.setFile} photo={this.props.photo} es_entrada={this.props.es_entrada} />
            </LoadMask>
          </div>
        </div>
      </div>
    )
  }
}

export default Edicion

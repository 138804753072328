import translate from 'translate-js';
import React from "react";

const traducciones = [
  {
    language: 'es',
    data: {
      // Traducciones Tienda
      //Redux Inicio
      'Registro exitoso': 'Registro exitoso',
      'Entradas pendientes de edición': 'Entradas pendientes de edición',
      'Por favor edite las entradas y luego imprima si es necesario': 'Por favor edite las entradas y luego imprima si es necesario',
      'Pago de oportunidad registrado con éxito': 'Pago de oportunidad registrado con éxito',
      'Enhorabuena!': 'Enhorabuena!',
      'Error!': 'Error!',
      'Pago de oportunidad fallido por favor verifica tus datos y vuelve a intentarlo': 'Pago de oportunidad fallido por favor verifica tus datos y vuelve a intentarlo',
      'Transacción fallida por favor verifica tus datos y vuelve a intentarlo': 'Transacción fallida por favor verifica tus datos y vuelve a intentarlo',
      'Ha ocurrido un error al actualizar la información, por favor espera un momento y vuelve a intentar': 'Ha ocurrido un error al actualizar la información, por favor espera un momento y vuelve a intentar',
      'Éxito':'Éxito',
      'Se te ha enviado un correo con los siguientes pasos para restablecer tu contraseña': 'Se te ha enviado un correo con los siguientes pasos para restablecer tu contraseña',
      'Ha ocurrido un error, verifica que la dirección de correo que has escrito es correcta y sea la asociada a tu cuenta de Agritrade.': 'Ha ocurrido un error, verifica que la dirección de correo que has escrito es correcta y sea la asociada a tu cuenta de Agritrade.',
      'Tu contraseña a sido restablecida con éxito.':'Tu contraseña a sido restablecida con éxito.',
      'El enlace no es válido o ha expirado.': 'El enlace no es válido o ha expirado.',
      'Transacción realizada':'Transacción realizada',
      'Datos almacenados':'Datos almacenados',
      'Ha ocurrido un error en la petición':'Ha ocurrido un error en la petición',
      //Caja
      'DETALLE DE CARRITO':  'DETALLE DE CARRITO',
      'Total de articulos': 'Total de articulos',
      'Modo de pago': 'Modo de pago',
      'Número de tarjeta': 'Número de tarjeta',
      'Nombre del dueño de la tarjeta *': 'Nombre del dueño de la tarjeta *',
      'Apellido del dueño de la tarjeta *': 'Apellido del dueño de la tarjeta *',
      'Fecha de caducidad': 'Fecha de caducidad',
      'Mes': 'Mes',
      'Seleccione mes': 'Seleccione mes',
      'Año': 'Año',
      'Código de seguridad': 'Código de seguridad',
      'Proceder a pago': 'Proceder a pago',
      'DETALLE DE COMPRA': 'DETALLE DE COMPRA',
      'Regresar': 'Regresar',
      'TOTAL': 'TOTAL',
      'Cargando...':'Cargando...',
      //Entradas Stand
      'MI STAND': 'MI STAND',
      'STAND NO.': 'STAND NO.',
      'metros': 'metros',
      'Área': 'Área',
      'ENTRADAS': 'ENTRADAS',
      //Footer
      'Dirección: 15 avenida 14 73, Zona 13 Guatemala':'Dirección: 15 avenida 14 73, Zona 13 Guatemala',
      'Teléfono: (502) 2422-3559': 'Teléfono: (502) 2422-3559',
      'Email: Agritrade@agexport.org.gt': 'Email: Agritrade@agexport.org.gt',
      'Horario: Lunes a Viernes: 8am-5pm': 'Horario: Lunes a Viernes: 8am-5pm',
      'Mapa virtual':'Mapa virtual',
      '© Copyright 2018 AGRITRADE. TODOS LOS DERECHOS RESERVADOS.' : '© Copyright 2018 AGRITRADE. TODOS LOS DERECHOS RESERVADOS.',
      //Header
      'INICIAR SESIÓN': 'INICIAR SESIÓN',
      'Tienda en línea': 'Tienda en línea',
      'PRODUCTOS': 'PRODUCTOS',
      'IR A AGRITRADE': 'IR A AGRITRADE',
      'Productos': 'Productos',
      'Mi Perfil': 'Mi Perfil',
      'Cerrar Sesión': 'Cerrar Sesión',
      'CERRAR SESIÓN': 'CERRAR SESIÓN',
      'Mis compras': 'Mis compras',
      'Cerrar sesión': 'Cerrar sesión',
      'MIS COMPRAS': 'MIS COMPRAS',
      //Inicio
      'Agregar al carrito': 'Agregar al carrito',
      'Ingresar': 'Ingresar',
      'Campo requerido': 'Campo requerido',
      'OPORTUNIDADES': 'OPORTUNIDADES',
      '¿Tienes un código de oportunidad y cliente?': '¿Tienes un código de oportunidad y cliente?',
      'Ingresa tus códigos para encontrarlo': 'Ingresa tus códigos para encontrarlo',
      'Puede que se hayan dado 3 problemas de las cuales son:': 'Puede que se hayan dado 3 problemas de las cuales son:',
      'Código de oportunidad se haya ingresado incorrectamente.': 'Código de oportunidad se haya ingresado incorrectamente.',
      'Código de oportunidad ya este pagado por lo tanto no es necesario volver a ingresar.': 'Código de oportunidad ya este pagado por lo tanto no es necesario volver a ingresar.',
      'Código de cliente no esta relacionado con el código de oportunidad': 'Código de cliente no esta relacionado con el código de oportunidad',
      'Puedes acudir al personal de agritrade para solucionar el problema o ingresar el código de oportunidad nuevamente': 'Puedes acudir al personal de agritrade para solucionar el problema o ingresar el código de oportunidad nuevamente',
      'Agregado al carrito':'Agregado al carrito',
      'El producto se ha agregado':'El producto se ha agregado',
      'al carrito  exitosamente':'al carrito  exitosamente',
      'Ir al Carrito': 'Ir al Carrito',
      'Seguir comprando': 'Seguir comprando',
      'Codigo de oportunidad*':'Codigo de oportunidad*',
      'Código de cliente*':'Código de cliente*',
      //--Pago de oportunidad
      'Pagar': 'Pagar',
      //Login
      'O':'O',
      'Registrarte': 'Registrarte',
      'Crea tu cuenta para la aplicación Agritrade': 'Crea tu cuenta para la aplicación Agritrade',
      'Correo *': 'Correo *',
      'Contraseña': 'Contraseña',
      'Iniciar sesión': 'Iniciar sesión',
      'Este campo es requerido': 'Este campo es requerido',
      '¿Olvidaste tu contraseña?': '¿Olvidaste tu contraseña?',
      'Recuperar': 'Recuperar',
      //Pago
      'VER EN': 'VER EN',
      'Pasar a Caja': 'Pasar a Caja',
      'También puedes:': 'También puedes:',
      'Para llenar tus datos automáticamente': 'Para llenar tus datos automáticamente',
      'Para llenar tus datos y realizar el pago': 'Para llenar tus datos y realizar el pago',
      'Eliminar': 'Eliminar',
      'Esta seguro que quiere eliminar el producto ': 'Esta seguro que quiere eliminar el producto ',
      ' del carrito': ' del carrito',
      'Eliminado': 'Eliminado',
      'El producto ha sido eliminado del carrito': 'El producto ha sido eliminado del carrito',
      'CARRITO DE COMPRA': 'CARRITO DE COMPRA',
      'ERROR': 'ERROR',
      '!El usuario de la sesión activa no puede realizar transacciones!, si desea realizar una transacción, cierre la sesión e ingrese con un usuario que si pueda (un usuario que no sea administrador)': '!El usuario de la sesión activa no puede realizar transacciones!, si desea realizar una transacción, cierre la sesión e ingrese con un usuario que si pueda (un usuario que no sea administrador)',
      //PassReset
      'RESTABLECER CONTRASEÑA': 'RESTABLECER CONTRASEÑA',
      'Restablecer contraseña': 'Restablecer contraseña',
      'Ingresa el correo de tu cuenta de agritrade *': 'Ingresa el correo de tu cuenta de agritrade *',
      'Se te enviará un correo con los siguientes pasos para restablecer tu contraseña': 'Se te enviará un correo con los siguientes pasos para restablecer tu contraseña',
      'Enviar':'Enviar',
      //Perfil
      ///Compras
      ////Entradas
      'Programa de Conferencias y Piso de Exhibición': 'Programa de Conferencias y Piso de Exhibición',
      'Estudiante': 'Estudiante',
      'Full Access': 'Full Access',
      'Apoyo': 'Apoyo',
      'Apoyo Stand':'Apoyo Stand',
      'Marbetes Parqueo':'Marbetes Parqueo',
      'Piso Exhibición':'Piso Exhibición',
      'Piso Exhibición Sábado':'Piso Exhibición Sábado',
      'Conferencias':'Conferencias',
      'Staff':'Staff',
      'Prensa':'Prensa',
      'Invitado Especial':'Invitado Especial',
      'Comité Organizador':'Comité Organizador',
      'Comprador':'Comprador',
      'Productor':'Productor',
      'Nombre aquí': 'Nombre aquí',
      'Empresa': 'Empresa',
      'Empresa aquí': 'Empresa aquí',
      'Correo aquí': 'Correo aquí',
      'Edición de entrada': 'Edición de entrada',
      'Nombre': 'Nombre',
      'Tipo de entrada': 'Tipo de entrada',
      'Guardar e Imprimir': 'Guardar e Imprimir',
      'Cargando': 'Cargando',
      'Patrocinador de gafete': 'Patrocinador de gafete',
      'No hay registros': 'No hay registros',
      'Código': 'Código',
      'Editado': 'Editado',
      'Sin editar': 'Sin editar',
      'Ver': 'Ver',
      'Editar': 'Editar',
      'Acciones': 'Acciones',
      'Todos':'Todos',
      'No editados':'No editados',
      'Editados':'Editados',
      ////Historial
      'Fecha de compra': 'Fecha de compra',
      'Código de Transferencia': 'Código de Transferencia',
      'Moneda': 'Moneda',
      'Oportunidad': 'Oportunidad',
      'Si': 'Si',
      'No': 'No',
      'Descripción de oportunidad': 'Descripción de oportunidad',
      'Producto': 'Producto',
      'Precio Unitario': 'Precio Unitario',
      'Cantidad': 'Cantidad',
      'Total': 'Total',
      'Descargar recibo': 'Descargar recibo',
      'Transacción': 'Transacción',
      'Transacciones':'Transacciones',
      'Entradas':'Entradas',
      'Detalle de Compra':'Detalle de Compra',
      ////Datos
      '!El usuario de la sesión activa no puede acceder a transacciones o al perfil!':'!El usuario de la sesión activa no puede acceder a transacciones o al perfil!',
      'País': 'País',
      'Apellidos':  'Apellidos',
      'Nit': 'Nit',
      'Dirección': 'Dirección',
      'Ciudad': 'Ciudad',
      'Código postal': 'Código postal',
      'Nit inválido':'Nit inválido',
      'Razón Social *':'Razón Social *',
      'Razón Social':'Razón Social',
      //Perfil
      'Guardar': 'Guardar',
      'Debe ser un correo válido': 'Debe ser un correo válido',
      'Asignado a': 'Asignado a',
      'Estado': 'Estado',
      //Historial
      'Código de Transacción': 'Código de Transacción',
      //Mensaje Exitoso
      'Gracias por realizar tu compra, te enviaremos':'Gracias por realizar tu compra, te enviaremos',
      'el recibo al correo: ':'el recibo al correo: ',
      'y podras descargarlo en tu perfil':'y podras descargarlo en tu perfil',
      'Aceptar':'Aceptar',
      //Registro
      'REGISTRO DE USUARIO': 'REGISTRO DE USUARIO',
      'Confirmar Contraseña': 'Confirmar Contraseña',
      'Crear cuenta': 'Crear cuenta',
      'Si es asociado a Agexport por favor  utilice el nit de la empresa registrada': 'Si es asociado a Agexport por favor  utilice el nit de la empresa registrada',
      'Ingrese el nombre del cliente o empresa con el cual sera emitido su recibo': 'Ingrese el nombre del cliente o empresa con el cual sera emitido su recibo',
      'Las contraseñas no coinciden': 'Las contraseñas no coinciden',
      //-------------------------------------------
      'Selecciona el espacio que deseas ver': 'Selecciona el espacio que deseas ver',
      'Espacios': 'Stands',
      'Espacio reservado': 'Stand reservado',
      'Disponibles': 'Disponibles',
      'Reservados': 'Reservados',
      'Comprados': 'Comprados',
      'REGRESAR A LA PÁGINA': 'REGRESAR A LA PÁGINA',
      'Si eres dueño de este espacio, porfavor actualiza tus datos': 'Si eres dueño de este espacio, por favor actualiza tus datos',
      'Actualizar datos': 'Actualizar datos',
      'Enviar datos': 'Enviar datos',
      'Visualizar': 'Visualizar',
      'Cancelar': 'Cancelar',
      'Reservado': 'Reservado',
      'Comprado': 'Comprado',
      'Disponible': 'Disponible',
      'Ver página': 'Ver página',
      'Area': 'Área',
      'Vecinos': 'Vecinos',
      'Reservar espacio': 'Reservar espacio',
      'Ver todos': 'Ver todos',
      'Mts': 'metros',
      'Planta': 'Planta',
      'ESPACIO NO.': 'STAND NO.',
      'Completa el formulario para solicitar la reserva': 'Completa el formulario para solicitar la reserva.',
      'Nombre de la empresa': 'Nombre de la empresa',
      'Nombre del contacto administrativo': 'Nombre del contacto administrativo',
      'Correo de contacto': 'Correo de contacto',
      'Teléfono': 'Teléfono',
      'No. de stand de su interés': 'No. de stand de su interés',
      'Actividad principal de su empresa': 'Actividad principal de su empresa',
    }
  },
  {
    language: 'en',
    data: {
      // Traducciones Tienda
      'Registro exitoso':'Successful registration',
      'Entradas pendientes de edición':'Pending edition tickets',
      //Redux Inicio
      'Registro exitoso': 'Register successful',
      'Entradas pendientes de edición': 'Tickets pending of edition',
      'Por favor edite las entradas y luego imprima si es necesario': 'Please edit the tickets and print if necessary',
      'Pago de oportunidad registrado con éxito': 'Opportunity payment registered successfully',
      'Enhorabuena!': 'Congratulations!',
      'Error!': 'Error!',
      'Pago de oportunidad fallido por favor verifica tus datos y vuelve a intentarlo': 'Opportunity payment failure, please verify the data and try again',
      'Transacción fallida por favor verifica tus datos y vuelve a intentarlo': 'Failed transaction, please verify the data and try again',
      'Ha ocurrido un error al actualizar la información, por favor espera un momento y vuelve a intentar': 'An error occurred while updating the information, please wait a moment and try again',
      'Éxito':'Success',
      'Se te ha enviado un correo con los siguientes pasos para restablecer tu contraseña': 'We\'ve sent you an email with the following steps to reset your password',
      'Ha ocurrido un error, verifica que la dirección de correo que has escrito es correcta y sea la asociada a tu cuenta de Agritrade.': 'An error has occurred, verify that the email address you have entered is correct and is the one associated with your Agritrade account.',
      'Tu contraseña a sido restablecida con éxito.':'Your password has been successfully restored.',
      'El enlace no es válido o ha expirado.': 'The link is invalid or has expired.',
      'Transacción realizada':'Transaction successful',
      'Datos almacenados':'Data storaged',
      'Ha ocurrido un error en la petición':'There has been an error in the request',
      //Caja
      'DETALLE DE CARRITO': 'CART DETAIL',
      'Total de articulos': 'Total articles',
      'Modo de pago': 'Payment method',
      'Número de tarjeta *': 'Card number *',
      'Nombre del dueño de la tarjeta *': 'Card owner\'s name',
      'Apellido del dueño de la tarjeta *': 'Card owner\'s last name *',
      'Fecha de caducidad': 'Date of Expiry',
      'Mes *': 'Month *',
      'Seleccione mes': 'Select month',
      'Seleccione año': 'Select year',
      'Año': 'Year',
      'Año *': 'Year *',
      'Código de seguridad *': 'Security code *',
      'Proceder a pago': 'Proceed and pay',
      'DETALLE DE COMPRA': 'PURCHASE DETAIL',
      'Regresar': 'Go Back',
      'TOTAL': 'TOTAL',
      'Cargando...': 'Loading...',
      //Entradas Stand
      'MI STAND': 'MY BOOTH',
      'STAND NO.': 'BOOTH NO.',
      'metros': 'meters',
      'Área': 'Area',
      'Apellido': 'Last name',
      'ENTRADAS': 'TICKETS',
      //Footer
      'Dirección: 15 avenida 14 73, Zona 13 Guatemala':'Address: 15 avenida 14 73, Zona 13 Guatemala',
      'Teléfono: (502) 2422-3559': 'Phone: (502) 2422-3559',
      'Email: Agritrade@agexport.org.gt': 'Email: Agritrade@agexport.org.gt',
      'Horario: Lunes a Viernes: 8am-5pm': 'Schedule: Monday to Friday: 8am-5pm',
      'Tienda en línea': 'Online store',
      'Mapa virtual':'Virtual map',
      '© Copyright 2018 AGRITRADE. TODOS LOS DERECHOS RESERVADOS.' : '© Copyright 2018 AGRITRADE. ALL RIGHTS RESERVED.',
      //Header
      'INICIAR SESIÓN': 'LOG IN',
      'PRODUCTOS': 'PRODUCTS',
      'IR A AGRITRADE': 'GO TO AGRITRADE',
      'Productos': 'Products',
      'Mi Perfil': 'My profile',
      'MI PERFIL': 'MY PROFILE',
      'Cerrar Sesión': 'Log Out',
      'CERRAR SESIÓN': 'LOG OUT',
      'Mis compras': 'My Purchases',
      'Cerrar sesión': 'Log out',
      'MIS COMPRAS': 'MY PURCHASES',
      //Inicio
      'Agregar al carrito': 'Add to cart',
      'Ingresar': 'Enter',
      'Campo requerido': 'Required field',
      'OPORTUNIDADES': 'OPPORTUNITY',
      '¿Tienes un código de oportunidad y cliente?': 'Do you have an opportunity and client code?',
      'Ingresa tus códigos para encontrarlo': 'Enter your codes to find it',
      'Puede que se hayan dado 3 problemas de las cuales son:': 'There may have been one of the following problems:',
      'Código de oportunidad se haya ingresado incorrectamente.': 'Opportunity code entered incorrectly.',
      'Código de oportunidad ya este pagado por lo tanto no es necesario volver a ingresar.': 'Opportunity code has already been payed.',
      'Código de cliente no esta relacionado con el código de oportunidad': 'The client code is not related with the opportunity code',
      'Puedes acudir al personal de agritrade para solucionar el problema o ingresar el código de oportunidad nuevamente': 'You can contact the Agritrade staff to solve the problem or try again',
      //--Pago de oportunidad
      'Pagar': 'Pay',
      //Login
      'O':'Or',
      'Registrarte': 'Sign up',
      'Crea tu cuenta para la aplicación Agritrade': 'Create your own account for the Agritrade application',
      'Correo *': 'Email *',
      'Contraseña *': 'Password *',
      'Iniciar sesión': 'Log in',
      'Este campo es requerido': 'This field is required',
      '¿Olvidaste tu contraseña?': 'Forgot your password?',
      'Recuperar': 'Recover',
      //Pago
      'VER EN': 'SEE IN',
      'Pasar a Caja': 'Go to Checkout',
      'También puedes:': 'You can also:',
      'Para llenar tus datos automáticamente': 'To fill your information automatically',
      'Para llenar tus datos y realizar el pago': 'To fill your information and pay',
      'Eliminar': 'Remove',
      'Esta seguro que quiere eliminar el producto ': 'Are you sure you want to remove the product?',
      ' del carrito': ' from the cart',
      'Eliminado': 'Removed',
      'El producto ha sido eliminado del carrito': 'The product has been removed from the cart',
      'CARRITO DE COMPRA': 'SHOPPING CART',
      'ERROR': 'ERROR',
      'Código de oportunidad*': 'Oportunity Code*',
      'Código de cliente*': 'Client Code*',
      '!El usuario de la sesión activa no puede realizar transacciones!, si desea realizar una transacción, cierre la sesión e ingrese con un usuario que si pueda (un usuario que no sea administrador)': 'The user of the active session can not perform transactions! If you want to make a transaction, log out and log in with a user who can (a user who is not an administrator)',
      //PassReset
      'RESTABLECER CONTRASEÑA': 'RESTORE PASSWORD',
      'Restablecer contraseña': 'Restore password',
      'Ingresa el correo de tu cuenta de agritrade *': 'Enter the email of your Agritrade account *',
      'Se te enviará un correo con los siguientes pasos para restablecer tu contraseña': 'You\'ll be sent an email with the following steps to reset your password',
      'Enviar':'Send',
      //Perfil
      'Foto': 'Picture',
      'Nombre': 'Name',
      'Apellidos': 'Last name',
      'Apellido *': 'Last name *',
      'Empresa *': 'Company name *',
      'Vista previa': 'Preview',
      'Correo': 'Email',
      'Longitud máxima de 15 caracteres': 'Max length of 15 chars',
      'El número de teléfono supera los 15 caracteres, editalo y vuelve a intentar': 'Your phone number exceeds 15 chars of length, please edit it and try again',
      'Dirección': 'Address',
      'Ciudad': 'City',
      'Código postal': 'Postal code',
      'País': 'Contry',
      ///Compras
      ////Entradas
      'Programa de Conferencias y Piso de Exhibición': 'Conference program and Exhibition floor',
      'Estudiante': 'Student',
      'Full Access': 'Full Access',
      'Apoyo': 'Staff',
      'Apoyo Stand':'Stand Support',
      'Marbetes Parqueo':'Parking Tags',
      'Piso Exhibición':'Exhibit Floor',
      'Piso Exhibición Sábado':'Exhibit Floor Saturday',
      'Conferencias':'Conferences',
      'Staff':'Staff',
      'Prensa':'Press',
      'Invitado Especial':'Special Guest',
      'Comité Organizador':'Organizing Committe',
      'Comprador':'Buyer',
      'Productor':'Producer',
      'Nombre aquí': 'Name here',
      'Empresa': 'Company',
      'Empresa aquí': 'Company here',
      'Correo aquí': 'Email here',
      'Edición de entrada': 'Ticket edition',
      'Nombre *': 'Name *',
      'Tipo de entrada': 'Ticket type',
      'Guardar e Imprimir': 'Save and print',
      'Cargando': 'Loading',
      'Patrocinador de gafete': 'Badge sponsor',
      'No hay registros': 'No records found',
      'Código': 'Code',
      'Editado': 'Edited',
      'Sin editar': 'Without edit',
      'Ver': 'See',
      'Editar': 'Edit',
      'Acciones': 'Actions',
      'Todos': 'All',
      "Editados": "Edited",
      "No editados": "Not edited",
      ////Historial
      'Fecha de compra': 'Purchase date',
      'Código de Transferencia': 'Transfer code',
      'Moneda': 'Currency',
      'Oportunidad': 'Opportunity',
      'Si': 'Yes',
      'No': 'No',
      'Descripción de oportunidad': 'Opportunity description',
      'Producto': 'Product',
      'Precio Unitario': 'Unit price',
      'Cantidad': 'Quantity',
      'Total': 'Total',
      'Descargar recibo': 'Download receipt',
      'Transacción': 'Transaction',
      'Transacciones':'Transactions',
      'Detalle Compra': 'Purchase Detail',
      'Código de transacción': 'Transaction number',
      'Entradas':'Tickets',
      ////Datos
      '!El usuario de la sesión activa no puede acceder a transacciones o al perfil!':'The user of the active session can not access transactions or the profile',
      'País *': 'Country *',
      'Apellidos *':  'Last name *',
      'Nit': 'Nit',
      'Dirección *': 'Address *',
      'Ciudad *': 'City *',
      'Código postal *': 'Postal Code *',
      'Vista Previa': 'Preview',
      'Nit inválido': 'Invalid nit',
      //Perfil
      'Guardar': 'Save',
      'Debe ser un correo válido': 'Must be a valid email',
      'Asignado a': 'Assigned to',
      'Estado': 'State',
      'Razón Social *':'Business name *',
      'Razón Social':'Business name',
      //Historial
      'Código de Transacción': 'Transaction code',
      //Mensaje exitoso
      'Gracias por realizar tu compra, te enviaremos':'Thank you for your purchase, we\'ll send',
      'el recibo al correo: ':'the receipt to your email: ',
      'y podras descargarlo en tu perfil':'and you can also download it on your profile',
      'Aceptar':'Accept',
      //Registro
      'REGISTRO DE USUARIO': 'USER REGISTRATION',
      'Confirmar Contraseña *': 'Confirm password *',
      'Crear cuenta': 'Create account',
      'Si es asociado a Agexport por favor  utilice el nit de la empresa registrada': 'If you are an Agexport associate please use the registered company nit',
      'Ingrese el nombre del cliente o empresa con el cual sera emitido su recibo': 'Enter the name of the client or company with which your receipt will be issued',
      'Las contraseñas no coinciden': 'The passwords don\'t match',
      'Dólares (USD)': 'Dollars (USD)',
      //-------------------------------------------
      'Selecciona el espacio que deseas ver': 'Select the stand you want to see',
      'Espacios': 'Stands',
      'Espacio reservado': 'Reserved stand',
      'Disponibles': 'Available',
      'Reservados': 'Reserved',
      'Comprados': 'Bought',
      'REGRESAR A LA PÁGINA': 'RETURN TO PAGE',
      'Si eres dueño de este espacio, porfavor actualiza tus datos': 'If you are the owner of this stand, please update your information',
      'Actualizar datos': 'Update info',
      'Enviar datos': 'Send Info',
      'Cancelar': 'Cancel',
      'Visualizar': 'Visualize',
      'Reservado': 'Reseved',
      'Comprado': 'Bought',
      'Agregado al carrito': 'Added to the cart',
      'El producto se ha agregado':'The product has been added',
      'al carrito  exitosamente':'to the cart successfully',
      'Ir al Carrito':'Go to the cart',
      'Seguir comprando':'Continue shopping',
      'Disponible': 'Available',
      'Area': 'Area',
      'Vecinos': 'Neighbours',
      'Reservar espacio': 'Reserve stand',
      'Ver todos': 'See all',
      'Mts': 'meters',
      'Planta': 'Floor',
      'ESPACIO NO.': 'STAND NO.',
      'Completa el formulario para solicitar la reserva': 'Complete the form to request the reservation.',
      'Nombre de la empresa': 'Company name',
      'Nombre del contacto administrativo': 'Name of the administrative contact',
      'Correo de contacto': 'Contact\'s email',
      'Teléfono': 'Phone',
      'Teléfono *': 'Phone *',
      'No. de stand de su interés': 'Stand number of your interest',
      'Actividad principal de su empresa': 'Commercial activity of the company',
      'Ver página': 'Go to site',
    }
  },
];

traducciones.forEach((idioma) => {
  translate.add(idioma.data, idioma.language);
});

const translator = {
  translate(key) {
    return translate(key);
  },
  useLanguage(lang) {
    translate.setLocale(lang);
  },
};

if (!!localStorage.getItem('lang')) {
  translator.useLanguage(localStorage.getItem('lang'));
}

export { translator };

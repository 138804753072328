import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import CodigoOportunidad from "./CodigoOportunidad";
import { Redirect} from "react-router-dom";
import LoadMask from "Utils/LoadMask";
import "./Error.css";

class ErrorOportunidad extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.buscarOportunidad = this.buscarOportunidad.bind(this);
    }
    componentWillUnmount(){
        this.props.setError(false);
    }
    
    buscarOportunidad(data) {
        if (!!this.props.usuario.perfil) {
          this.props.buscarOportunidad(data);
        } else {
          this.props.setAccionModal('Oportunidad');
          this.props.setLoginModal(true);
        }
    }

    render(){
        const { loading, error } = this.props;
        if(!error)
        {
            return(
                <Redirect to='/tienda/inicio' />
            )
        }
        return(
            <div id="error-oportunidad">
            {/* <div className="difuminado" /> */}
            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                <div className="p-2">
                    <img src={require("assets/img/info.png")} alt="info" />
                </div>
                <div className="p-2">
                    <div className="p-2">
                        <h5>{t.translate('Puede que se hayan dado 3 problemas de las cuales son:')}</h5>
                    </div>
                    <div className="p-2">
                        <ul>
                            <li className="p-2">{t.translate('Código de oportunidad se haya ingresado incorrectamente.')}</li>
                            <li className="p-2">{t.translate('Código de oportunidad ya este pagado por lo tanto no es necesario volver a ingresar.')}</li>
                            <li className="p-2">{t.translate('Código de cliente no esta relacionado con el código de oportunidad')}</li>                            
                        </ul>
                    </div>
                    <div className="p-2">
                        <p>{t.translate('Puedes acudir al personal de agritrade para solucionar el problema o ingresar el código de oportunidad nuevamente')}</p>
                        <LoadMask loading={loading} light color={"#424D88"}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <CodigoOportunidad onSubmit={this.buscarOportunidad} loader={loading} />
                        </div>
                        </LoadMask>
                    </div>
                </div>                
            </div>
            <img className="verduras" src={require("assets/img/marco_verduras.png")} alt=""/>
            </div>
        );
    }
}

export default ErrorOportunidad;
import React from 'react';
import { ToastContainer, ToastStore } from 'react-toasts';
import {
  Route,
  Switch,
} from 'react-router-dom';

import PrivateRoute from './ProtectedRoute'
import TiendaRoute from './TiendaRoute';

// RUTAS DE ADMINISTRACION
import {
  Login,
  Stands as Administracion,
  EdicionUsuario as EditarUsuario,
  ListadoUsuario as Usuarios,
  NuevoUsuario,
  ListadoProducto,
  EdicionProducto,
  DetalleProducto,
  ListadoEntradas,
  CrearEntradas,
  ListadoTransacciones,
  DetalleTransaccion,
} from './common/components/Administracion';

// RUTAS DE CLIENTES
import { Planta }from './common/components/Publico';


import '../assets/fonts/fonts.css';

// RUTAS DE TIENDA
import {
  Inicio,
  Pagos,
  Registro,
  Caja,
  Perfil,
  Productos,

} from "./common/components/Tienda";
import { Tienda } from "./TiendaRoute";

import {DetalleCompra} from "./common/components/Tienda/Perfil/Compras/Historial";
import {DetalleEntradas, EdicionEntradas} from "./common/components/Tienda/Perfil/Compras/Entradas";
import {PagoOportunidad, ErrorOportunidad} from "./common/components/Tienda/Inicio/Oportunidades";
import {EntradasStand} from "./common/components/Tienda/EntradasStand";
import {Reset} from "./common/components/Tienda/PassReset";
import Exito from "./common/components/Tienda/Perfil/MensajeExito";

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

const lugar = window.location.href;
let urlTienda = "store.agritradecentralamerica";
let urlCroquis = "mapa.agritradecentralamerica";
// let urlTienda = ".";
// let urlCroquis = ".";
try {
  const server = require("./utility/local_settings");
  urlTienda = server.urlTienda;
  urlCroquis = server.urlCroquis;
} catch (err){
}

module.exports = (
  <div>
    <div className="container__content">
      <Switch>
        <Route exact path="/login" component={Login} />

        <PrivateRoute exact path="/usuarios" component={Usuarios} />
        <PrivateRoute exact path="/usuarios/nuevo" component={NuevoUsuario} />
        <PrivateRoute exact path="/usuarios/editar/:id" component={EditarUsuario} />
        <PrivateRoute exact path="/administracion" component={Administracion} />
        <PrivateRoute exact path="/administrar_entradas" component={ListadoEntradas} />
        <PrivateRoute exact path="/administrar_entradas/crear" component={CrearEntradas} />
        <PrivateRoute exact path="/administrar_entradas/:id" component={EdicionEntradas} />
        <PrivateRoute exact path="/administrar_productos" component={ListadoProducto} />
        <PrivateRoute exact path="/administrar_productos/:id" component={EdicionProducto} />
        <PrivateRoute exact path="/administrar_productos/detalle/:id" component={DetalleProducto} />
        <PrivateRoute exact path="/administrar_transacciones" component={ListadoTransacciones} />
        <PrivateRoute exact path="/administrar_transacciones/detalle/:id" component={DetalleTransaccion} />

        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda" component={Tienda} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/inicio" component={Inicio} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/pass_recover/:token" component={Reset} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/carrito" component={Pagos} />)}
        {(lugar.includes(urlTienda)) && (<Route exact path="/tienda/registro" component={Registro} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/caja" component={Caja} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/perfil" component={Perfil} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/stand/:codigo" component={EntradasStand} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/stand/:codigo/:id/editar" component={EdicionEntradas} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/entrada/:id" component={DetalleEntradas} />)}
        {/* 
          SE DEBE DESCOMENTAR EL FRAGMENTO && false PARA DESBLOQUEAR 
          LA RUTA DE EDICION DEL GAFETE POR PARTE DEL USUARIO
        */}
        {(lugar.includes(urlTienda)) && false && (<TiendaRoute exact path="/tienda/entrada/:id/editar" component={EdicionEntradas} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/entrada/historial/detalle/:id" component={DetalleCompra} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/productos_genericos" component={Productos} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/oportunidad/error" component={ErrorOportunidad} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/oportunidad/pagar" component={PagoOportunidad} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/tienda/compra_exitosa" component={Exito} />)}
        {(lugar.includes(urlTienda)) && (<TiendaRoute exact path="/" component={Inicio} />)}

        {(lugar.includes(urlCroquis)) && (<Route path="*" component={Planta} />)}

      </Switch>
      <ToastContainer position={ToastContainer.POSITION.TOP_RIGHT} lightBackground store={ToastStore} />
    </div>
  </div>
);

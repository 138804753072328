import { handleActions } from 'redux-actions';
import { api } from "api";
import { push } from "react-router-redux";
import {ToastStore} from "react-toasts";
import {initialize as initializeForm} from 'redux-form';
import swal from 'sweetalert2';

const DATA = 'USUARIO_DATA';
const LOADING = 'USUARIO_LOADING';
const PAGE = 'USUARIO_PAGE';
const SEARCH = 'USUARIO_SEARCH';

export const constants = {
};

// ------------------------------------
// Actions
// ------------------------------------

export const getUsuarios = (page=1) => (dispatch, getStore) => {
  const search = getStore().usuario.search;
  dispatch(setLoading(true));
  api.get('user', {search, page}).then((data) => {
    dispatch(setData(data));
    dispatch(setPage(page));
  }).catch(e => {

  }).finally(() => {
    dispatch(setLoading(false));
  });
};

export const getUser = id => (dispatch) => {
  api.get(`user/${id}`).then(data => {
    dispatch(initializeForm('EditarUser', data));
  }).catch(() => {

  }).finally(() => {

  });
};

export const updateUsuarios = (data, id) => (dispatch) => {
  dispatch(setLoading(true));
  api.put(`/user/${id}`, {...data, })
    .then((data) => {
      dispatch(push('/usuarios'));
    })
    .catch((e) => {
      const error = e.username[0];            
      swal(
        'Error',
        error,
        'error'
      )
    })
    .finally(() => {
      dispatch(getUsuarios());
    })
};

export const newUsuario = (data) => (dispatch) =>{
  dispatch(setLoading(true));
  api.post(`/user/`,{...data, })
    .then((data) =>{
      dispatch(push('/usuarios'));
    })
    .catch((e) => {
      if (e.username) {
        ToastStore.error(e.username[0]);
      }
    })
    .finally(()=>{
      dispatch(getUsuarios());
    })
};

export const deleteUsuario = (id) => (dispatch) => {
  dispatch(setLoading(true));
  api.eliminar(`/user/${id}`)
    .then(() => {
      swal(
        'Eliminado',
        'Usuario eliminado',
        'success'
      )
    })
    .catch((e) => {
      const error = e.detail;
      swal(
        'Error',
        error,
        'error'
      )
    })
    .finally(() => {
      dispatch(getUsuarios());
    })
};

export const editar = (stand) => (dispatch) => {
  dispatch(initializeForm('EditarUser', stand));
};

export const searchChange = search => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getUsuarios());
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setData = data => ({
  type: DATA,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

export const setLoading = loading => ({
  type: LOADING,
  loading,
});

export const setSearch = search => ({
  type: SEARCH,
  search,
});

export const actions = {
  getUsuarios,
  updateUsuarios,
  newUsuario,
  deleteUsuario,
  getUser,
  editar,
  searchChange,
};

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,

    };
  },
};

export const initialState = {
  data: {},
  loading: false,
  page: 1,
  search: '',
};

export default handleActions(reducers, initialState);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import Moneda from './Moneda';
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";
import FormComprar from "./form";
import "./carrito.css";
import LoadMask from "Utils/LoadMask";


class Carrito extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.comprar = this.comprar.bind(this);
  }

  componentWillMount() {
    this.props.getProductos();
    // this.props.getEmisores();    
  }

  handleChange(e){
    this.props.setMoneda(e.value);
  }

  comprar(data){
    this.props.realizarPago(data);
  }

  render() {
    const { en_carrito, idioma, total, moneda, usuario, emisores, loading} = this.props;
    let total_dolares = 0;
    let total_quetzales = 0;
    if(usuario.es_socio){
      total_dolares=total.total_dolares_socios;
      total_quetzales=total.total_quetzales_socios;
    }
    else
    {
      total_dolares=total.total_dolares;
      total_quetzales=total.total_quetzales;
    }
    return (
      <div id="carrito-caja" className="d-flex flex-column m-3 bd-highlight justify-content-center">
        <LoadMask loading={loading} light color={"#424D88"}>

          <div className="d-flex flex-column ml-3 mr-3 align-items-center encabezado">
            <i className="p-2 fa fa-shopping-cart fa-3x" />
            <div className="p-1">
              <p>{t.translate("DETALLE DE CARRITO")}</p>
            </div>
          </div>
          <p className="ml-3 mt-3">{t.translate("Total de articulos")}({en_carrito ? en_carrito : 0}):</p>
          <div className="d-flex flex-column align-items-center precio m-3">
            <p>
              <RenderCurrency value={moneda.codigo === "GTQ" ? total_quetzales: total_dolares} prefix={moneda.codigo === "GTQ" ? "Q": "$"} />
            </p>
          </div>
          <div className="ml-5">{t.translate("Modo de pago")}</div>
          <div className="d-flex flex-row moneda justify-content-center ml-5 mr-5">
            <div className="p-2 flex-1 align-self-end">
              <i className="fa fa-cc-visa fa-2x"/>
            </div>
            <div className="p-2 flex-1">
              <Moneda
                moneda = {moneda.codigo}
                handleChange={this.handleChange}
              />
            </div>
          </div>
          <div className="d-flex flex-column align-items-center m-1">
            <FormComprar
              emisores={emisores}
              idioma={idioma}
              onSubmit={this.comprar}
              loader = {loading}
            />
          </div>
        </LoadMask>
      </div>
    );
  }
}

export default Carrito;

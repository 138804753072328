import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import FormComprar from "./form";
import "./carrito.css";
import LoadMask from "Utils/LoadMask";


class Carrito extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
      this.comprar = this.comprar.bind(this);
  }

  componentWillMount() {
     // this.props.getEmisores();    
  }
   comprar(data){
    this.props.pagarOportunidad(data);
  }

  render() {
    const { idioma, oportunidad, emisores, loading} = this.props;
   
    return (
      <div id="carrito-oportunidad" className="d-flex flex-column m-3 bd-highlight justify-content-center">
        <LoadMask loading={loading} light color={"#424D88"}>

          <div className="d-flex flex-column ml-3 mr-3 align-items-center encabezado">
            <i className="p-2 fa fa-shopping-cart fa-3x" />
            <div className="p-1">
              <p>{t.translate("DETALLE DE CARRITO")}</p>
            </div>
          </div>
          <p className="ml-3 mt-3">{t.translate("Total de articulos")}({oportunidad.total ? 1:0}):</p>
          <div className="d-flex flex-column align-items-center precio m-3">
            <p>
              <RenderCurrency value={oportunidad.total} prefix={oportunidad.moneda === "GTQ" ? "Q": "$"} />
            </p>
          </div>
          <div className="ml-5">{t.translate("Modo de pago")}</div>
          <div className="d-flex flex-row moneda justify-content-center ml-5 mr-5">
            <div className="p-2">
              <i className="fa fa-cc-visa fa-2x"/>
            </div>
            <div className="p-2 flex-1">
              <small><strong>{oportunidad.moneda === "GTQ" ? "Quetzales (GTQ)":"Dolares (USD)"}</strong></small>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center ml-3 mr-3">
            <FormComprar
              emisores={emisores}
              idioma={idioma}
              onSubmit={this.comprar}
              loader = {loading}
            />
          </div>
        </LoadMask>
      </div>
    );
  }
}

export default Carrito;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'react-dd-menu';
import { translator as t } from "traducer";
import Modal from "react-responsive-modal";
import Login from "../Login";
import {logo_en, logo_es, logo_blanco_es, logo_blanco_en} from "utility/variables";
import { SolicitudPass } from "../PassReset";
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import Movil from './Movil/';
import Loader from "Utils/Loader";
import "./header.css";
import "./movil-menu.css";


class Header extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    idioma: PropTypes.object.isRequired,
    planta: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {opened: false, openedIdiomas: false, openedPerfil: false, openedMovil: false, modalRecuperar: false};
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
    this.closeIdiomas = this.closeIdiomas.bind(this);
    this.toggleIdiomas = this.toggleIdiomas.bind(this);
    this.closePerfil = this.closePerfil.bind(this);
    this.togglePerfil = this.togglePerfil.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.login = this.login.bind(this);
    this.containsUrl = this.containsUrl.bind(this);
    this.getTexto = this.getTexto.bind(this);
    this.closeMovil  = this.closeMovil.bind(this);
    this.toggleMovil = this.toggleMovil.bind(this);    
  }

  componentWillMount() {
    this.props.setIdioma(localStorage.getItem('lang') || 'es');
    this.props.getMe();
    this.props.getProductosGenericos();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.usuario.first_name !== this.props.usuario.first_name && this.props.modal) {
      this.toggleModal();
    }
    if ((nextProps.loading_recuperacion !== this.props.loading_recuperacion) && this.props.loading_recuperacion) {
      this.setState({modalRecuperar: false});
    }
  }

  close() {
    this.setState({ opened: !this.state.opened });
  };

  toggle() {
    this.setState({ opened: !this.state.opened });
  };

  closeIdiomas() {
    this.setState({ openedIdiomas: !this.state.openedIdiomas });
  };

  toggleIdiomas() {
    this.setState({ openedIdiomas: !this.state.openedIdiomas });
  };

  closeMovil() {
    this.setState({ openedMovil: false });    
  };

  toggleMovil() {
    this.setState({ openedMovil: !this.state.openedMovil });    
  };

  closePerfil() {
    this.setState({ openedPerfil: !this.state.openedPerfil });
  };

  togglePerfil() {
    this.setState({ openedPerfil: !this.state.openedPerfil });
  };

  toggleModal() {
    this.props.setAccionModal(null);
    this.props.setModal(!this.props.modal);
  }

  recuperar = () => {
    this.toggleModal();
    this.setState({modalRecuperar: true});
  };

  login(data) {
    this.props.login(data);
  }

  getTexto() {
    if (this.containsUrl("tienda")) {
      return "Tienda";
    } else if (this.containsUrl("productos")) {
      return "Productos";
    }
  }
  containsUrl(url) {
    return this.props.url.includes(url);
  }

  render() {
    const { idioma, usuario, modal: openModal, loginLoader, productos_genericos } = this.props;
    const pagina = "Entradas";
    const menuIdiomas = {
      isOpen: this.state.openedIdiomas,
      close: this.closeIdiomas,
      closeOnInsideClick: false,
      toggle: <li style={{display: "inline-block"}}>
        <a
          className="d-none d-sm-inline-block"
          style={{cursor: "pointer"}}
          onClick={this.toggleIdiomas}>
          <img src={require(`assets/img/idiomas/${idioma.img}.png`)} alt=""/>&nbsp;{idioma.nombre} <em className="fa fa-caret-down" />
        </a>
        <a
          className="d-inline-block d-sm-none"
          style={{cursor: "pointer"}}
          onClick={this.toggleIdiomas}>
          <em className="fa fa-navicon" />
        </a>
      </li>,
      align: 'right',
    };
    const menuPerfil = {
      isOpen: this.state.openedPerfil,
      close: this.closePerfil,
      closeOnInsideClick: false,
      toggle: <li style={{display: "inline-block"}}>
        <a
          style={{cursor: "pointer"}}
          onClick={this.togglePerfil}>
          {!!usuario.perfil ? usuario.perfil.nombre : usuario.first_name} <em className="fa fa-caret-down text-uppercase" />
        </a>
      </li>,
      align: 'right',
    };
    return (
      <div id="tienda-header" className="d-flex pl-3 pr-3 flex-row align-items-center justify-content-between justify-content-sm-start">
        <div className="logo m-1 d-flex flex-column justify-content-center">
        <Link to="/tienda/inicio">          
          <img src={idioma.codigo === "es" ? logo_es : logo_en} alt={idioma.codigo === "es" ? "Promoviendo oferta exportable" : "Growing business together"}/>
        </Link>
        </div>
        <div className="d-none d-sm-flex">
        <Link to="/tienda/inicio">          
          {t.translate("Tienda en línea")}
        </Link>
        </div>
        <div className="separador d-none d-sm-inline-block" />
        <Link className={this.containsUrl("inicio") ? "pagina-actual" : null} to="/tienda/inicio">
          <div className="d-none d-sm-flex text-uppercase text-oscuro ml-2 mr-2">
            &nbsp;&nbsp;{t.translate(pagina)}&nbsp;&nbsp;
          </div>
        </Link>
        {productos_genericos &&
          <Link className={this.containsUrl("productos") ? "pagina-actual" : null} to="/tienda/productos_genericos">
            <div className="d-none d-sm-flex ml-2 mr-2 text-uppercase text-oscuro" >
              &nbsp;&nbsp;{t.translate("PRODUCTOS")}&nbsp;&nbsp;
            </div>
          </Link>
        }
        {
        //<a className="d-none d-sm-flex ml-2 mr-2 text-uppercase text-oscuro" href="https://agritradecentralamerica.org">{t.translate("IR A AGRITRADE")}</a>
        }
        <div className="d-sm-none d-flex flex-column justify-content-end ">
          <Menu 
            right            
            disableCloseOnEsc            
            isOpen = {this.state.openedMovil}
          > 
            <Movil 
              close = {this.closeMovil}
            />
          </Menu>          
        </div>
        <div className="align-items-center ml-auto d-none d-sm-flex">
          <DropdownMenu {...menuIdiomas}>            
            <li><a style={{cursor: "pointer"}} onClick={() => {this.props.setIdioma("es"); this.toggleIdiomas();}}><img src={require("assets/img/idiomas/España.png")} alt=""/> ESPAÑOL</a></li>
            <li><a style={{cursor: "pointer"}} onClick={() => {this.props.setIdioma("en"); this.toggleIdiomas();}}><img src={require("assets/img/idiomas/Usa.png")} alt=""/> ENGLISH</a></li>            
          </DropdownMenu>
        </div>
        <Loader active={!!loginLoader} color={"#8CBF3A"} small>
        {(!usuario.username) ? (
          <a style={{cursor: "pointer"}} className="ml-4 ml-lg-5 mr-2 d-none d-sm-inline-block" onClick={() => {
            this.toggleModal();
          }}>{t.translate("INICIAR SESIÓN")}</a>
        ) : (
          <div className="align-items-center ml-4 ml-lg-5 mr-2 d-none d-sm-flex">
            <DropdownMenu {...menuPerfil}>
              <li><a className="d-inline-block d-lg-none" href={"/#/tienda/inicio"} onClick={() => {this.togglePerfil();}}>{t.translate("Entradas")}&nbsp;<em className="fa fa-ticket align-self-center" /></a></li>
              {productos_genericos &&
                <li><a className="d-inline-block d-lg-none" href={"/#/tienda/productos_genericos"} onClick={() => {this.togglePerfil();}}>{t.translate("Productos")}&nbsp;<em className="fa fa-list align-self-center" /></a></li>
              }
              {!!usuario.perfil &&
                <div>
                  <li><a className="" href={"/#/tienda/perfil"} onClick={() => {this.togglePerfil();}}>{t.translate("MI PERFIL")}&nbsp;<em className="fa fa-user align-self-center" /></a></li>                
                  <li><a className="" href={"/#/tienda/perfil"} onClick={() => {this.togglePerfil();}}>{t.translate("MIS COMPRAS")}&nbsp;<em className="fa fa-archive align-self-center" /></a></li>              
                </div>
              }              
              <li><a className="" href={"/#/tienda/inicio"} onClick={() => {
                localStorage.removeItem("token");
                this.props.cerrarSesion();
                this.togglePerfil();}
              }>{t.translate("CERRAR SESIÓN")}&nbsp;<em className="fa fa-lock align-self-center" /></a></li>
            </DropdownMenu>
          </div>
        )}
        </Loader>
        <Modal classNames={{modal:"login-modal pl-sm-5 pr-sm-5"}} showCloseIcon={false} open={openModal} onClose={this.toggleModal}>
          <Login recuperar={this.recuperar} onSubmit={this.login} />
        </Modal>
        <Modal classNames={{modal:"login-modal pl-sm-5 pr-sm-5"}} showCloseIcon={false} open={this.state.modalRecuperar} onClose={() => this.setState({modalRecuperar:false})}>
          <SolicitudPass onSubmit={this.login} />
        </Modal>
      </div>
    );
  }
}

export default Header;

import React, { Component } from 'react';
import { default as DetalleCarito } from './Detalle';
import { default as Carito } from './Carrito';

class Pagos extends Component {
  render() {    
    return (
      <div className="d-flex flex-column flex-md-row bd-highlight m-3 justify-content-center">
          <DetalleCarito />        
          <Carito />                
      </div>
    );
  }
}

export default Pagos;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./compras.css";
import { translator as t } from "traducer";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { Entradas }from './Entradas';
import { HistorialCompras} from './Historial/';


class Compras extends Component {
  static propTypes = {
    usuario: PropTypes.object.isRequired,
  };

  componentWillMount() {    
  }

  render() {
    const { usuario: { compras }, tab, tabChange, page_perfil} = this.props;

    return (
      <div id="perfil-compras">
        <h4 className="mt-4"><strong>{t.translate("MIS COMPRAS")}</strong></h4>
        <Tabs
          activeKey={tab}
          onChange={tabChange}
          renderTabBar={()=><ScrollableInkTabBar />}
          renderTabContent={()=><TabContent />}
        >
          <TabPane tab={t.translate('Entradas')} key="1" >            
            <Entradas />
          </TabPane>          
          <TabPane tab={t.translate('Transacciones')} key="2">
            <HistorialCompras />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Compras;

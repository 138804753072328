import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {TIPOS_ENTRADA} from "utility/variables";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import "./stand.css";
import LoadMask from "Utils/LoadMask";


class Entradas extends Component {
  static propTypes = {
    stand: PropTypes.object.isRequired,
    loading_entradas: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.validarLogin = this.validarLogin.bind(this);
  }

  componentWillMount() {
    this.props.getEntradas(undefined, this.props.match.params.codigo);  
    console.log(this.props);
  }

  validarLogin(id){
    if (!!this.props.usuario.first_name && !!this.props.usuario.perfil) {
      this.props.history.push(`/tienda/stand/${this.props.match.params.codigo}/${id}/editar`);
    } else {
      this.props.setAccionModal('EntradasStand');
      this.props.setModal(true);
    }
  }

    render() {
    const { stand, loading_entradas: loading, filtro,loadingLoader} = this.props;
    let { entradas } = stand;
    if (filtro === false) {
      entradas = _.filter(entradas, {estado: false});
    } else if (filtro === true) {
      entradas = _.filter(entradas, {estado: true});
    }
    const options = {
      hideSizePerPage: true,
      noDataText: loading ? `${t.translate('Cargando')}...` : t.translate('No hay registros'),
      onPageChange: this.props.getEntradas,
    };

    return (
      <div id="stand-entradas" className="m-0 m-sm-2 m-md-5">
        <LoadMask loading={loadingLoader} light color={"#424D88"}>
          <div className="d-flex flex-column">
            <h3 className="p-2" ><strong>{t.translate("MI STAND")}</strong></h3>
            <div className="d-flex flex-column flex-md-row justify-content-between detalle-stand p-3">
              <div className="d-flex justify-content-around flex-1">
                <img src={require("assets/img/icono_stand.png")} alt=""/>
                <div className="d-flex flex-column" style={{borderBottom: "solid"}}>
                  <h3><strong>{stand.empresa}</strong></h3>
                  <h5><strong>{`${t.translate('STAND NO.')} ${stand.id ? stand.id:""}`}</strong></h5>
                  <small>{t.translate("Área")}</small>
                  <h3>{stand.area} {t.translate("metros")}</h3>
                </div>
              </div>
              <div className="d-flex justify-content-around flex-1">
              </div>
            </div>
          </div>
          <div className="form-group d-flex border-top mt-3">
            <h3 className="p-2"><strong>{t.translate("ENTRADAS")}</strong></h3>
            <div className="radio c-radio c-radio-nofont d-flex ml-3 ml-md-5">
              <label className="negro font-weight-normal">
                <input type="radio" name="filtro"
                      ref={node => {
                        this.editados = node;
                        }}
                        onChange={() => {
                          this.props.setFiltro()
                        }}
                        checked={filtro === undefined}
                        value="todos"/>
                <span />Todos
              </label>
            </div>
            <div className="radio c-radio c-radio-nofont d-flex ml-10">
              <label className="negro font-weight-normal">
                <input type="radio" name="filtro"
                      ref={node => {
                        this.editados = node;
                        }}
                        onChange={() => {
                          this.props.setFiltro(false)
                        }}
                        checked={filtro === false}
                        value="no_editados"/>
                <span />No editados
              </label>
            </div>
            <div className="radio c-radio c-radio-nofont d-flex ml-10">
              <label className="negro font-weight-normal">
                <input type="radio" name="filtro"
                      ref={node => {
                        this.editados = node;
                        }}
                        onChange={() => {
                          this.props.setFiltro(true)
                        }}
                        checked={filtro === true}
                        value="no_editados"/>
                <span />Editados
              </label>
            </div>
          </div>
          <BootstrapTable
            data={loading ? [] : entradas}
            remote
            hover
            fetchInfo={{ dataTotalSize: entradas.length }}
            options={options}
            >
            <TableHeaderColumn
              dataAlign="center"
              dataField="tipo_entrada"
              dataFormat={(cell) => {
                const tipo = _.find(TIPOS_ENTRADA, {id: cell});
                return (<img src={tipo.img} alt={tipo.label} />)
              }}
              >
              Img
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="tipo_entrada"
              dataFormat={(cell) => {
                const entrada = _.find(TIPOS_ENTRADA, {id: cell});
                return entrada.label;
              }}
              >
              {t.translate("Tipo de entrada")}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="nombre"
              dataFormat={(cell) => {
                if (!!cell)
                return cell;
                return "---------"
              }}
              >
              {t.translate("Asignado a")}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="estado"
              dataFormat={(cell) => {
                return cell ? t.translate("Editado") : (<strong style={{color: "#C60B1E"}}>{t.translate("Sin editar")}</strong>)
              }}
              >
              {t.translate("Estado")}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="id"
              isKey
              dataFormat={(cell) => {
                return (<button className="btn btn-success" onClick={()=>{
                  this.validarLogin(cell);
                }}>{t.translate("Editar")}</button>)
              }}
              >
              {t.translate("Acciones")}
            </TableHeaderColumn>
          </BootstrapTable>
        </LoadMask>
      </div>
    );
  }
}

export default withRouter(Entradas);

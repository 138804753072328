import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/administracion/productos';
import ListadoProducto from './ListadoProducto';


const ms2p = (state) => {
  const loading = state.productos.loading !== 0;
  return {
    ...state.productos,
    loading,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoProducto);

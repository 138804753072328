import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datos from "./Datos";
import Compras from "./Compras";


class Perfil extends Component {
  static propTypes = {
  };

  componentWillMount() {
  }

  render() {

    return (
      <div id="perfil" className="p-4">
        <Datos />
        <Compras />
      </div>
    );
  }
}

export default Perfil;

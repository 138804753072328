import React, { Component } from 'react';
import _ from "lodash";
import {ToastStore} from "react-toasts";
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form';
import {
  renderField,
  renderSelectField,
  multiSelect,
  renderCurrency,
  renderNumber,
  renderSearchCreateSelect,
} from "Utils/renderField/renderField";
import {COMPRADO, ESTADOS_STAND, STANDS} from "variables";

let urlTienda = "https://store.agritradecentralamerica.org";
try {
  const server = require("./utility/local_settings");
  urlTienda = server.urlTienda;
} catch (err){
}


const Form = props => {

  const { handleSubmit, close, espacio, empresas, nuevaEmpresa, enlace } = props;
  const stands = _.cloneDeep(STANDS);
  const stand = _.find(stands, {espacio});
  const index = stands.indexOf(stand);

  if (index > -1) {
    stands.splice(index, 1);
  }

  return (
    <form onSubmit={handleSubmit} style={{padding: "2rem"}}>
      <div >
        <div className="form-group">
          <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="estado">Estado *</label>
                <Field name="estado" component={renderSelectField} options={ ESTADOS_STAND } type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="empresa">Empresa {nuevaEmpresa && (<small>(Actualización pendiente)</small>)}</label>
                <Field name="empresa" component={renderSearchCreateSelect} options={empresas} placeholder={"buscar"} createLabel={"Añadir temporal:"} labelKey={"Nombre"} valueKey={"Id"} className="form-control"/>
              </div>
            </div>
            <h3 className="m-2">Tarifas Socios</h3>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="so_productor_q">Productor Q *</label>
                <Field name="so_productor_q" component={renderCurrency} prefix={"Q "} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="so_casa_q">Casa comercial Q *</label>
                <Field name="so_casa_q" component={renderCurrency} prefix={"Q "} type="text" className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="so_productor_d">Productor $ *</label>
                <Field name="so_productor_d" component={renderCurrency} prefix={"$ "} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="so_casa_d">Casa comercial $ *</label>
                <Field name="so_casa_d" component={renderCurrency} prefix={"$ "} type="text" className="form-control"/>
              </div>
            </div>
            <h3 className="m-2">Tarifa No Socios</h3>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="ns_productor_q">Productor Q *</label>
                <Field name="ns_productor_q" component={renderCurrency} prefix={"Q "} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="ns_casa_q">Casa comercial Q *</label>
                <Field name="ns_casa_q" component={renderCurrency} prefix={"Q "} type="text" className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="ns_productor_d">Productor $ *</label>
                <Field name="ns_productor_d" component={renderCurrency} prefix={"$ "} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="ns_casa_d">Casa comercial $ *</label>
                <Field name="ns_casa_d" component={renderCurrency} prefix={"$ "} type="text" className="form-control"/>
              </div>
            </div>
            <div className="d-flex flex-column form-group m-1">
              <label htmlFor="vecinos">Vecinos *</label>
              <Field name="vecinos" component={multiSelect} valueKey={"espacio"} labelKey={"espacio"} options={stands} type="text" className="form-control"/>
            </div>
            <h3 className="m-2">Entradas *</h3>
            <div className="d-flex justify-content-between flex-column flex-sm-row align-items-end">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="full_access">Full Access</label>
                <Field name="full_access" component={renderNumber} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="un_dia">Programa de Conferencias y Piso de Exhibición</label>
                <Field name="un_dia" component={renderNumber} className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="apoyo">Apoyo</label>
                <Field name="apoyo" component={renderNumber} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="estudiante">Estudiante</label>
                <Field name="estudiante" component={renderNumber} className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row align-items-end">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="marbetes_parqueo">Marbetes Parqueo</label>
                <Field name="marbetes_parqueo" component={renderNumber} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="piso_exhibicion">Piso Exhibición</label>
                <Field name="piso_exhibicion" component={renderNumber} className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="piso_exhibicion_sabado">Pisio Exhibición Sábado</label>
                <Field name="piso_exhibicion_sabado" component={renderNumber} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="conferencia">Conferencias</label>
                <Field name="conferencia" component={renderNumber} className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="staff">Staff</label>
                <Field name="staff" component={renderNumber} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="prensa">Prensa</label>
                <Field name="prensa" component={renderNumber} className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="invitado_especial">Invitado Especial</label>
                <Field name="invitado_especial" component={renderNumber} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="comite_organizador">Comite Organizador</label>
                <Field name="comite_organizador" component={renderNumber} className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="comprador">Comprador</label>
                <Field name="comprador" component={renderNumber} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="productor">Productor</label>
                <Field name="productor" component={renderNumber} className="form-control"/>
              </div>
            </div>           
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label>Enlace para editar las entradas</label>
                <Field id="enlace" name="codigo" component={"input"} type="text" className="d-none" />
                <label
                  style={{color: "#0666B8", cursor: "pointer"}}
                  onClick={() => {
                    const copyText = document.getElementById("enlace");
                    copyText.className = "d-flex";
                    copyText.value = `${urlTienda}/#/tienda/stand/${enlace}`;
                    copyText.select();
                    document.execCommand("copy");
                    copyText.className = "d-none";
                    ToastStore.success("Enlace copiado al portapales");
                  }}>&nbsp;&nbsp;&nbsp;{`${urlTienda}/#/tienda/stand/${enlace}`}&nbsp;&nbsp;<em className="fa fa-copy"/></label>
              </div>
            </div>
            <br/>
            <div className="d-flex justify-content-center flex-row">
              <button type="button" className="btn btn-secondary m-1 align-self-center" onClick={close}>Cancelar</button>
              <button type="submit" className="btn btn-primary m-1 align-self-center">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

const EdicionStand = reduxForm({
  // a unique name for the form
  form: 'EdicionStand',
  validate: data => {
    let empresa;
    if (parseInt(data.estado) === COMPRADO){
      empresa = validators.exists()("Campo requerido");
    }
    // TODO Alertar cuando está reduciendo el número de entradas
    return validate(data, {
      empresa,
      so_productor_q: validators.exists()("Campo requerido"),
      so_casa_q: validators.exists()("Campo requerido"),
      so_productor_d: validators.exists()("Campo requerido"),
      so_casa_d: validators.exists()("Campo requerido"),
      ns_productor_q: validators.exists()("Campo requerido"),
      ns_casa_q: validators.exists()("Campo requerido"),
      ns_productor_d: validators.exists()("Campo requerido"),
      ns_casa_d: validators.exists()("Campo requerido"),
    })
  }
})(Form);

export default EdicionStand

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/planta/planta';
import { actions as cuentaActions } from "../../../../../redux/modules/tienda/cuenta";
import Movil from './Movil';


const ms2p = (state) => {
  const loginLoader = state.cuenta.loading;
  const productos_genericos = state.cuenta.productos_genericos;  
  return {
    ...state.planta,
    loginLoader,
    usuario: state.cuenta.usuario,
    productos_genericos,        
  };
};

const md2p = { ...actions, ...cuentaActions };

export default connect(ms2p, md2p)(Movil);
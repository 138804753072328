import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/tienda/tienda';
import { setModal, setAccionModal } from "../../../../../../redux/modules/tienda/cuenta";
import Oportunidades from './Oportunidades';


const ms2p = (state) => {
  const { idioma } = state.planta;
  return {
    ...state.tienda,
    idioma: idioma.codigo,
    usuario: state.cuenta.usuario,
    loading: state.tienda.loading !== 0,
  };
};

const md2p = { ...actions, setLoginModal: setModal, setAccionModal };

export default connect(ms2p, md2p)(Oportunidades);

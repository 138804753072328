import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderImagePicker, renderCurrency, renderTextArea, renderSwitch } from "Utils/renderField/renderField";
import { Link } from "react-router-dom";
import {renderSelectField} from "../../../Utils/renderField";
import {TIPOS_ENTRADA} from "../../../../../utility/variables";


const Form = props =>{
  const { handleSubmit, photo, setFile, es_entrada } = props;
  return(

    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
          <div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap">
            <div className="d-flex flex-column form-group flex-1 m-2 w-100" style={{minWidth: "300px"}}>
              <label htmlFor="nombre">Nombre *</label>
              <Field name="nombre" disabled component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-2 w-100" style={{minWidth: "300px"}}>
              <label htmlFor="texto_comercial">Texto Comercial *</label>
              <Field name="texto_comercial" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-2 w-100" style={{minWidth: "300px"}}>
              <label htmlFor="texto_comercial_ingles">Texto Comercial Inglés *</label>
              <Field name="texto_comercial_ingles" component={renderField} type="text" className="form-control"/>
            </div>
          </div>
          <div className="d-flex flex-column flex-xl-row">
            <div className="d-flex flex-column form-group flex-1 m-2 w-100">
              <label htmlFor="descripcion">Descripción *</label>
              <Field name="descripcion" component={renderTextArea} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-2 w-100">
              <label htmlFor="descripcion_ingles">Descripción Inglés *</label>
              <Field name="descripcion_ingles" component={renderTextArea} type="text" className="form-control"/>
            </div>
          </div>
          <div className="d-flex flex-column flex-xl-row">
            <div className="d-flex flex-column form-group flex-1 m-2 w-100">
              <label htmlFor="vendible">Venta al público</label>
              <Field name="vendible" component={renderSwitch} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-2 w-100">
              <label htmlFor="es_entrada">Es Entrada</label>
              <Field name="es_entrada" component={renderSwitch} type="text" className="form-control"/>
            </div>
            {es_entrada && (<div className="d-flex flex-column form-group flex-1 m-2 w-100">
              <label htmlFor="tipo_entrada">Tipo de entrada *</label>
              <Field name="tipo_entrada" component={renderSelectField} options={ TIPOS_ENTRADA } type="text" className="form-control"/>
            </div>)}
          </div>
          {!es_entrada && (<div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap">
            <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="foto">Foto *</label>
              <Field photo={photo} setFile={setFile} name="foto" component={renderImagePicker} className=""/>
            </div>
          </div>)}
          <br/>
          <div className="d-flex justify-content-center flex-row">
            <Link to="/administrar_productos" className="btn btn-secondary m-1 align-self-center" >Cancelar</Link>
            <button type="submit" className="btn btn-default m-1 align-self-center">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  )
};

const EditarUser = reduxForm({
  // a unique name for the form
  form : 'EdicionProducto',
  validate: (data, props) => {
    return validate(data, {
    })
  }
})(Form);

export default EditarUser;

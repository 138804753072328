import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/cuenta';
import { setVenta } from '../../../../../redux/modules/tienda/tienda';
import Exito from './Exito';


const ms2p = (state) => {
    return {
    ...state.cuenta,
    venta : state.tienda.venta,    
  };
};

const md2p = { ...actions,setVenta };

export default connect(ms2p, md2p)(Exito);
import * as React from 'react';
import { Field, change, reduxForm } from 'redux-form'
import { translator as t } from 'traducer';
import { validate, validators, validatorFromFunction } from 'validate-redux-form';
import { renderField, renderSelectField} from "Utils/renderField/renderField";
import Loader from "Utils/Loader";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { PAISES, ESTILOS_SELECT_REGISTRO } from 'variables';
import Select, { AsyncCreatable, Async } from 'react-select';
import validarNIT from 'validar-nit-gt';
import { ValueContainer } from 'react-select/lib/animated';

let mostrar=undefined
let mostrar1=null
let vaciarNit=false
let nit1
let nit2




const nitField = ({input, value, label, type, meta: { touched, error }, className, onFocusChange}) => {
  const invalid = touched && error;
  return (
    <div>
      <input
        {...input}
        onBlur={() => {
          onFocusChange()
        }}
        onFocus={() => {
          onFocusChange()
        }}
        placeholder={label}
        type={type}
        className={classNames(!!className ? className : "form-control", { 'is-invalid': invalid })}
        disabled={mostrar}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

const razonFiscalField = ({input, label, type, meta: { touched, error }, className, onFocusChange}) => {
  const invalid = touched && error;
  return (
    <div>
      <input
        {...input}
        onBlur={() => {
          onFocusChange()
        }}
        onFocus={() => {
          onFocusChange()
        }}
        placeholder={label}
        type={type}
        className={classNames(!!className ? className : "form-control", { 'is-invalid': invalid })}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

const Form = props =>{
  const { handleSubmit, loader, popover, change, togglePopover,popover2, togglePopover2 } = props;

  const renderSelectField2 = ({ valueKey, styles, placeholder, labelKey, input, disabled, options, meta: { touched, error }, }) => {
    const invalid = touched && error;  
    return (
      <div>
        <Select isDisabled={disabled} className={classNames('', { 'is-invalid': invalid })}
                options={options}
                value={input.value}
                styles={styles}              
                getOptionValue={(option) => option[valueKey]?option[valueKey]:option.value}
                getOptionLabel={(option) => option[labelKey]?option[labelKey]:option.label}
                onChange={(e) => {
                  input.onChange(e);
                  if (e.id == "GT")
                  change('nit' ,""),
                    mostrar1=null,
                    mostrar=null
                  else
                    change('nit' ,"CF"),
                    mostrar1=true,
                    mostrar=true,
                    vaciarNit = !vaciarNit
                }}
                placeholder={placeholder}
                >
        </Select>
        {invalid && (
          <div className="invalid-feedback">
            {error}
          </div>
        )}
      </div>
    );
  };

  return(
    
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
          <div className="d-flex align-items-center justify-content-sm-start flex-column flex-wrap">
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="first_name">{t.translate('Nombre *')}</label>
              <Field name="first_name" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="last_name">{t.translate('Apellidos *')}</label>
              <Field name="last_name" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="username">{t.translate('Correo *')}</label>
              <Field name="username" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="telefono">{t.translate('Teléfono *')}</label>
              <Field name="telefono" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label id={"Popover2"} htmlFor="razon_fiscal">{t.translate('Razón Social *')}</label>
              <Popover placement={window.innerWidth > 940 ? "left" : "top"} isOpen={popover2} target="Popover2" toggle={() => {}}>
                <PopoverBody>{t.translate("Ingrese el nombre del cliente o empresa con el cual sera emitido su recibo")}</PopoverBody>
              </Popover>
              <Field name="razon_fiscal" onFocusChange={togglePopover2} component={razonFiscalField} type="text" className="form-control"/>
            </div>
            <div className=" flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label id={"Popover1"} htmlFor="nit">{t.translate('Nit *')}</label>
              <Popover placement={window.innerWidth > 940 ? "left" : "top"} isOpen={popover} target="Popover1" toggle={() => {}}>
                <PopoverBody>{t.translate("Si es asociado a Agexport por favor  utilice el nit de la empresa registrada")}</PopoverBody>
              </Popover>
              <Field name="nit" onFocusChange={togglePopover} component={nitField} type="text" className="form-control" disabled={mostrar}/> 
              <Field
              name="nit_check"
              component="input"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked==true)
                  change('nit' ,"CF"),
                  nit1=undefined,
                  mostrar=true,
                  vaciarNit = !vaciarNit
                else
                  change('nit' ,""),
                  mostrar=undefined,
                  nit1=undefined
              }}
              disabled={mostrar1}
              />
              <label id={"Popover1"} htmlFor="CF" disabled={mostrar1}>{t.translate('CF')}</label>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="pais">{t.translate('País *')}</label>
              <Field name="pais" component={renderSelectField2} options={PAISES} styles={ESTILOS_SELECT_REGISTRO} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="ciudad">{t.translate('Ciudad *')}</label>
              <Field name="ciudad" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="direccion">{t.translate('Dirección *')}</label>
              <Field name="direccion" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-2 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="codigo_postal">{t.translate('Código postal *')}</label>
              <Field name="codigo_postal" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="password">{t.translate('Contraseña *')}</label>
              <Field name="password" component={renderField} type="password" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="pass_confirmation">{t.translate('Confirmar Contraseña *')}</label>
              <Field name="pass_confirmation" component={renderField} type="password" className="form-control"/>
            </div>
          </div>
          <br/>
          <div className="d-flex justify-content-center flex-row">
            <Link to="/tienda/inicio" className="btn btn-secondary m-1 align-self-center">
              <strong>
                {t.translate('Cancelar')}
              </strong>
            </Link>
            <button type="submit" className="btn btn-default m-1 align-self-center">
              <Loader active={loader} small>
                <strong>
                  {t.translate('Crear cuenta')}
                </strong>
              </Loader>
            </button>
          </div>
        </div>
      </div>
    </form>
  )
};

const RegistroForm = reduxForm({
  // a unique name for the form
  form : 'TiendaRegistro',
  validate: (data, props) => {
    const valido = validarNIT(data.nit)
    const pass_confirmation = validatorFromFunction(() => (data.password === data.pass_confirmation));
    let correo = validators.exists()(t.translate("Este campo es requerido"));
    if (!!data.correo) {
      correo = validators.regex(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)(t.translate('Debe ser un correo válido'));
    }
    let nit
    if(data.nit !== "CF"){
      nit = validatorFromFunction(() => (valido))()(t.translate("nit invalido"));
    }
    let telefono = validators.exists()(t.translate("Este campo es requerido"));
    if(!!data.telefono) {
      telefono = validatorFromFunction(() => (data.telefono.length <= 15))()(t.translate("Longitud máxima de 15 caracteres"))
    }
    return validate(data, {
      first_name: validators.exists()(t.translate("Este campo es requerido")),
      last_name: validators.exists()(t.translate("Este campo es requerido")),
      username: validators.exists()(t.translate("Este campo es requerido")),
      correo,
      nit,
      direccion: validators.exists()(t.translate("Este campo es requerido")),
      password: validators.exists()(t.translate("Este campo es requerido")),
      pass_confirmation: pass_confirmation()(t.translate("Las contraseñas no coinciden")),
      telefono,
      codigo_postal: validators.exists()(t.translate("Este campo es requerido")),
      pais: validators.exists()(t.translate("Este campo es requerido")),
      ciudad: validators.exists()(t.translate("Este campo es requerido")),
      razon_fiscal: validators.exists()(t.translate("Este campo es requerido")),
    })
  }
})(Form);

export default RegistroForm;
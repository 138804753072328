import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import ResetForm from "./ResetForm";
import { Redirect } from 'react-router-dom';
import LoadMask from "Utils/LoadMask";
import { logo_es, logo_en } from "variables";


class Reset extends Component {
  static propTypes = {
  };
  constructor(props) {
    super(props);
  }

  componentWillMount() {
  }

  onSubmit = (data) => {
    this.props.restablecerPass({...data, token: this.props.match.params.token});
  };

  render() {
    const { idioma, loading } = this.props;
    if (!this.props.match.params.token) {
      return (<Redirect to="/tienda/inicio" />);
    }
    return (
      <div id="tienda-registro">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={ idioma.codigo ==="es"? logo_es: logo_en } alt="Agritrade"/>
        </div>
        <div className="d-flex flex-row barra justify-content-center">
          <div className="verde" />
          <div className="naranja" />
          <div className="rojo" />
          <div className="amarillo" />
          <div className="azul" />
        </div>
        <br />
        <LoadMask loading={loading !== 0} light color={"#424D88"}>
          <center><h4><strong>{t.translate("RESTABLECER CONTRASEÑA")}</strong></h4></center>
          <ResetForm onSubmit={this.onSubmit} loader={loading !== 0}/>
        </LoadMask>
      </div>
    );
  }
}

export default Reset;

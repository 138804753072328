import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSelectField, multiSelect } from "Utils/renderField/renderField";
import { Link } from "react-router-dom";


const Form = props =>{
  const { handleSubmit, contrasenia, cambiarPass } = props;
  return(

    <form onSubmit={handleSubmit}>
      <div >
        <div className="form-group">
          <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
            <div className="d-flex flex-wrap flex-column flex-sm-row">
              <div className="d-flex flex-column form-group ml-2 mr-2 mr-md-4 mr-lg-5 ml-md-4 ml-lg-5" style={{minWidth: "300px"}}>
                <label htmlFor="first_name">Nombre *</label>
                <Field name="first_name" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group ml-2 mr-2 mr-md-4 mr-lg-5 ml-md-4 ml-lg-5" style={{minWidth: "300px"}}>
                <label htmlFor="username">Usuario de sistema *</label>
                <Field name="username" component={renderField} type="text" className="form-control" />
              </div>
              {contrasenia && (<div className="d-flex flex-column form-group ml-2 mr-2 mr-md-4 mr-lg-5 ml-md-4 ml-lg-5" style={{minWidth: "300px"}}>
                <label htmlFor="password">Contraseña *</label>
                <Field name="password" component={renderField} type="text" className="form-control"/>
              </div>)}            
              {!contrasenia && (<div className="d-flex flex-column form-group ml-2 mr-2 mr-md-4 mr-lg-5 ml-md-4 ml-lg-5" style={{minWidth: "300px"}}>
                <a className="btn btn-default mt-auto ml-auto mr-auto" style={{cursor: "pointer", color: "#fff"}} onClick={()=>{cambiarPass()}}>Cambiar Contraseña</a>
              </div>)}
            </div>
            <div className="d-flex justify-content-center flex-row mt-3">
              <Link to="/usuarios" className="btn btn-secondary m-1 align-self-center" >Cancelar</Link>
              <button type="submit" className="btn btn-agregar m-1 align-self-center">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
const EditarUser = reduxForm({
  // a unique name for the form
  form : 'EditarUser',
  validate: (data, props) => {
    const password = props.contrasenia ? validators.exists()("Campor requerido") : undefined;
    return validate(data, {
      username: validators.exists()("Campor requerido"),
      first_name: validators.exists()("Campo requerido"),
      password,
    })
  }
})(Form);

export default EditarUser;

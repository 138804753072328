import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import _ from "lodash";
import { translator as t } from "traducer";
import { Link } from "react-router-dom";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import swal from 'sweetalert2';


class Compras extends Component {
  static propTypes = {
    usuario: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.getCompras();
  }

  render() {
    const { compras, loading_entradas: loading, page_compras: page, archivo } = this.props;
    
    const options = {
      hideSizePerPage: true,
      noDataText: loading ? `${t.translate('Cargando')}...` : t.translate('No hay registros'),
      page: page,
      onPageChange: this.props.getCompras,
    };

    return (
      <div id="perfil-compras">
        <BootstrapTable
          data={loading ? [] : compras.results}
          remote
          hover
          fetchInfo={{ dataTotalSize: compras.count }}
          options={options}
          pagination
        >
          <TableHeaderColumn
                dataAlign="center"
                dataField="transferencia"
                dataSort
                iskey               
                dataFormat={(cell,row) => {
                  if (cell)
                    return cell;
                  return row.id
                }}
              >
                {t.translate("Transacción")}
          </TableHeaderColumn>
          <TableHeaderColumn
                dataAlign="center"
                dataField="creado"
                dataSort               
                dataFormat={(cell) => {
                  if (cell)
                    return <RenderDate value={cell} />;
                  return "--------"
                }}
              >
                {t.translate("Fecha de compra")}
          </TableHeaderColumn>
          <TableHeaderColumn
                dataAlign="center"
                dataField="moneda"
                dataSort               
                dataFormat={(cell) => {
                  if (cell)
                    return cell;
                  return "--------"
                }}
              >
               {t.translate("Moneda")} 
          </TableHeaderColumn>
          <TableHeaderColumn
                dataAlign="right"
                dataField="total"
                dataSort                
                dataFormat={(cell,row) => {                  
                  if (cell)
                    return <RenderCurrency value={cell} prefix={row.moneda === "GTQ" ? "Q": "$"} />;
                  return "0.00"
                }}
              >
                {t.translate("Total")}
          </TableHeaderColumn>
          <TableHeaderColumn
                dataAlign="center"
                dataField="id"
                dataSort
                isKey
                dataFormat={(cell,row) => {
                  const cont = row.recibo_compra.length; 
                  if(cont>0){
                      const recibo = row.recibo_compra
                      if(cont<2){
                        return(
                          <div>
                            <Link className="mr-1 btn btn-secondary" to={`/tienda/entrada/historial/detalle/${cell}`}><i className="fa fa-info-circle"/></Link>                          
                           { 
                             recibo.map(rec => {
                              return(
                                <a key={rec.id}className="mr-1 btn btn-success" href={rec.archivo} download target="_blank"><em className="fa fa-download"/></a>
                              )                            
                            })}
                          </div>
                        )                       
                      }else{
                        return(
                          <div>
                              <Link className="mr-1 btn btn-secondary" to={`/tienda/entrada/historial/detalle/${cell}`}><i className="fa fa-info-circle"/></Link>                              
                              <button className="mr-1 btn btn-success" onClick={()=>{
                                this.props.getZip(cell)                                
                              }}><em className="fa fa-download"/></button>
                          </div>
                        )
                      }
                  }
                  return(
                      <div>
                        <Link className="mr-1 btn btn-secondary" to={`/tienda/entrada/historial/detalle/${cell}`}><i className="fa fa-info-circle"/></Link>                      
                      </div>
                    )                  
                }}
              >
                {t.translate("Acciones")}
          </TableHeaderColumn>         
        </BootstrapTable>
      </div>      
    );
  }
}

export default Compras;

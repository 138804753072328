import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { Link } from "react-router-dom";
import "./transacciones.css";
import DatePicker from "Utils/DatePicker";


class ListadoTransacciones extends Component{
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

  }
  componentWillMount() {
    this.props.getTransacciones();
  }

  render(){
    const { loading, data, search, page, fecha_inicial, fecha_final, filtro, total } = this.props;
    const options = {
      hideSizePerPage: true,
      noDataText: 'No hay registros',
      prePage: 'Anterior',
      nextPage: 'Siguiente',
      firstPage: 'Primero',
      lastPage: 'Último',
    };
    options.page = page;
    options.onPageChange = this.props.getTransacciones;
    options.noDataText = loading ? "Cargando..." : options.noDataText;
    return(
      <div id="transacciones-admin">
        <div className="">
          <div className="pt-5 pb-5 pl-1 pr-1 p-sm-5">
            <div className="fondo">
              <div className="buscador d-flex flex-row justify-content-between p-3 encabezado-azul">
                <div className="d-flex align-items-center mr-2">
                  <h2 className="d-none d-sm-inline-block m-0">Transacciones</h2>
                  <h4 className="d-inline-block d-sm-none m-0">Transacciones</h4>
                </div>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="toolbar-search">
                    <input className="form-control" type="text" placeholder="Buscar"
                           ref={node => {
                             this.buscar = node;
                             if (this.buscar) {
                               this.buscar.value = search;
                             }
                           }}
                           onChange={() => {
                             if (this.buscar) {
                               this.props.searchChange(this.buscar.value);
                             }
                           }}
                           autoComplete="off"
                    />
                    <span className="fa fa-search icon-search" />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row p-2">
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start" style={{flex: "3"}}>
                  <div>
                    <label>Fecha Inicio</label>
                    <DatePicker
                      value={fecha_inicial}
                      maxDate={new Date()}
                      onChange={(date) => {
                        this.props.setFecha('Inicial', date);
                      }}
                    />
                  </div>
                  &nbsp;
                  &nbsp;
                  <div>
                    <label>Fecha Fin</label>
                    <DatePicker
                      value={fecha_final}
                      minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                      onChange={(date) => {
                        this.props.setFecha('Final', date);
                      }}
                    />
                  </div>
                  <div className="radio c-radio c-radio-nofont d-flex ml-3 ml-md-5">
                    <label className="negro font-weight-normal">
                      <input type="radio" name="filtro"
                             ref={node => {
                               this.editados = node;
                             }}
                             onChange={() => {
                               this.props.setFiltro()
                             }}
                             checked={filtro === undefined}
                             value="todos"/>
                      <span />Todos
                    </label>
                  </div>
                  &nbsp;
                  <div className="radio c-radio c-radio-nofont d-flex ml-10">
                    <label className="negro font-weight-normal">
                      <input type="radio" name="filtro"
                             ref={node => {
                               this.editados = node;
                             }}
                             onChange={() => {
                               this.props.setFiltro(false)
                             }}
                             checked={filtro === false}
                             value="no_editados"/>
                      <span />Contado
                    </label>
                  </div>
                  &nbsp;
                  <div className="radio c-radio c-radio-nofont d-flex ml-10">
                    <label className="negro font-weight-normal">
                      <input type="radio" name="filtro"
                             ref={node => {
                               this.editados = node;
                             }}
                             onChange={() => {
                               this.props.setFiltro(true)
                             }}
                             checked={filtro === true}
                             value="editados"/>
                      <span />Crédito
                    </label>
                  </div>
                </div>
              </div>
              <BootstrapTable
                data={loading ? [] : data.results}
                remote
                hover
                fetchInfo={{ dataTotalSize: data.count }}
                options={options}
                pagination
              >
                <TableHeaderColumn
                  dataField="transferencia"
                  dataSort
                  dataFormat={(cell,row) => {
                    if (cell)
                      return cell;
                    else
                      return row.id
                  }}
                >
                  Transacción
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="es_oportunidad"
                  dataSort
                  dataFormat={(cell,row) => {
                    if (cell)
                      return "Crédito";
                    else
                      return "Contado"
                  }}
                >
                  Tipo
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="usuario"
                  dataAlign="center"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell.nombre;
                    return "--------"
                  }}
                >
                  Cliente
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="usuario"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell.correo;
                    return "--------"
                  }}
                >
                  Correo
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="usuario"
                  dataAlign="center"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell.nit;
                    return "--------"
                  }}
                >
                  Nit
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="creado"
                  dataAlign="center"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return <RenderDate value={cell} />;
                    return "--------"
                  }}
                >
                  Fecha de compra
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="total"
                  dataAlign="right"
                  dataSort
                  dataFormat={(cell,row) => {
                    if (cell)
                      return <RenderCurrency value={cell} prefix={row.moneda === "GTQ" ? "Q": "$"} />;
                    return "0.00"
                  }}
                >
                  Total
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataAlign="center"
                  isKey
                  dataFormat ={(cell,row) =>{
                    return(
                      <div>
                        <Link className="mr-1 btn btn-agregar" to={`/administrar_transacciones/detalle/${cell}`}><i className="fa fa-info-circle"/></Link>
                      </div>
                    )
                  }}
                >
                  Acciones
                </TableHeaderColumn>
              </BootstrapTable>
              <div className="d-flex flex-column justify-content-end align-items-end p-2">
                <div>
                  Total ventas en quetzales:&nbsp;<strong><RenderCurrency value={!!total.quetzales ? total.quetzales : 0} prefix={"Q"} /></strong>
                </div>
                <div>
                  Total ventas en dólares:&nbsp;<strong><RenderCurrency value={!!total.dolares ? total.dolares : 0} prefix={"$"} /></strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListadoTransacciones;

import React , { Component }from "react";
import { translator as t } from "traducer";
import {logo_blanco_es, logo_blanco_en} from "utility/variables";
import { Link } from 'react-router-dom';
import './movil.css';

const sesion = require('assets/img/iniciar_sesion.png');
class Movil extends Component{
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);        
      }
    
    toggleModal() {
      this.props.setAccionModal(null);
      this.props.setModal(!this.props.modal);
    }            
  
    render(){
        const { idioma, usuario,close,productos_genericos } = this.props;
        return(
            <div id="menu-movil">
                <Link to="/tienda/inicio">
                    <img src={idioma.codigo === "es" ? logo_blanco_es:logo_blanco_en} />                              
                </Link>
                <div className="d-flex flex-column align-items-end">                 
                  <div className="blanco w-100 mt-2 mb-2"></div>                
                  <li><a className="d-inline-block" href={"/#/tienda/inicio"} onClick={() => {close();}}>{t.translate("Entradas")}&nbsp;<em className="fa fa-ticket" /></a></li>
                  {productos_genericos && <li><a className="d-inline-block" href={"/#/tienda/productos_genericos"} onClick={() => {close();}}>{t.translate("Productos")}&nbsp;<em className="fa fa-archive" /></a></li>}
                  {(!usuario.username) && (<li><a style={{cursor: "pointer" }} className="d-inline-block" onClick={() => this.toggleModal()}>{t.translate("Iniciar sesión")}&nbsp;<img style={{maxWidth: "15px"}} className="align-self-center" src={sesion} /></a></li>)}
                  {(!!usuario.perfil) && (<li><a className="d-inline-block" href={"/#/tienda/perfil"} onClick={() => {close();}}>{t.translate("Mi perfil")}&nbsp;<em className="fa fa-user align-self-center" /></a></li>)}
                  {(!!usuario.perfil) && (<li><a className="d-inline-block" href={"/#/tienda/perfil"} onClick={() => {close();}}>{t.translate("Mis compras")}&nbsp;<em className="fa fa-archive align-self-center" /></a></li>)}
                  <div className="blanco w-100 mt-2 mb-2"></div>                
                  <li><a onClick={() => {this.props.setIdioma("es"); close();}}>ESPAÑOL <img src={require("assets/img/idiomas/España.png")} alt=""/></a></li>
                  <li><a onClick={() => {this.props.setIdioma("en"); close();}}>ENGLISH <img src={require("assets/img/idiomas/Usa.png")} alt=""/></a></li>
                  <div className="blanco w-100 mt-2 mb-2"></div>
                  {(!!usuario.username) && (<li><a className="d-inline-block" href={"/#/tienda/inicio"} onClick={() => {
                    localStorage.removeItem("token");
                    this.props.cerrarSesion();
                    }
                  }>{t.translate("Cerrar sesión")}&nbsp;<img style={{maxWidth: "15px"}}src={sesion} /></a></li>)}                  
                </div>
            </div>
        )
    }
}

export default Movil;
import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { translator as t } from 'traducer';
import { validate, validators, validatorFromFunction } from 'validate-redux-form';
import { renderField, renderImagePicker, renderCurrency, renderTextArea, renderSwitch } from "Utils/renderField/renderField";
import {Link} from "react-router-dom";
import Loader from "Utils/Loader";


const Form = props =>{
  const { handleSubmit, loader } = props;
  return(

    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
          <div className="d-flex align-items-center justify-content-sm-start flex-column flex-wrap">
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="password">{t.translate('Contraseña *')}</label>
              <Field name="password" component={renderField} type="password" className="form-control"/>
            </div>
            <div className="d-flex flex-column form-group flex-1 m-1 w-100" style={{minWidth: "300px", maxWidth: "300px"}}>
              <label htmlFor="pass_confirmation">{t.translate('Confirmar Contraseña *')}</label>
              <Field name="pass_confirmation" component={renderField} type="password" className="form-control"/>
            </div>
          </div>
        </div>
        <br/>
        <div className="d-flex justify-content-center flex-row">
          <button type="submit" className="btn btn-default m-1 align-self-center">
            <Loader active={loader} small>
              <strong>
                {t.translate('Restablecer contraseña')}
              </strong>
            </Loader>
          </button>
        </div>
      </div>
    </form>
  )
};

const ResetPass = reduxForm({
  // a unique name for the form
  form : 'ResetPass',
  validate: (data, props) => {
    const pass_confirmation = validatorFromFunction(() => (data.password === data.pass_confirmation));
    return validate(data, {
      password: validators.exists()(t.translate("Este campo es requerido")),
      pass_confirmation: pass_confirmation()(t.translate("Las contraseñas no coinciden")),
    })
  }
})(Form);

export default ResetPass;

import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { setUsuario } from "./redux/modules/tienda/cuenta";

// maquetado base
import { Navbar }from './common/components/Administracion';

// funciona para determinar si puede acceder a la vista
function isAuthenticated(usuario, setUsuario) {
  if (!!usuario.perfil) {
    localStorage.removeItem('token');
    setUsuario({});
  }
  return localStorage.getItem('token');
}

class AdminRoute extends Component {
  render() {
    const { component: Component, usuario, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={props => (isAuthenticated(usuario, this.props.setUsuario) ? (
          <div>
            <Navbar />
            <div className="content-wrapper">
              <Component {...props} />
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ))
                }
      />
    );
  }
}

const ms2p = state => ({usuario: state.cuenta.usuario});

const md2p = {setUsuario};

const ProtectedRoute = connect(
    ms2p,
    md2p
)(AdminRoute);

export default ProtectedRoute;

import React, { Components } from 'react';
import Select from 'react-select';
import { TIPOS_MONEDA, ESTILOS_SELECT } from 'variables';

 
const Moneda = props =>{  
    const { handleChange, moneda } = props;     
    return(
        <div>            
            <Select
                styles={ ESTILOS_SELECT }
                defaultValue={moneda==='GTQ'?TIPOS_MONEDA[0]:TIPOS_MONEDA[1]}  
                options={TIPOS_MONEDA}
                onChange={handleChange}                                   
            />
        </div>
    )
}
export default Moneda;
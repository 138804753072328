import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'react-dd-menu';
import "./registro.css";
import { translator as t } from "traducer";
import RegistroForm from "./RegistroForm";
import { Redirect } from 'react-router-dom';
import LoadMask from "Utils/LoadMask";
import { logo_es, logo_en } from "variables";


class Registro extends Component {
  static propTypes = {
    registrar: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {opened: false, openedIdiomas: false, popover: false, popover2:false};
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
    this.closeIdiomas = this.closeIdiomas.bind(this);
    this.toggleIdiomas = this.toggleIdiomas.bind(this);
  }

  componentWillMount() {
    this.props.setIdioma(localStorage.getItem('lang') || 'es');
    this.props.inicializarRegistro();
  }

  close() {
    this.setState({ opened: !this.state.opened });
  };

  toggle() {
    this.setState({ opened: !this.state.opened });
  };

  closeIdiomas() {
    this.setState({ openedIdiomas: !this.state.openedIdiomas });
  };

  toggleIdiomas() {
    this.setState({ openedIdiomas: !this.state.openedIdiomas });
  };

  togglePopover = () => {
    this.setState({popover: !this.state.popover});
  };

  togglePopover2 = () => {
    this.setState({popover2: !this.state.popover2});
  };

  render() {
    const { idioma, loading } = this.props;
    if (localStorage.getItem('token')) {
      return (<Redirect to="/tienda/carrito" />);
    }
    const menuIdiomas = {
      isOpen: this.state.openedIdiomas,
      close: this.closeIdiomas,
      closeOnInsideClick: false,
      toggle: <li style={{display: "inline-block"}}>
        <a
          className="d-none d-sm-inline-block"
          style={{cursor: "pointer"}}
          onClick={this.toggleIdiomas}>
          <img src={require(`assets/img/idiomas/${idioma.img}.png`)} alt=""/>&nbsp;{idioma.nombre} <em className="fa fa-caret-down" />
        </a>
        <a
          className="d-inline-block d-sm-none"
          style={{cursor: "pointer"}}
          onClick={this.toggleIdiomas}>
          <em className="fa fa-navicon" />
        </a>
      </li>,
      align: 'right',
    };
    return (
      <div id="tienda-registro">
        <div className="d-flex justify-content-end align-items-center ml-5 mr-5 mt-3">
          <DropdownMenu {...menuIdiomas}>
            <li><a style={{cursor: "pointer", borderBottom: "solid 1px #cacaca"}} className="d-inline-block d-sm-none" onClick={() => this.toggleModal()}>{t.translate("INICIAR SESIÓN")}</a></li>
            <li><a style={{cursor: "pointer"}} onClick={() => {this.props.setIdioma("es"); this.toggleIdiomas();}}><img src={require("assets/img/idiomas/España.png")} alt=""/> ESPAÑOL</a></li>
            <li><a style={{cursor: "pointer"}} onClick={() => {this.props.setIdioma("en"); this.toggleIdiomas();}}><img src={require("assets/img/idiomas/Usa.png")} alt=""/> ENGLISH</a></li>
          </DropdownMenu>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={ idioma.codigo ==="es"? logo_es: logo_en } alt="Agritrade"/>
        </div>
        <div className="d-flex flex-row barra justify-content-center">
          <div className="verde" />
          <div className="naranja" />
          <div className="rojo" />
          <div className="amarillo" />
          <div className="azul" />                    
        </div>
        <br />
        <LoadMask loading={loading} light color={"#424D88"}>
          <center><h4><strong>{t.translate("REGISTRO DE USUARIO")}</strong></h4></center>
          <RegistroForm onSubmit={this.props.registrar} loader={loading} popover={this.state.popover} togglePopover={this.togglePopover} popover2={this.state.popover2} togglePopover2={this.togglePopover2}/>
        </LoadMask>
      </div>
    );
  }
}

export default Registro;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import EdicionForm from "./EdicionEntradaForm";
import DetalleEntrada from "../Detalle/DetalleEntrada";
import LoadMask from "../../../../../Utils/LoadMask";


class EdicionEntrada extends Component {
  static propTypes = {
  };

  state = {
    hide_badge: false
  }

  componentWillMount() {
    this.props.getEntrada(this.props.match.params.id, this.props.match.params.codigo);
    /*
      PARA VOLVER A HABILITAR LA VISUALIZACION DEL GAFETE AL USUARIO UNICAMENTE 
      SE DEBE COMENTAR O REMOVER LA LINEA DEBAJO DE ESTE COMENTARIO.
    */
    this.setState({ hide_badge: !(this.props.location.pathname.includes("administrar_entradas")) })
  }

  editarEntrada = (data, impresion=false) => {
    this.props.editarEntrada(this.props.match.params.id, data, impresion, window.location.href);
  };

  render() {
    const { entrada, loading_entradas: loader } = this.props;
    return (
      <LoadMask loading={loader} light>
        <div id="perfil-entrada" className="p-2 p-md-5">
          <h3><strong>{t.translate("Edición de entrada")}</strong></h3>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <EdicionForm onSubmit={this.editarEntrada} loader={loader} hide_badge={this.state.hide_badge} />
            {this.state.hide_badge !== true ?
              <DetalleEntrada getEntrada={() => {}} entrada={entrada} edicion={true}/> :
              null
            }
          </div>
        </div>
      </LoadMask>
    );
  }
}

export default EdicionEntrada;

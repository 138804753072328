import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import EdicionForm from "./CrearEntradaForm";
import LoadMask from "Utils/LoadMask";
import DetalleEntrada from "../../../Tienda/Perfil/Compras/Entradas/Detalle/DetalleEntrada";


class EdicionEntrada extends Component {
  static propTypes = {
  };

  componentWillMount() {
    // this.props.getEntrada(this.props.match.params.id);
  }

  crearEntrada = (data, impresion=false) => {
    if (!!data.usuario) {
      data.usuario = data.usuario.id;
    }
    this.props.crear(data, impresion, window.location.href);
  };

  render() {
    const { entrada, loading: loader } = this.props;
    return (
      <LoadMask loading={loader!==0} light>
        <div id="perfil-entrada" className="p-2 p-md-5">
          <h3><strong>{t.translate("Edición de entrada")}</strong></h3>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <EdicionForm onSubmit={this.crearEntrada} loader={loader!==0} />
            <DetalleEntrada getEntrada={() => {}} entrada={entrada} edicion={true}/>
          </div>
        </div>
      </LoadMask>
    );
  }
}

export default EdicionEntrada;

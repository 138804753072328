import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Genericos from "./Genericos";
import Carrito from "../Inicio/Carrito";

class Inicio extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };


  render() {
    const { data } = this.props;

    return (
      <div className="d-flex flex-column align-items-center justify-content-between justify-content-sm-start">
        <Genericos />
        <Carrito />
      </div>
    );
  }
}

export default Inicio;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import "./detalle.css";
import { RenderCurrency, RenderNumber } from "Utils/renderField/renderReadField";
import { Link , Redirect} from "react-router-dom";
import {APOYO, ESTUDIANTE, FULL_ACCESS, UN_DIA} from 'variables';
// import { translator as t } from "../../../../../utility/traducer";

const placeholder = require('assets/img/img_placeholder.png');
const pago_oportunidad = {background: "linear-gradient(to right, #BC871A,#FFF1A3,#C18F22)"};
const foto_oportunidad = require("assets/img/img_oportunidad.png")

class Carito extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,    
  };

  constructor(props) {
    super(props);    
  }

  componentWillMount() {
    this.props.getProductos();
    this.props.getTotal();
    
  } 
  

  render() {
    const { idioma,oportunidad } = this.props;
    if(!oportunidad.total)
    {
      return(        
        <Redirect to='/tienda/inicio' />
      )
    }
    
    
    return (      
      <div id="detalle-oportunidad" className="d-flex flex-column m-3 w-100">                  
          <div className="d-flex flex-column flex-lg-row align-items-center align-self-stretch m-2">                                                               
              <div style={pago_oportunidad} className="detalle-imagen d-flex flex-column m-2">
                <img className="m-3" src={foto_oportunidad} onError={(e)=>{e.target.onerror = null; e.target.src=logo}} alt=""/>
              </div>
              <div className="d-flex flex-column flex-md-row contenido w-100 justify-content-around">
                <div className="p-2 m-2 d-flex align-items-center align-self-center titulo-producto">
                    <p className="align-self-center m-0"><strong>{oportunidad.texto}</strong></p>                    
                </div>                                     
                <div className="p-2 align-self-center">
                  <RenderCurrency value={oportunidad.total} prefix={oportunidad.moneda === "GTQ" ? "Q": "$"} /> 
                </div>                                           
              </div>
          </div>
          <div className="d-flex flex-row bd-highlight mt-3 justify-content-around">
              <div className="p-2">
                <Link to="/tienda/inicio" className="bt-regresar"><img style={{maxWidth: "25px"}} src={require('assets/img/regresar_azul.png')} /> {t.translate("Regresar")}</Link>
              </div>
              <div className="p-2 precio align-self-end">
                <span>
                {t.translate("TOTAL")}<RenderCurrency className="ml-3" value={oportunidad.total } prefix={oportunidad.moneda === "GTQ" ? "Q": "$"} />                  
                </span>
              </div>          
          </div>
      </div>
    );
  }
}

export default Carito;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderCurrency, RenderDate, RenderDateTime } from "Utils/renderField/renderReadField";
import { Link } from "react-router-dom";
import './detalle-trans.css';

class DetalleTransaccion extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
      };
    
    constructor(props) {
        super(props);
    
    }  
    componentWillMount() {
        try{
            this.props.getDetalle(this.props.match.params.id);
        }catch(e){}
    }
    
    render(){
        const {detalle, loading} = this.props
        const options = {
            hideSizePerPage: true,
            noDataText: 'No hay registros',          
            };
            onPageChange: this.props.getDetalle,
            options.noDataText = loading ? "Cargando..." : options.noDataText;
        return(
            <div id="detalle-transferencia-admin">
                <div className="p-2 encabezado-azul">
                    <h2 className="d-none d-sm-inline-block m-0">Transacción {detalle.transferencia ? detalle.transferencia:detalle.id}</h2>
                    <h4 className="d-inline-block d-sm-none m-0">Transacción {detalle.transferencia ? detalle.transferencia:detalle.id}</h4>                    
                </div>
                <div className="p-2 p-sm-4">
                    <div className="d-flex flex-column flex-wrap">
                        <div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap">
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100">
                                <div className="p-2"><h5>Fecha de compra</h5></div>
                                <div className="p-2"><RenderDateTime value={detalle.creado} /></div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100">
                                <div className="p-2"><h5>Oportunidad</h5></div>
                                <div className="p-2">{detalle.es_oportunidad ? "Si":"No"}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100">
                                <div className="p-2"><h5>Moneda</h5></div>
                                <div className="p-2">{detalle.moneda}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100">
                                <div className="p-2"><h5>Total</h5></div>
                                <div className="p-2"><RenderCurrency value={detalle.total} prefix={detalle.moneda === "GTQ" ? "Q": "$"} /></div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mt-5">
                            <div className="p-2"><h3>Cliente</h3></div>
                            <div className="p-sm-2 w-100"><hr /></div>
                        </div> 
                        <div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap mb-3">
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>Nombre</h5></div>
                                <div className="p-2">{detalle.usuario.nombre}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>Apellido</h5></div>
                                <div className="p-2">{detalle.usuario.apellido}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>Nit</h5></div>
                                <div className="p-2">{detalle.usuario.nit}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>Correo</h5></div>
                                <div className="p-2">{detalle.usuario.correo}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap mb-3">
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>País</h5></div>
                                <div className="p-2">{detalle.usuario.pais ? detalle.usuario.pais : "---"}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>Ciudad</h5></div>
                                <div className="p-2">{detalle.usuario.ciudad ? detalle.usuario.ciudad : "---"}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>Direccion</h5></div>
                                <div className="p-2">{detalle.usuario.direccion ? detalle.usuario.direccion : "---"}</div>
                            </div>
                            <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100" >
                                <div className="p-2"><h5>Empresa</h5></div>
                                <div className="p-2">{detalle.usuario.empresa ? detalle.usuario.empresa : "---"}</div>
                            </div>
                        </div>
                        {!detalle.es_oportunidad && (
                        <div>
                            <div className="d-flex flex-column flex-sm-row">
                                <div className="p-2"><h3>Detalle</h3></div>
                                <div className="p-sm-2 w-100"><hr /></div>
                            </div> 
                            <BootstrapTable
                            data={loading ? [] : detalle.detalle_compra}
                            remote
                            hover
                            options={options}
                            pagination
                            className="mt-3"                        
                            >
                                <TableHeaderColumn
                                        dataField="texto_comercial"
                                        isKey
                                        dataSort
                                        dataFormat={(cell) => {
                                            return cell
                                        return "--------"
                                        }}
                                    >
                                        Producto
                                </TableHeaderColumn>                            
                                <TableHeaderColumn
                                        dataAlign="right"
                                        dataField="precio"
                                        dataSort
                                        dataFormat={(cell) => {
                                        if (cell)
                                            return <RenderCurrency value={cell} prefix={detalle.moneda === "GTQ" ? "Q": "$"} />;
                                        return "0.00"
                                        }}
                                    >
                                        Precio Unitario
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                        dataAlign="center"
                                        dataField="cantidad"
                                        dataSort
                                        dataFormat={(cell) => {
                                        if (cell)
                                            return cell
                                        return "0.00"
                                        }}
                                    >
                                        Cantidad
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                        dataAlign="right"
                                        dataField="precio"
                                        dataSort
                                        dataFormat={(cell,row) => {
                                        if (cell){
                                            const total = cell * row.cantidad;
                                            return <RenderCurrency value={total} prefix={detalle.moneda === "GTQ" ? "Q": "$"} />;
                                        }
                                        return "0.00"
                                        }}
                                    >
                                        Total
                                </TableHeaderColumn>
                            </BootstrapTable>
                            <div className="d-flex contenido flex-row align-self-start justify-content-end form-group mr-2">
                                <div className="p-2"><h5>Total</h5></div>
                                <div className="p-2"><RenderCurrency value={detalle.total} prefix={detalle.moneda === "GTQ" ? "Q": "$"} /></div>                                
                            </div>
                        </div>)}
                        <div className="d-flex flex-colum justify-content-center">
                            <Link to="/administrar_transacciones" className="btn btn-default m-2">Regresar</Link>
                            <button className="btn btn-secondary m-2" onClick={() => this.props.descargarRecibo(detalle.id)}>
                                <i className="fa fa-download"/> Descargar recibo
                            </button>
                            <br />                       
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetalleTransaccion;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import "./carrito.css";


class Oportunidades extends Component {
  static propTypes = {
    en_carrito: PropTypes.number.isRequired,
  };

  componentWillMount() {
  }

  render() {
    const { en_carrito, animar } = this.props;

    return (
      <div id="carrito-flotante">
        <Link to={"/tienda/carrito"} className={animar ? "changing-animation on" : "changing-animation"}>
          <i className="fa fa-shopping-cart fa-2x" />
          <span>{en_carrito}</span>
        </Link>
      </div>
    );
  }
}

export default Oportunidades;

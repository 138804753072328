import React, { Component } from 'react';
import { default as DetalleCarito } from './Detalle';
import { default as Carrito } from './Carrito';

class PagoOportunidad extends Component {
  render() {    
    return (      
      <div className="d-flex flex-column mt-3 mb-3 flex-md-row bd-highlight justify-content-center">
          <DetalleCarito />        
          <Carrito />                
      </div>      
    );
  }
}

export default PagoOportunidad;
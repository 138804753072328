import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import {TIPOS_ENTRADA} from "variables";


class ListadoEntradas extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {stand:null};
    this.editar = this.editar.bind(this);
  }

  editar(stand) {
    this.props.editar(stand);
    this.setState({stand: stand.id});
  }

  componentWillMount() {
    this.props.listar();
  }

  borrar = (id) => {
    swal({
      title: 'Borrar',
      text: '¿Está seguro de borrar la entrada?',
      type: 'warning',
      confirmButtonText: 'Sí, Borrar',
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        this.props.eliminar(id);
      }
    })
  };


  render() {
    const { loading, data, search, filtro } = this.props;
    const options = {
      hideSizePerPage: true,
      noDataText: 'No hay registros',
      prePage: 'Anterior',
      nextPage: 'Siguiente',
      firstPage: 'Primero',
      lastPage: 'Último',
    };
    options.page = this.props.page;
    options.onPageChange = this.props.listar;
    options.noDataText = loading ? "Cargando..." : options.noDataText;
    return (
      <div id="productos">
        <div className="">
          <div className="pt-5 pb-5 pl-1 pr-1 p-sm-5">
            <Link to="/administrar_entradas/crear" className="btn btn-agregar m-1 mb-3">Agregar</Link><br />
            <div className="fondo">
              <div className="buscador d-flex flex-column justify-content-between p-3 encabezado-azul">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex align-items-center mr-2">
                    <h2 className="d-none d-sm-inline-block m-0">Entradas</h2>
                    <h4 className="d-inline-block d-sm-none m-0">Entradas</h4>
                  </div>
                  <div className="d-flex flex-column flex-sm-row">
                    <div className="toolbar-search">
                      <input className="form-control" type="text" placeholder="Buscar"
                             ref={node => {
                               this.buscar = node;
                               if (this.buscar) {
                                 this.buscar.value = search;
                               }
                             }}
                             onChange={() => {
                               if (this.buscar) {
                                 this.props.searchChange(this.buscar.value);
                               }
                             }}
                             autoComplete="off"
                      />
                      <span className="fa fa-search icon-search" />
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex m-0">
                  <div className="radio c-radio c-radio-nofont d-flex ml-3 ml-md-5">
                    <label className="negro font-weight-normal">
                      <input type="radio" name="filtro"
                             ref={node => {
                               this.editados = node;
                             }}
                             onChange={() => {
                               this.props.setFiltro()
                             }}
                             checked={filtro === undefined}
                             value="todos"/>
                      <span />Todos
                    </label>
                  </div>
                  <div className="radio c-radio c-radio-nofont d-flex ml-10">
                    <label className="negro font-weight-normal">
                      <input type="radio" name="filtro"
                             ref={node => {
                               this.editados = node;
                             }}
                             onChange={() => {
                               this.props.setFiltro(false)
                             }}
                             checked={filtro === false}
                             value="no_editados"/>
                      <span />No editados
                    </label>
                  </div>
                  <div className="radio c-radio c-radio-nofont d-flex ml-10">
                    <label className="negro font-weight-normal">
                      <input type="radio" name="filtro"
                             ref={node => {
                               this.editados = node;
                             }}
                             onChange={() => {
                               this.props.setFiltro(true)
                             }}
                             checked={filtro === true}
                             value="no_editados"/>
                      <span />Editados
                    </label>
                  </div>
                </div>
              </div>
              <BootstrapTable
                data={loading ? [] : data.results}
                remote
                hover
                fetchInfo={{ dataTotalSize: data.count }}
                options={options}
                pagination
              >
                <TableHeaderColumn
                  dataField="nombre"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return <span style={{color: "#C60B1E"}}>Info. sin completar</span>
                  }}
                >
                  Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="empresa"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return <span style={{color: "#C60B1E"}}>Info. sin completar</span>
                  }}
                >
                  Empresa
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="correo"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return <span style={{color: "#C60B1E"}}>Info. sin completar</span>
                  }}
                >
                  Correo
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="usuario"
                  dataSort
                  dataFormat={(cell) => {
                    if (cell)
                      return cell.nombre;
                    return "No enlazado al sistema"
                  }}
                >
                  Usuario de sistema
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="tipo_entrada"
                  dataFormat={(cell) => {
                    const entrada = _.find(TIPOS_ENTRADA, {id: cell});
                    return entrada.label;
                  }}
                >
                  Tipo de entrada
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="stand"
                  dataSort
                  dataFormat={(cell) => {
                    if (!!cell)
                      return "Sí";
                    return "No"
                  }}
                >
                  Enlazado a un Stand
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataAlign="center"
                  isKey
                  dataFormat ={(cell, row) =>{
                    return <div>
                      <Link to={`/administrar_entradas/${cell}`}>
                        <img className="action-img" title="Editar" alt="Editar" src={require("assets/img/editar.png")}/>
                      </Link>
                      {!row.usuario && (
                        <img className="action-img" title="Borrar" alt="Borrar" src={require("assets/img/borrar.png")}
                          onClick={()=>
                            this.borrar(cell)
                          }
                        />
                      )}
                    </div>
                  }}
                >
                  Acciones
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListadoEntradas;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./detalle.css";
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";
import swal from 'sweetalert2';
import { translator as t } from "traducer";
import { Link , Redirect} from "react-router-dom";
import NumberFormat from 'react-number-format';
import validarNIT from 'validar-nit-gt';
import {
  APOYO, 
  ESTUDIANTE, 
  FULL_ACCESS, 
  ENTRADA_VIRTUAL_CONGRESO_AGUACATE,
  FULL_ACCESS_CONGRESO_AGUACATE,
  APOYO_CONGRESO_AGUACATE,
  UN_DIA,
  MARBETES_PARQUEO,
  PISO_EXHIBICION,
  PISO_EXHIBICION_SABADO,
  CONFERENCIA,
  STAFF,
  PRENSA,
  INVITADO_ESPECIAL,
  COMITE_ORGANIZADOR,
  COMPRADOR,
  PRODUCTOR,
  full_access,
  full_access_congreso_aguacate,
  un_dia,
  estudiante,
  apoyo,
  apoyo_congreso_aguacate,
  marbetes_parqueo,  
  piso_exhibicion,
  piso_exhibicion_sabado,
  conferencia,
  staff,
  prensa,
  invitado_especial,  
  comite_organizador,
  comprador,
  productor,
  foto_full_access_c,
  foto_full_access_congreso_aguacate_c,
  foto_un_dia_c,
  foto_estudiante_c,
  foto_apoyo_c,
  foto_apoyo_congreso_aguacate_c,
  foto_marbetes_parqueo_c,
  foto_piso_exhibicion_c,
  foto_piso_exhibicion_sabado_c,
  foto_conferencia_c,
  foto_staff_c,
  foto_prensa_c,
  foto_invitado_especial_c,
  foto_comite_organizador_c,
  foto_comprador_c,
  foto_productor_c,
} from 'variables';
// import { translator as t } from "../../../../../utility/traducer";

const placeholder = require('assets/img/img_placeholder.png');

class Carito extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.borrar = this.borrar.bind(this);
    this.Cantidad = this.Cantidad.bind(this);
    this.validar =this.borrar.bind(this);
  }

  componentWillMount() {    
    this.props.getTotal();    
  }

  borrar(producto){    
    let prod;
    if(this.idioma==="es"){
      prod=producto.texto_comercial
    }else{
      prod=producto.texto_comercial_ingles
    }
    swal({
      title: t.translate("Eliminar"),
      text: t.translate('Esta seguro que quiere eliminar el producto ')+prod+t.translate(' del carrito'),
      type: 'warning',
      confirmButtonText: t.translate('Eliminar'),
      cancelButtonText: t.translate('Cancelar'),
      showConfirmButton: true,
      showCancelButton: true
    }).then((result) => {
      if (result.value) {        
        this.props.borrarCarrito(producto);        
        swal(
          t.translate('Eliminado'),
          t.translate('El producto ha sido eliminado del carrito'),
          'success'
        )
      }
    })        
  }

  Cantidad(){

  }

  render() {
    const { carrito,idioma,total, moneda, en_carrito, usuario } = this.props;
    if(en_carrito<1)
    {
      return(
        
        <Redirect to='/tienda/inicio' />
      )
    }
    let total_dolares = 0;
    let total_quetzales = 0;
    if(usuario.es_socio){
      total_dolares=total.total_dolares_socios;
      total_quetzales=total.total_quetzales_socios;
    }
    else
    {
      total_dolares=total.total_dolares;      
      total_quetzales=total.total_quetzales;     
    }

    if (usuario.perfil==undefined){
    }else{
      const nit = validarNIT(usuario.perfil.nit)
      if (usuario.perfil.nit=="CF"){
      }else{
        if (nit==false){
          swal({
            title: t.translate("Nit Invalido"),
            text: t.translate('El nit registrado es invalido, modificar antes de realizar compra'),
            type: 'warning',
            confirmButtonText: '<a style="color:white;" href="#/tienda/perfil">Ir al Perfil</a>',
            showConfirmButton: true,
          })
        }
      }
    }
    
    
    return (
      
      <div id="detalle-pagos" className="d-flex flex-column bd-highlight mt-3 mb-3 mr-3 justify-content-start">
        <h5 className={"m-2 "}><strong>{t.translate("CARRITO DE COMPRA")}</strong></h5>
          {
             carrito.map((carrito)=>{
              let quetzales = 0;
              let dolares = 0;
              let quetzales_socio = 0;
              let dolares_socio = 0;
              carrito.precios.forEach((item2) => {
                if(item2.lista_precios==="No Socio" && item2.codigo_moneda==="GTQ"){
                  quetzales = parseFloat(item2.importe);          
                }
                if(item2.lista_precios==="No Socio" && item2.codigo_moneda==="USD"){
                  dolares = parseFloat(item2.importe);          
                }
                if(item2.lista_precios==="Socio" && item2.codigo_moneda==="GTQ"){
                  quetzales_socio = parseFloat(item2.importe);          
                }
                if(item2.lista_precios==="Socio" && item2.codigo_moneda==="USD"){
                  dolares_socio = parseFloat(item2.importe);          
                }               
              });
              if(usuario.es_socio)
              {
                quetzales = quetzales_socio *carrito.cantidad;                
                dolares = dolares_socio * carrito.cantidad;
              }else
              {
                quetzales = quetzales * carrito.cantidad;
                dolares = dolares * carrito.cantidad;                
              }
              let style;
              let foto;
              switch (carrito.tipo_entrada) {
                case ENTRADA_VIRTUAL_CONGRESO_AGUACATE:
                  style = full_access_congreso_aguacate;
                  foto = foto_full_access_congreso_aguacate_c;
                  break;
                case FULL_ACCESS_CONGRESO_AGUACATE:
                  style = full_access_congreso_aguacate;
                  foto = foto_full_access_congreso_aguacate_c;
                  break;
                case APOYO_CONGRESO_AGUACATE:
                  style = apoyo_congreso_aguacate;
                  foto = foto_apoyo_congreso_aguacate_c;
                  break;
                case FULL_ACCESS:
                  style = full_access;
                  foto = foto_full_access_c;
                  break;
                case UN_DIA:
                  style = un_dia;
                  foto = foto_un_dia_c;
                  break;
                case ESTUDIANTE:
                  style = estudiante;
                  foto = foto_estudiante_c;
                  break;
                case APOYO:
                  style = apoyo;
                  foto = foto_apoyo_c;
                  break;
                case MARBETES_PARQUEO:
                  style = marbetes_parqueo;
                  foto = foto_marbetes_parqueo_c;
                  break;
                case PISO_EXHIBICION:
                  style = piso_exhibicion;
                  foto = foto_piso_exhibicion_c;
                  break;
                case PISO_EXHIBICION_SABADO:
                  style = piso_exhibicion_sabado;
                  foto = foto_piso_exhibicion_sabado_c;
                  break;
                case CONFERENCIA:
                  style = conferencia;
                  foto = foto_conferencia_c;
                  break;
                case STAFF:
                  style = staff;
                  foto = foto_staff_c;
                  break;
                case PRENSA:
                  style = prensa;
                  foto = foto_prensa_c;
                  break;
                case INVITADO_ESPECIAL:
                  style = invitado_especial;
                  foto = foto_invitado_especial_c;
                  break;
                case COMITE_ORGANIZADOR:
                  style = comite_organizador;
                  foto = foto_comite_organizador_c;
                  break;
                case COMPRADOR:
                  style = comprador;
                  foto = foto_comprador_c;
                  break;
                case PRODUCTOR:
                  style = productor;
                  foto = foto_productor_c;
                  break;                
              }                           
              return(
                <div key={carrito.id} className="d-flex flex-column flex-lg-row align-items-center align-self-stretch m-2">
                    {
                      carrito.es_entrada? 
                      <div style={style} className="detalle-imagen d-flex flex-column m-2">
                        <img className="ml-3 mr-3 mt-3" src={foto} onError={(e)=>{e.target.onerror = null; e.target.src=logo}} alt=""/>
                      </div>:
                      <div className="detalle-imagen-genericos d-flex flex-column m-2">
                        <img className="p-2 m-auto" src={carrito.foto} onError={(e)=>{e.target.onerror = null; e.target.src=logo}} alt=""/>
                      </div>                                              
                    }
                    <div className="d-flex flex-column flex-md-row contenido w-100">
                      <div className="p-2 m-2 d-flex align-items-center align-self-stretch titulo-producto flex-2">
                          <h5 className="align-self-center m-0"><strong>{idioma === "es" ? carrito.texto_comercial: carrito.texto_comercial_ingles}</strong></h5>
                      </div>
                      <div className="p-2 align-items-stretch align-self-stretch flex-2 descripcion" >
                        <small>{idioma === "es" ? carrito.descripcion: carrito.descripcion_ingles}</small>
                      </div>
                      <div className="pt-2 pb-2 pl-5 pr-5 pr-md-2 pl-md-2 align-self-md-center flex-1">
                        <NumberFormat
                          className={'form-control'}
                          decimalScale={0}
                          fixedDecimalScale
                          value={carrito.cantidad}
                          thousandSeparator
                          prefix=""
                          size="1"
                          onValueChange={(value) => {
                            if(value.floatValue)
                              this.props.cambiarCantidad(carrito,value.floatValue);                         
                          }}                         
                        />   
                      </div>                                      
                      <div className="p-2 align-self-center flex-1">
                        <RenderCurrency className="font-weight-bold" value={moneda.codigo === "GTQ" ? quetzales : dolares} prefix={moneda.codigo === "GTQ" ? "Q": "$"} />                         
                      </div>
                      <div className="p-2 align-self-center action-eliminar flex-1">
                        <a onClick={() => {
                              this.borrar(carrito)
                          }}>
                          <i className="fa fa-times"/>&nbsp;{t.translate("Eliminar")}
                        </a>
                      </div>                      
                    </div>
                </div>
              )
            })
          }            
          <div className="d-flex flex-row bd-highlight mt-3 justify-content-around">
              <div className="p-2">
                <Link to="/tienda/inicio" className="bt-regresar"><img style={{maxWidth: "25px"}} src={require('assets/img/regresar_azul.png')} />{t.translate("Regresar")}</Link>
              </div>
              <div className="p-2 precio align-self-end">
                <span>
                  {t.translate("TOTAL")}<RenderCurrency className="ml-3" value={moneda.codigo === "GTQ" ? total_quetzales: total_dolares} prefix={moneda.codigo === "GTQ" ? "Q": "$"} />                  
                </span>
              </div>          
          </div>
      </div>
    );
  }
}



export default Carito;

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderField } from '../../Utils/renderField/index'
import Loader from 'Utils/Loader';


const LoginForm = props => {
  const { handleSubmit, loader, reset, submitting } = props
  return (
    <form autoComplete="new-password" name="loginForm" className="d-flex flex-column form-validate mb-lg" onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label htmlFor="username">Nombre de usuario *</label>
        <Field name="username" component={renderField} type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="password">Contraseña *</label>
        <Field name="password" component={renderField} type="password" className="form-control" />
      </div>
      <div className="clearfix" />
      <Loader active={loader} />
      {!loader && (<button type="submit" className="btn btn-default m-1 align-self-center">Iniciar Sesión</button>)}
    </form>
  )
};

export default reduxForm({
  form: 'login' // a unique identifier for this form
})(LoginForm)

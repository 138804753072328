import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import _ from "lodash";
import { PrintContainer, actions as printActions } from "Utils/Print";
import {Link} from "react-router-dom";
import {RenderCurrency} from "Utils/renderField/renderReadField";
import * as Letras from "Utils/NumeroALetras";
import "./recibo.css";

const agexport = require("assets/img/logo_agexport_color.png");
class DetalleRecibo extends Component{
    
    componentWillMount() {
        try {
          this.props.getCompra(this.props.match.params.id);
        } catch (e) {}
    }

    render(){
        const { compra, usuario} = this.props
        return(
            <div id="detalle-recibo" className="d-flex flex-column m-3 justify-content-center">
                <h3><strong>{t.translate('Vista Previa')}</strong></h3>
                <PrintContainer name="reciboVenta">
                    <div className="d-flex flex-row contendio-recibo">
                        <div className="d-flex flex-column w-100">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column ml-3">
                                    <img src={agexport} alt="agexport" />
                                </div>
                                <div className="d-flex flex-column align-items-center align-self-end mb-1 flex-1">
                                    <div className="azul"><strong>ASOCIACION GUATEMALTECA DE EXPORTADORES</strong></div>
                                    <div className="azul"><strong>AGEXPORT</strong></div>
                                    <div><small>15 Avenida 14-72, Zona 13, Guatemala, Guatemala</small></div>
                                    <div><small>PBX: 2422-3400 FAX: 2422-3434</small></div>
                                    <div><small><strong>NIT.: 516348-K</strong></small></div>
                                </div>
                            </div>                            
                            <div className="d-flex flex-row ml-3 mr-1">
                                <div className="d-flex flex-column p-1 flex-2 borde-azul">
                                    <div><strong>GUATEMALA</strong></div>
                                    <div><strong>SEÑORES:</strong> {usuario.first_name} {usuario.last_name}</div>
                                    <div className="d-flex flex-row">
                                        <div className="flex-1"><strong>NIT:</strong> {usuario.perfil.nit} </div>
                                        <div className="flex-1"><strong>COD:</strong> </div>
                                    </div>
                                    <div><strong>DIRECCIÓN:</strong> {usuario.perfil.direccion} {usuario.perfil.direccion.toLowerCase()==="ciudad" ? "" : ", "+usuario.perfil.ciudad}</div>
                                </div>
                                <div className="d-flex flex-column p-1 flex-1 borde-azul">
                                    <div className="d-flex flex-column align-items-center"><strong>RECIBO DE COBRO</strong></div>
                                    <div className="d-flex flex-column align-items-center"><strong>SERIE</strong></div>
                                    <div className="d-flex flex-column align-items-center rojo"><strong>RECE-01-A1CR</strong></div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-0"><strong>No.  </strong></div>
                                        <div className="rojo">00102</div>
                                    </div>
                                </div>                                                
                            </div>
                            <div className="d-flex flex-row ml-3 mr-1">
                                <div className="d-flex flex-column p-1 align-items-center flex-2 fondo-azul"><strong>CONCEPTO</strong></div>
                                <div className="d-flex flex-column p-1 align-items-center flex-1 fondo-azul"><strong>VALOR {compra.moneda}</strong></div>
                            </div>                            
                            { compra.es_oportunidad ? 
                                <div className="d-flex flex-row ml-3 mr-1">
                                    <div className="d-flex flex-column p-1 flex-2 borde-azul-superior">
                                        {compra.texto_oportunidad}
                                    </div>
                                    <div className="d-flex flex-column p-1 align-items-end flex-1 borde-azul-superior">
                                        <div className="d-flex flex-column"><RenderCurrency value={compra.total} prefix={compra.moneda==="USD" ? "$":"Q"} /></div>
                                    </div>
                                </div>
                                :<div className="d-flex flex-column">                                                                        
                                    {
                                        compra.detalle_compra.map((detalle)=>{
                                            return(
                                                <div key={detalle.id} className="d-flex flex-row ml-3 mr-1 detalles">
                                                    <div className="d-flex flex-column p-1 flex-2 borde-azul-lados w-100">
                                                        <div className="d-flex flex-row mt-1">
                                                            <div className="ml-1 mr-1">{detalle.cantidad}</div>
                                                            <div className="ml-1 mr-1">{detalle.texto_comercial}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-end p-1 flex-1 borde-azul-lados">
                                                        <div className="ml-1 mr-1 mt-1"><RenderCurrency value={detalle.precio*detalle.cantidad} prefix={compra.moneda==="USD" ? "$":"Q"} /></div> 
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }                                    
                                </div>
                            }                            
                            <div className="d-flex flex-row ml-3 mr-1">
                                <div className="d-flex flex-column p-1 flex-2 borde-azul-inferior">
                                    <div><br /><small><strong>VALOR EN LETRAS</strong></small></div>
                                    <div><small><strong>{Letras.numeroALetras(compra.total,{plural: compra.moneda ==="USD" ? "DOLARES":"QUETZALES",singular: compra.moneda ==="USD" ? "DOLAR":"QUETZAL",centPlural: 'CENTAVOS',centSingular: 'CENTAVO'})}</strong></small></div>
                                    <div><br /><small>EXENTO DEL IVA SEGÚN ART. 7 INCISO 13 DECRETO 27-92</small></div>
                                    <div><small>EXENTO DE ISR SEGÚN ARTICULO 11 LIBRO 1 TITULO II CAPITULO II DEL DECRETO 10-2012</small></div>
                                    <div className="d-flex flex-row justify-content-end">                                        
                                        <div> TOTAL {compra.moneda}</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-end justify-content-end p-1 flex-1 borde-azul-inferior">
                                    <div className="d-flex flex-column"><RenderCurrency value={compra.total} prefix={compra.moneda==="USD" ? "$":"Q"} /></div>
                                </div>
                            </div>                            
                        </div>
                        <div className="vertical">
                            <div>PERSONA JURIDICA EXENTA</div>
                            <div>-AL ISR ART. 11 LIBRO 1 TIT. 2 CAP. 2 DEL DECRETO 10-2012 </div>
                            <div>-AL IVA DECRETO 27-92. CAPITULO IV ARTICULO 7 INCISO 13</div>                            
                        </div>
                    </div>
                </PrintContainer>
                <div className="mt-3 text-center">
                    <Link to={`/tienda/entrada/historial/detalle/${this.props.match.params.id}`} className={"btn btn-secondary m-1"} ><strong>Cancelar</strong></Link>
                    <button
                    onClick={(e) => {
                        e.preventDefault();
                        printActions.print('reciboVenta')
                        // window.print();
                    }}
                    className="btn btn-default "
                    type={'button'}
                    >
                    <em className="fa fa-print"/> Imprimir
                    </button>
                </div>
            </div>            
        )
    }
}
export default DetalleRecibo;

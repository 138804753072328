import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import Modal from "react-responsive-modal";
import FormComprar from "../../../Caja/Carrito/form";
import CodigoOportunidad from "./CodigoOportunidad";
import "./oportunidades.css";
import {logo_blanco_en, logo_blanco_es} from "../../../../../../utility/variables";
import {pagarOportunidad} from "../../../../../../redux/modules/tienda/tienda";


class Oportunidades extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.buscarOportunidad = this.buscarOportunidad.bind(this);
  }

  buscarOportunidad(data) {
    if (!!this.props.usuario.perfil) {
      this.props.buscarOportunidad(data);
    } else {
      this.props.setAccionModal('Oportunidad');
      this.props.setLoginModal(true);
    }
  }

  pagoOportunidad = (data) => {
    this.props.pagarOportunidad(data);
    this.props.setModal();
  };

  render() {
    const { idioma, loading, openModal } = this.props;

    return (
      <div id="oportunidades" className="w-100 pt-4 pb-4 pl-2 pr-2 p-sm-5">
        <div className="d-flex flex-row justify-content-center">
          <div className="d-none d-sm-flex align-items-center justify-content-end pr-3 " style={{borderRight: "2px solid white"}}>
            <div className="oportunidad-icon">
              <img src={require("assets/img/oportunidad_icon.png")} alt=""/>
            </div>
          </div>
          <div className="d-flex flex-column pl-0 pl-sm-3 align-items-center align-items-md-start">
            <h3><strong>{t.translate("OPORTUNIDADES")}</strong></h3>
            <strong>{t.translate("¿Tienes un código de oportunidad y cliente?")}</strong>
            <strong><span className="d-none d-md-inline-block">&nbsp;&nbsp;&nbsp;&nbsp;</span>{t.translate("Ingresa tus códigos para encontrarlo")}</strong>
            <CodigoOportunidad onSubmit={this.buscarOportunidad} loader={loading} />
          </div>
        </div>
        <Modal classNames={{modal:"login-modal pl-sm-5 pr-sm-5"}} showCloseIcon={false} open={openModal} onClose={() => this.props.setModal(false)}>
          <div id="pago-oportunidad">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img src={idioma === "es" ? logo_blanco_es : logo_blanco_en} alt="Agritrade"/>
            </div>
            <div className="d-flex flex-row barra justify-content-center">
              <div className="verde" />
              <div className="naranja" />
              <div className="rojo" />
              <div className="amarillo" />
              <div className="azul" />
            </div>
            <FormComprar onSubmit={this.pagoOportunidad} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Oportunidades;

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/cuenta';
import Reset from './Reset';


const ms2p = (state) => {
  const { idioma } = state.planta;
  return {
    ...state.cuenta,
    idioma
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Reset);

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/administracion/entradas';
import CrearEntradas from './CrearEntradas';


const ms2p = (state) => {
  let entrada = {tipo_entrada: {}};
  try {
    if (!!state.form.CrearEntradaForm.values)
      entrada = state.form.CrearEntradaForm.values;
    if (!state.form.CrearEntradaForm.values.tipo_entrada) {
      entrada.tipo_entrada = {};
    }
    if (!!state.cuenta.entrada.qr)
      entrada.qr = state.cuenta.entrada.qr;
  } catch (e) {

  }

  return {
    ...state.entradas,
    entrada,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEntradas);

import { handleActions } from 'redux-actions';
import { api } from "api";
import _ from "lodash";
import {initialize as initializeForm} from 'redux-form';
import {ToastStore} from "react-toasts";
import { translator as t } from "utility/traducer";
import { push } from "react-router-redux";
import swal from 'sweetalert2';

const DATA = 'TIENDA_DATA';
const MODAL = 'TIENDA_MODAL';
const SEARCH = 'TIENDA_SEARCH';
const LOADING = 'TIENDA_LOADING';
const ANIMAR = 'TIENDA_ANIMAR';
const CARRITO = 'TIENDA_CARRITO';
const TOTAL  = 'TIENDA_TOTAL';
const MONEDA = 'TIENDA_MONEDA';
const EMISORES = 'TIENDA_EMISORES';// Pendiente a confirmar si se utilizara
const OPORTUNIDAD = 'TIENDA_OPORTUNIDAD';
const ERROR = "TIENDA_ERROR_OPORTUNIDAD";
const VENTA = "TIENDA_VENTA_EXITOSA";

export const constants = {
};

// ------------------------------------
// Actions
// ------------------------------------

export const realizarPago = (data) => (dispatch, getStore) =>{
  dispatch(setLoading(true));
  const cuerpo = {
    productos: getStore().tienda.carrito,
    moneda: getStore().tienda.moneda.codigo,
    idioma: localStorage.getItem('lang'),
    tarjeta: {
      number: data.number,
      expirationMonth: data.expirationMonth.value.toString(),
      expirationYear: data.expirationYear.value.toString(),
      securityCode: data.securityCode,
      firstName: data.firstName,
      lastName: data.lastName,
    },
  };

  api.post('compras/crear_pedido',cuerpo)
    .then((data) =>{
      localStorage.removeItem('cart');
      localStorage.removeItem('sessionCart');
      dispatch(setCarrito([]));
      ToastStore.success(t.translate('Transacción realizada'));
      dispatch(setVenta(true));
      dispatch(push("/tienda/compra_exitosa"));
    })
    .catch((e) => {
      try {
        if (e.detail.includes("VALUE ERROR") && e.detail.includes("phone_number"))
          swal(
            t.translate('Error!'),
            t.translate('El número de teléfono supera los 15 caracteres, editalo y vuelve a intentar'),
            'error',
          );
        else
          swal(
            t.translate('Error!'),
            t.translate('Transacción fallida por favor verifica tus datos y vuelve a intentarlo'),
            'error',
          );
      } catch(e) {
        swal(
          t.translate('Error!'),
          t.translate('Transacción fallida por favor verifica tus datos y vuelve a intentarlo'),
          'error',
        );
      }
    })
    .finally(()=>{
      dispatch(setLoading(false));
    })
}

// Pendiente a confirmar si se utilizara
export const getEmisores = () => (dispatch) => {
  dispatch(setLoading(true));
  api.get('/emisores/emisores').then((data) => {
    dispatch(setEmisores(data));
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const setLoading = load => (dispatch, getStore) => {
  const loading = getStore().tienda.loading;
  if (load) {
    dispatch({type: LOADING, loading: loading + 1 });
  } else {
    dispatch({type: LOADING, loading: loading - 1 });
  }
};

export const buscarOportunidad = data => (dispatch, getStore) => {
  dispatch(setLoading(true));
  api.get("compras/buscar_oportunidad", {...data}).then((result) => {
    result.codigoCrm=data.codigoCrm;
    result.codigoCliente=data.codigoCliente;
    dispatch(setOportunidad(result));
    dispatch(push("/tienda/oportunidad/pagar"));
  }).catch((e) =>  {
    dispatch(initializeForm('CodigoOportunidadForm', {}));
    dispatch(setError(true));
    dispatch(push("/tienda/oportunidad/error"));
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const pagarOportunidad = data => (dispatch, getStore) => {
  dispatch(setLoading(true));
  const tarjeta = {
    number: data.number,
    expirationMonth: data.expirationMonth.value.toString(),
    expirationYear: data.expirationYear.value.toString(),
    securityCode: data.securityCode,
    firstName: data.firstName,
    lastName: data.lastName,
  };
  const codigoCrm = getStore().tienda.oportunidad.codigoCrm;
  const codigoCliente = getStore().tienda.oportunidad.codigoCliente;
  api.post("compras/pago_oportunidad", { idioma: localStorage.getItem('lang'),tarjeta},{codigoCrm,codigoCliente}).then((result) => {
    ToastStore.success(t.translate("Pago de oportunidad registrado con éxito"));
    swal(
      t.translate('Enhorabuena!'),
      t.translate('Pago de oportunidad registrado con éxito'),
      'success',
    );
    dispatch(setOportunidad([]));
    dispatch(setVenta(true));
    dispatch(push("/tienda/compra_exitosa"));
  }).catch((e) => {
    try {
        if (e.detail.includes("VALUE ERROR") && e.detail.includes("phone_number"))
          swal(
            t.translate('Error!'),
            t.translate('El número de teléfono supera los 15 caracteres, editalo y vuelve a intentar'),
            'error',
          );
        else
          swal(
            t.translate('Error!'),
            t.translate('Pago de oportunidad fallido por favor verifica tus datos y vuelve a intentarlo'),
            'error',
          );
    } catch(e) {
      swal(
        t.translate('Error!'),
        t.translate('Pago de oportunidad fallido por favor verifica tus datos y vuelve a intentarlo'),
        'error',
      );
    }

  }).finally(() => {
    dispatch(setLoading(false));
    dispatch(initializeForm('CodigoOportunidadForm', {}));
  })
};

export const agregarCarrito = producto => (dispatch, getStore) => {
  const carrito = _.cloneDeep(getStore().tienda.carrito);
  const item = _.find(carrito, {id: producto.id});
  if (!!item) {
    item.cantidad += 1;
  } else {
    producto.cantidad = 1;
    carrito.push(producto);
  }
  if (!!localStorage.getItem('token'))
    localStorage.setItem('sessionCart', JSON.stringify(carrito));
  else
    localStorage.setItem('cart', JSON.stringify(carrito));
  dispatch(setCarrito(carrito));
  dispatch(setAnimacion(true));
  setTimeout(() => {
    dispatch(setAnimacion(false));
  }, 500);
};

export const getProductos = () => (dispatch) => {
  dispatch(setLoading(true));
  api.get('/producto', {vendible: true, es_entrada: true, limit: 20}).then((data) => {
    dispatch(setData(data));
  }).catch(() => {

  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const getGenericos = () => (dispatch) => {
  dispatch(setLoading(true));
  api.get('/producto', {vendible: true, es_entrada: false, limit: 20}).then((data) => {
    dispatch(setData(data));
  }).catch(() => {

  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const getTotal = () => (dispatch, getStore) =>{
  const carrito = _.cloneDeep(getStore().tienda.carrito);
  let total_quetzales_socios =0;
  let total_quetzales = 0;
  let total_dolares_socios =0;
  let total_dolares =0;
  let cantidad = 0;
  carrito.forEach((item) => {
    var precio_quetzales_socio =0;
    var precio_quetzales = 0;
    var precio_dolares_socio = 0;
    var precio_dolares = 0;
    item.precios.forEach((item2)=>{
      if(item2.lista_precios==="No Socio" && item2.codigo_moneda==="GTQ"){
        precio_quetzales = parseFloat(item2.importe);
      }
      if(item2.lista_precios==="No Socio" && item2.codigo_moneda==="USD"){
        precio_dolares = parseFloat(item2.importe);
      }
      if(item2.lista_precios==="Socio" && item2.codigo_moneda==="GTQ"){
        precio_quetzales_socio = parseFloat(item2.importe);
      }
      if(item2.lista_precios==="Socio" && item2.codigo_moneda==="USD"){
        precio_dolares_socio = parseFloat(item2.importe);
      }
    })
    cantidad = item.cantidad;
    total_quetzales += precio_quetzales * cantidad;
    total_dolares += precio_dolares * cantidad;
    total_quetzales_socios += precio_quetzales_socio * cantidad;
    total_dolares_socios += precio_dolares_socio * cantidad;
  })
  const total = {
    total_quetzales : total_quetzales,
    total_dolares : total_dolares,
    total_quetzales_socios : total_quetzales_socios,
    total_dolares_socios : total_dolares_socios
  }
  dispatch(setTotal(total));
}
export const borrarCarrito = producto => (dispatch, getStore) => {
  const carrito = _.cloneDeep(getStore().tienda.carrito);
  const temp = _.find(carrito, {id: producto.id});
  const index = carrito.indexOf(temp);
  carrito.splice(index,1);
  dispatch(setCarrito(carrito));
  dispatch(getTotal());
  if(JSON.stringify(carrito)=='[]'){
    localStorage.removeItem('cart');
    localStorage.removeItem('sessionCart');
  }else{
    if (!!localStorage.getItem('token'))
      localStorage.setItem('sessionCart', JSON.stringify(carrito));
    else
      localStorage.setItem('cart', JSON.stringify(carrito));
  dispatch(setCarrito(carrito));
  }
};

export const setMoneda = (codigo) => (dispatch) => {
  if (codigo === "GTQ") {
    dispatch(_setMoneda({
      codigo,
    }));
  } else if (codigo === "USD") {
    dispatch(_setMoneda({
      codigo,
    }));
  }
};

export const cambiarCantidad = (producto,cantidad) => (dispatch, getStore) => {
  const carrito = _.cloneDeep(getStore().tienda.carrito);
  const item = _.find(carrito, {id: producto.id});
  item.cantidad = cantidad;
  if (!!localStorage.getItem('token'))
    localStorage.setItem('sessionCart', JSON.stringify(carrito));
  else
    localStorage.setItem('cart', JSON.stringify(carrito));
  dispatch(setCarrito(carrito));
  dispatch(getTotal());
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setSearch = search => ({
  type: SEARCH,
  search,
});

export const setCarrito = carrito => ({
  type: CARRITO,
  carrito,
});

export const setData = data => ({
  type: DATA,
  data,
});

export const setModal = openModal => ({
  type: MODAL,
  openModal,
});

export const setTotal = total => ({
  type: TOTAL,
  total,
});

export const _setMoneda = moneda => ({
  type: MONEDA,
  moneda,
});

export const setOportunidad = oportunidad =>({
  type: OPORTUNIDAD,
  oportunidad,
});

export const setError = error =>({
  type: ERROR,
  error,
});

export const setAnimacion = animar => ({
  type: ANIMAR,
  animar,
});

export const setVenta = venta =>({
  type: VENTA,
  venta,
})

// Pendiente a confirmar si se utilizara
export const setEmisores = emisores =>({
  type: EMISORES,
  emisores,
})

export const actions = {
  buscarOportunidad,
  agregarCarrito,
  getProductos,
  getTotal,
  setMoneda,
  borrarCarrito,
  cambiarCantidad,
  setModal,
  realizarPago,
  pagarOportunidad,
  getGenericos,
  setError,
  setVenta,
};

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [CARRITO]: (state, { carrito }) => {
    return {
      ...state,
      carrito,
    };
  },
  [TOTAL]: (state, { total }) => {
    return {
      ...state,
      total,
    };
  },
  [MONEDA]: (state, { moneda }) => {
    return {
      ...state,
      moneda,
    };
  },
  [EMISORES]: (state, { emisores }) =>{
    return {
      ...state,
      emisores,
    }
  },
  [MODAL]: (state, { openModal }) =>{
    return {
      ...state,
      openModal,
    }
  },
  [OPORTUNIDAD]: (state, { oportunidad }) =>{
    return {
      ...state,
      oportunidad,
    }
  },
  [ERROR]: (state, { error }) =>{
    return {
      ...state,
      error,
    }
  },
  [ANIMAR]: (state, { animar }) =>{
    return {
      ...state,
      animar,
    }
  },
  [VENTA]: (state, { venta }) =>{
    return {
      ...state,
      venta,
    }
  },
};

export const initialState = {
  data: {
    results: []
  },
  loading: 0,
  error: false,
  search: '',
  carrito: [],
  total: {
    total_quetzales : 0,
    total_dolares : 0,
    total_quetzales_socios : 0,
    total_dolares_socios : 0
  },
  moneda:{
    codigo: 'GTQ',
  },
  openModal: false,
  animar: false,
  oportunidad:[],
  emisores:[],// Pendiente a confirmar si se utilizara
  venta: false,
};

export default handleActions(reducers, initialState);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import Moneda from './Moneda';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";
import swal from 'sweetalert2';
import "./carito.css";


class Carito extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = { sesion: false, registro: false, };
    this.handleChange = this.handleChange.bind(this);
    this.registroModal = this.registroModal.bind(this);
    this.sesionModal = this.sesionModal.bind(this);
    this.pasarCajar = this.pasarCajar.bind(this);
    this.login = this.login.bind(this);
  }

  componentWillMount() {
    this.props.getProductos();    
  }
  
  handleChange(e){
    this.props.setMoneda(e.value);
  }

  login(data) {
    this.props.login(data);
  }

  sesionModal() {
    this.props.setAccionModal('Pago');
    this.props.setModal(true);
  }

  pasarCajar(){
    if (!!this.props.usuario.first_name && !!this.props.usuario.perfil) {
      this.props.history.push('/tienda/caja');
    } else if(!!this.props.usuario.first_name) {
      swal(
          t.translate('ERROR'),
          t.translate('!El usuario de la sesión activa no puede realizar transacciones!, si desea realizar una transacción, cierre la sesión e ingrese con un usuario que si pueda (un usuario que no sea administrador)'),
          'error'
        )
    } else {
      this.props.setAccionModal('Caja');
      this.props.setModal(true);                 
    }
  }

  registroModal(){
    this.setState({ registro: false});
  } 

  openRegistro(){
    this.setState({ registro: true});
  }

  render() {
    const { en_carrito, idioma, total, moneda, usuario} = this.props;
    let total_dolares = 0;
    let total_quetzales = 0;
    if(usuario.es_socio){
      total_dolares=total.total_dolares_socios;
      total_quetzales=total.total_quetzales_socios;
    }
    else
    {
      total_dolares=total.total_dolares;      
      total_quetzales=total.total_quetzales;     
    }
    return (
      <div id="carito-pagos" className="d-flex flex-column m-3 bd-highlight justify-content-center">
          <div className="d-flex flex-column m-1 align-items-center">
            <i className="p-2 fa fa-shopping-cart fa-3x" />
            <div className="p-1 encabezado">              
              <p>{t.translate("DETALLE DE CARRITO")}</p>            
            </div>
          </div>
          <div className="d-flex flex-row moneda justify-content-center m-2">
            <div className="p-2 flex-1 align-self-end">
            {t.translate("VER EN")}
            </div>
            <div className="p-2 flex-1">
              <Moneda
                moneda = {moneda.codigo}                 
                handleChange={this.handleChange}
              />
            </div>            
          </div>                   
            <p className="ml-3">{t.translate("Total de articulos")} ({en_carrito ? en_carrito : 0}):</p>            
          <div className="d-flex flex-column align-items-center">            
            <h1><RenderCurrency value={moneda.codigo === "GTQ" ? total_quetzales: total_dolares} prefix={moneda.codigo === "GTQ" ? "Q": "$"} /></h1>            
          </div>
          <div className="d-flex flex-column m-3 align-items-center">
            <div className="p-1">
              <a style={{color: "black"}}className="btn btn-primary m-1 align-self-center" onClick={() => this.pasarCajar()}>{t.translate("Pasar a Caja")}</a>
            </div>
              { !usuario.first_name ?
              <div className="d-flex flex-column align-items-center" >
                <h5>{t.translate("También puedes:")}</h5>
                <a className="enlace" onClick={() => this.sesionModal()}>{t.translate("Iniciar sesión")}</a>                
                <center><small>{t.translate("Para llenar tus datos automáticamente")}</small></center>
                <div className="d-flex registrate flex-row justify-content-center align-self-center">
                  <hr />
                  <div className="mr-1 ml-1 pt-2">
                    <span>{t.translate("O")}</span>
                  </div>
                  <hr />            
                </div>
                <Link to="/tienda/registro" className="enlace align-self-center" >{t.translate("Registrarte")}</Link>                
                <center><small>{t.translate("Para llenar tus datos y realizar el pago")}</small></center>
              </div>             
              : null}                                        
          </div>          
      </div>
    );
  }
}

export default withRouter(Carito);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import "./listado.css";


class ListadoProducto extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {stand:null};
    this.editar = this.editar.bind(this);
  }

  editar(stand) {
    this.props.editar(stand);
    this.setState({stand: stand.id});
  }

  componentWillMount() {
    this.props.getProductos();
  }


  render() {
    const { loading, data, search } = this.props;
    const options = {
      hideSizePerPage: true,
      noDataText: 'No hay registros',
      prePage: 'Anterior',
      nextPage: 'Siguiente',
      firstPage: 'Primero',
      lastPage: 'Último',
    };
    options.page = this.props.page;
    options.onPageChange = this.props.getProductos;
    options.noDataText = loading ? "Cargando..." : options.noDataText;
    return (
      <div id="productos">
        <div className="">
          <div className="pt-5 pb-5 pl-1 pr-1 p-sm-5">
            <button onClick={this.props.sincronizar} className="btn btn-agregar m-1 mb-3">Sincronizar productos con CARTApi</button><br />
            <div className="fondo">

              <div className="buscador d-flex flex-row justify-content-between p-3 encabezado-azul">
                <div className="d-flex align-items-center mr-2">
                  <h2 className="d-none d-sm-inline-block m-0">Productos</h2>
                  <h4 className="d-inline-block d-sm-none m-0">Productos</h4>
                </div>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="toolbar-search">
                    <input className="form-control" type="text" placeholder="Buscar"
                           ref={node => {
                             this.buscar = node;
                             if (this.buscar) {
                               this.buscar.value = search;
                             }
                           }}
                           onChange={() => {
                             if (this.buscar) {
                               this.props.searchChange(this.buscar.value);
                             }
                           }}
                           autoComplete="off"
                    />
                    <span className="fa fa-search icon-search" />
                  </div>
                </div>
              </div>
              <BootstrapTable
                data={loading ? [] : data.results}
                remote
                hover
                fetchInfo={{ dataTotalSize: data.count }}
                options={options}
                pagination
              >
                <TableHeaderColumn
                  dataField="material"
                  dataSort
                  width={"10%"}
                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return "--------"
                  }}
                >
                  Material
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="nombre"
                  dataSort

                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return "--------"
                  }}
                >
                  Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="texto_comercial"
                  dataSort

                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return "--------"
                  }}
                >
                  Texto comercial
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="texto_comercial_ingles"
                  dataSort

                  dataFormat={(cell) => {
                    if (cell)
                      return cell;
                    return "--------"
                  }}
                >
                  Texto comercial inglés
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataAlign="center"
                  isKey
                  dataFormat ={(cell) =>{
                    return <div>
                      <Link style={{fontSize: "15px", padding: "0 4px", borderRadius: "2px"}} className="btn btn-primary text-light" to={`administrar_productos/detalle/${cell}`}>
                        <i className="fa fa-eye" />
                      </Link>
                      <Link to={`administrar_productos/${cell}`}>
                        <img className="action-img" title="Editar" alt="Editar" src={require("assets/img/editar.png")}/>
                      </Link>
                    </div>
                  }}
                >
                  Acciones
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListadoProducto;

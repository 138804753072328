import React from 'react';
import { RenderDateTime as DateTime, RenderCurrency } from './renderReadField';


export const RenderDateTime = ({ fecha, className }) => {
  if (fecha) {
    return (
      <DateTime value={fecha} className={className} />
    );
  }
  return <span className={className}>Sin registro</span>;
};

export const RenderMoneda = ({ monto, className, prefix }) => {
  if (monto || monto === 0) {
    return (
      <RenderCurrency value={monto} prefix={prefix} className={className} />
    );
  }
  return <span className={className}>Sin registro</span>;
};

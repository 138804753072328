import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';

const logo_es = require("assets/img/logo_blanco_es.png");
const fondo = require("assets/img/fondo_login.png");


class Login extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
  }

  render() {
    const { onSubmit, loader } = this.props;
    if (localStorage.getItem('token')) {
      return (<Redirect to="/administracion" />);
    }
    return (
      <div>
        <img src={fondo} style={{position: "absolute", width: "100%", height: "100%"}} alt=""/>
        <div id="login" className="d-flex flex-column">
          <div className="header d-flex justify-content-center flex-row align-items-center">
            <img className="ml-4" src={logo_es} alt={"Promoviendo oferta exportable"}/>
          </div>
          <div className="login-wrapper d-flex justify-content-center align-items-center">
            <div className="p-5 d-flex flex-column">
                <LoginForm onSubmit={onSubmit} loader={loader} />
                <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'react-dd-menu';
import { translator as t } from "../../../../../utility/traducer";

import "./header.css";
import "./dd-menu.css";

const logo_en = require("assets/img/logo_azul_en.png");
const logo_es = require("assets/img/logo_azul_es.png");


class Header extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    idioma: PropTypes.object.isRequired,
    planta: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {opened: false, openedIdiomas: false};
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
    this.closeIdiomas = this.closeIdiomas.bind(this);
    this.toggleIdiomas = this.toggleIdiomas.bind(this);
  }

  componentWillMount() {
    this.props.setIdioma(localStorage.getItem('lang') || 'es');
  }

  close() {
    this.setState({ opened: !this.state.opened });
  };

  toggle() {
    this.setState({ opened: !this.state.opened });
  };

  closeIdiomas() {
    this.setState({ openedIdiomas: !this.state.openedIdiomas });
  };

  toggleIdiomas() {
    this.setState({ openedIdiomas: !this.state.openedIdiomas });
  };

  render() {
    const { data, idioma, planta, plantas } = this.props;
    const { ocupante } = data;
    const menuIdiomas = {
      isOpen: this.state.openedIdiomas,
      close: this.closeIdiomas,
      closeOnInsideClick: false,
      toggle: <li style={{display: "inline-block"}}>
        <a
          style={{cursor: "pointer"}}
          onClick={this.toggleIdiomas}>
          <img src={require(`assets/img/idiomas/${idioma.img}.png`)} alt=""/>&nbsp;{idioma.nombre} <em className="fa fa-caret-down" />
        </a>
      </li>,
      align: 'right',
    };
    const menuPlantas = {
      isOpen: this.state.opened,
      close: this.close,
      closeOnInsideClick: false,
      toggle: <li style={{display: "inline-block"}}>
        <a
          style={{cursor: "pointer"}}
          onClick={this.toggle}>
          {t.translate("Planta")} {planta} <em className="fa fa-caret-down" />
        </a>
      </li>,
      align: 'right',
    };
    return (
      <div id="header" className="d-flex pl-3 pr-3 flex-row align-items-center justify-content-between justify-content-sm-start">
        <div className="logo m-1 d-none d-lg-flex flex-column justify-content-center">
          <img src={idioma.codigo === "es" ? logo_es : logo_en} alt={idioma.codigo === "es" ? "Promoviendo oferta exportable" : "Growing business together"}/>
        </div>
        <div className="d-none d-sm-flex">
          {t.translate("CROQUIS")}
        </div>
        <div className="separador d-none d-sm-inline-block" />
        <div className="ml-auto mr-4 d-none d-lg-flex"><a className="regresar" href="http://agritradecentralamerica.org"><img style={{maxWidth: "25px"}} src={require("assets/img/regresar_negro.png")} alt=""/>&nbsp;{t.translate('REGRESAR A LA PÁGINA')}</a></div>
        <div className="m-1 m-sm-4 d-flex d-lg-none align-items-center">
          <DropdownMenu {...menuPlantas}>
            <li><a style={{cursor: "pointer"}} onClick={() => {this.props.setPlanta(1); this.toggle();}}>{t.translate('Planta')} 1</a></li>
            <li><a style={{cursor: "pointer"}} onClick={() => {this.props.setPlanta(2); this.toggle();}}>{t.translate('Planta')} 2</a></li>
          </DropdownMenu>
        </div>
        <div className="m-1 m-sm-4 d-flex align-items-center">
          <DropdownMenu {...menuIdiomas}>
            <li><a style={{cursor: "pointer", color: "#505050"}} onClick={() => {this.props.setIdioma("es"); this.toggleIdiomas();}}><img src={require("assets/img/idiomas/España.png")} alt=""/> ESPAÑOL</a></li>
            <li><a style={{cursor: "pointer", color: "#505050"}} onClick={() => {this.props.setIdioma("en"); this.toggleIdiomas();}}><img src={require("assets/img/idiomas/Usa.png")} alt=""/> ENGLISH</a></li>
          </DropdownMenu>
        </div>
      </div>
    );
  }
}

export default Header;

import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/tienda/cuenta';
import Login from './Login';


const ms2p = (state) => {
  const { idioma } = state.planta;
  return {
    ...state.cuenta,
    idioma: idioma.codigo,
    loading: state.cuenta.loading !== 0,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Login);

import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/administracion/stands';
import Stands from './Stands';


const ms2p = (state) => {
  const loading = state.administracion.loading !== 0;
  let nuevaEmpresa = state.form.EdicionStand ? state.form.EdicionStand.values : undefined;
  nuevaEmpresa = nuevaEmpresa ? nuevaEmpresa.empresa : {};
  nuevaEmpresa = nuevaEmpresa ? nuevaEmpresa : {};
  nuevaEmpresa = !!nuevaEmpresa.__isNew__;
  return {
    ...state.planta,
    ...state.administracion,
    loading,
    nuevaEmpresa,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Stands);

import React, { Component } from 'react';
import { default as DetallePlanta } from './Plantas/index';
import { default as Reserva } from './Reserva/index';
import { default as Header } from './Header/index';


class Planta extends Component {
  render() {
    return (
      <div id="publico">
        <Header />
        <DetallePlanta />
        <Reserva />
      </div>
    );
  }
}

export default Planta;

import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { validate, validators } from 'validate-redux-form';
import { translator as t } from "traducer";
import { renderSelectField, renderNumber, renderCardNumber, renderCcv, renderField} from "Utils/renderField/renderField";
import { MESES, ESTILOS_SELECT_MONTH,MONTHS, AÑOS } from 'variables';
import Loader from "Utils/Loader";


const Form = props =>{
  const { handleSubmit, loader,idioma } = props;  
  
  return(
    <form onSubmit={handleSubmit}>
        <div className="form-group">
            <div className="d-flex flex-column">
                <div className="d-flex flex-column form-group">
                    <label htmlFor="number">{t.translate('Número de tarjeta *')}</label>
                    <Field name="number" component={renderCardNumber} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column form-group">
                    <label htmlFor="firstName">{t.translate('Nombre del dueño de la tarjeta *')}</label>
                    <Field name="firstName" component={renderField} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column form-group">
                    <label htmlFor="lastName">{t.translate('Apellido del dueño de la tarjeta *')}</label>
                    <Field name="lastName" component={renderField} type="text" className="form-control"/>
                </div>
                <div className="d-flex flex-column form-group">
                    <label htmlFor="expiration">{t.translate('Fecha de caducidad')}</label>
                    <label htmlFor="expirationMonth">{t.translate('Mes *')}</label>
                    <Field name="expirationMonth" component={renderSelectField} styles={ESTILOS_SELECT_MONTH} placeholder={t.translate('Seleccione mes')} options={ idioma==="es"?MESES:MONTHS } type="text" className="form-control"/>
                    <label htmlFor="expirationYear">{t.translate('Año *')}</label>
                    <Field name="expirationYear" component={renderSelectField} styles={ESTILOS_SELECT_MONTH} placeholder={t.translate('Seleccione año')} options={ AÑOS } type="text" className="form-control"/>                    
                </div>
                <div className="d-flex flex-column form-group">
                    <label htmlFor="securityCode">{t.translate('Código de seguridad *')}</label>
                    <Field name="securityCode" component={renderCcv} type="text" className="form-control"/>
                </div>            
                <div className="d-flex flex-column">
                    <button type="submit" className="btn btn-primary m-1 align-self-center">
                        <Loader active={loader} small>
                        {t.translate('Pagar')}
                        </Loader>                        
                    </button>
                </div>
            </div>          
        </div>      
    </form>
  )
}
const FormComprar = reduxForm({
  // a unique name for the form
  form : 'Comprar',
  validate: data => {
    return validate(data, {
      number: validators.exists()("Campor requerido"),
      firstName: validators.exists()("Campor requerido"),
      lastName: validators.exists()("Campor requerido"),
      expirationMonth: validators.exists()("Campo requerido"),
      expirationYear: validators.exists()("Campo requerido"),
      securityCode: validators.exists()("Campo requerido"),
    })
  }
})(Form);

export default FormComprar;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {DropdownMenu as Slide} from 'react-dd-menu';
import './navbar.css';
import './burger-sidebar.css';

const logo_es = require("assets/img/logo_azul_es.png");
const usuarios = require("assets/img/usuarios_n.png");
const usuarios_a = require("assets/img/usuarios_a.png");
const stands = require("assets/img/stands_n.png");
const stands_a = require("assets/img/stands_a.png");
const entradas = require("assets/img/ticket_n.png");
const entradas_a = require("assets/img/ticket_a.png");
const productos = require("assets/img/producto_n.png");
const productos_a = require("assets/img/producto_a.png");
const transacciones = require("assets/img/transaccion_n.png");
const transacciones_a = require("assets/img/transaccion_a.png");

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.containsUrl = this.containsUrl.bind(this);
    this.getTexto = this.getTexto.bind(this);
    this.toggleSlide = this.toggleSlide.bind(this);
    this.state = { openedSlide: false };    
  }
  componentWillMount() {
    this.logOut = this.logOut.bind(this);
    this.props.getMe();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ verMenu: false });
    }
  }
  onClick = (item) => {
    this.props.navAction(item.key)
  }; 

  toggleSlide(){
    this.setState({ openedSlide: !this.state.openedSlide });
  };

  closeSlide(){
    this.setState({ openedSlide: !this.state.openedSlide });
  };

  getTexto() {
    if (this.containsUrl("administracion")) {
      return "STANDS";
    } else if (this.containsUrl("productos")) {
      return "PRODUCTOS";
    } else if (this.containsUrl("usuario")) {
      return "USUARIOS";
    } else if (this.containsUrl("entradas")) {
      return "ENTRADAS";
    }else if (this.containsUrl("transacciones")) {
      return "TRANSACCIONES";
    }

  }
  containsUrl(url) {
    return this.props.url.includes(url);
  }

  logOut(event) {
    this.props.logOut();
  }

  render() {
    const { url } = this.props;
    const menuSlide = {
      isOpen: this.state.openedSlide,
      close: this.closeSlide,
      closeOnInsideClick: false,
      toggle: <li style={{display: "inline-block"}}>
        <a                  
          style={{cursor: "pointer"}}
          onClick={this.toggleSlide}>
          {this.getTexto()}&nbsp;<i className="fa fa-caret-down" />
        </a>        
      </li>,
      align: 'right',
    };
    return (
      <div className="enc d-flex justify-content-between flex-row align-items-center">
        <img className="ml-4" src={logo_es} alt="Promoviendo oferta exportable" />
        <div className="d-none d-sm-flex flex-row ml-4 mr-auto align-items-center">
          <Link className="d-flex flex-row align-items-center" to={"/administracion"} >
            <img className="small-img" src={this.containsUrl("administracion") ? stands_a : stands} alt=""/>
            <div className={this.containsUrl("administracion") ? "text-primary" : "text-oscuro"}>STANDS</div>
          </Link>          
          <Link className="d-flex flex-row ml-3 align-items-center" to={"/usuarios"} >
            <img className="small-img" src={this.containsUrl("usuarios") ? usuarios_a : usuarios} alt=""/>
            <div className={this.containsUrl("usuarios") ? "text-primary" : "text-oscuro"}>USUARIOS</div>
          </Link>
          <Link className="d-flex flex-row ml-3 align-items-center" to={"/administrar_productos"} >
            <img className="small-img" src={this.containsUrl("productos") ? productos_a : productos} alt=""/>
            <div className={this.containsUrl("productos") ? "text-primary" : "text-oscuro"}>PRODUCTOS</div>
          </Link>
          <Link className="d-flex flex-row ml-3 align-items-center" to={"/administrar_entradas"} >
            <img className="small-img" src={this.containsUrl("entradas") ? entradas_a : entradas} alt=""/>
            <div className={this.containsUrl("entradas") ? "text-primary" : "text-oscuro"}>ENTRADAS</div>
          </Link>
          <Link className="d-flex flex-row ml-3 align-items-center" to={"/administrar_transacciones"} >
            <img className="small-img" src={this.containsUrl("transacciones") ? transacciones_a : transacciones} alt=""/>
            <div className={this.containsUrl("transacciones") ? "text-primary" : "text-oscuro"}>TRANSACCIONES</div>
          </Link>
        </div>
        <div className="align-items-center ml-4 ml-lg-5 mr-4 text-oscuro d-flex d-md-none">
          <Slide {...menuSlide}>
            <li><a style={{color: "#505050"}} className="d-inline-block" href={"/#/usuarios"} onClick={() => {this.toggleSlide();}}>USUARIOS&nbsp;<em className="fa fa-user align-self-center" /></a></li>
            <li><a style={{color: "#505050"}} className="d-inline-block" href={"/#/administracion"} onClick={() => {this.toggleSlide();}}>STANDS&nbsp;<em className="fa fa-list align-self-center" /></a></li>
            <li><a style={{color: "#505050"}} className="d-inline-block" href={"/#/administrar_productos"} onClick={() => {this.toggleSlide();}}>PRODUCTOS&nbsp;<em className="fa fa-archive align-self-center" /></a></li>
            <li><a style={{color: "#505050"}} className="d-inline-block" href={"/#/administrar_entradas"} onClick={() => {this.toggleSlide();}}>ENTRADAS&nbsp;<em className="fa fa-ticket align-self-center" /></a></li>
            <li><a style={{color: "#505050"}} className="d-inline-block" href={"/#/administrar_transacciones"} onClick={() => {this.toggleSlide();}}>TRANSACCIONES&nbsp;<img style={{maxHeight: "20px"}} className="align-self-center" src={transacciones} alt=""/></a></li>
            <li><a style={{color: "#505050"}} className="d-inline-block" href={"/#/"} onClick={() => {
              this.logOut();
              this.props.cerrarSesion();
              this.toggleSlide();
            }}>CERRAR SESIÓN&nbsp;<em className="fa fa-lock align-self-center" /></a></li>
          </Slide>          
        </div>
        <li className="d-none d-md-flex ml-4 ml-lg-5 mr-4 text-oscuro"><a style={{color: "#505050"}} className="d-inline-block" href={"/#/"} onClick={() => {
          this.logOut();
          this.props.cerrarSesion();
          this.toggleSlide();
        }}>CERRAR SESIÓN&nbsp;<em className="fa fa-lock align-self-center" /></a></li>
      </div>
    );
  }
}
Navbar.propTypes = {
};

export default Navbar;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {DISPONIBLE, RESERVADO} from "variables";
import { translator as t } from "../../../../../../utility/traducer";


class Encabezado extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  getEstado(estado) {
    if (estado === DISPONIBLE) {
      return "Disponible";
    } else if (estado === RESERVADO) {
      return "Reservado";
    }
    return "Comprado";
  }

  render() {
    const { data } = this.props;
    return (
      <div className="d-flex flex-row flex-lg-column p-2 p-lg-4 pt-3 pt-lg-4" id="encabezado-espacio">
        <h3 className="font-weight-bold">
          {t.translate('ESPACIO NO.')}&nbsp;
          {data.espacio}
        </h3>
        <div className="d-flex flex-row">
          <h4 className={`text-capitalize font-weight-light ${data.estado === DISPONIBLE ? 'text-amarillo' : 'text-blanco'}`}>
            ({t.translate(this.getEstado(data.estado))})
          </h4>
          <div className="d-none d-lg-flex flex-row ml-auto">
            <a className="mr-2" style={{cursor: "pointer"}} onClick={() => {this.props.standAnterior(data.espacio)}}><i className="fa fa-2x fa-arrow-left" /></a>
            <a className="ml-2" style={{cursor: "pointer"}} onClick={() => {this.props.standSiguiente(data.espacio)}}><i className="fa fa-2x fa-arrow-right" /></a>
          </div>
        </div>
      </div>
    );
  }
}

export default Encabezado;

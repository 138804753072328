import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { translator as t } from 'traducer';
import { validate, validators, validatorFromFunction } from 'validate-redux-form';
import { renderField, renderImagePicker, renderSelectField } from "Utils/renderField/renderField";
import { PAISES, ESTILOS_SELECT_PERFIL_READ, ESTILOS_SELECT_PERFIL_WRITE } from 'variables';
import Select, { AsyncCreatable, Async } from 'react-select';
import classNames from "classnames";
import validarNIT from 'validar-nit-gt';

let mostrar1=true
let vaciarNit=false
let nit1




const Form = props =>{
  const { handleSubmit, solo_lectura, change,toggleForm, photo, setFile, usuario } = props;

  const renderSelectField2 = ({ valueKey, styles, placeholder, labelKey, input, disabled, options, meta: { touched, error }, }) => {
    const invalid = touched && error;  
    return (
      <div>
        <Select isDisabled={disabled} className={classNames('', { 'is-invalid': invalid })}
                options={options}
                value={input.value}
                styles={styles}              
                getOptionValue={(option) => option[valueKey]?option[valueKey]:option.value}
                getOptionLabel={(option) => option[labelKey]?option[labelKey]:option.label}
                onChange={(e) => {
                  input.onChange(e);
                  if (e.id == "GT")
                    mostrar1=undefined
                  else
                    change('nit' ,"CF"),
                    mostrar1=true,
                    vaciarNit = !vaciarNit
                }}
                placeholder={placeholder}
                >
        </Select>
        {invalid && (
          <div className="invalid-feedback">
            {error}
          </div>
        )}
      </div>
    );
  };


  return(

    <form onSubmit={handleSubmit} className="form-perfil d-flex flex-column-reverse flex-md-row p-3">
      {solo_lectura ?
        (<a onClick={toggleForm} className="btn btn-default m-1 d-inline-block d-sm-none align-self-center"><strong>{t.translate('Editar')}</strong></a>) :
        (<button type="submit" className="btn btn-default m-1 d-inline-block d-sm-none align-self-center"><strong>{t.translate('Guardar')}</strong></button>)
      }
      <div className="d-flex mr-3 align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap">
        <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px", maxWidth: "200px"}}>
          <label htmlFor="foto">{t.translate('Foto')}</label>
          <Field disabled={solo_lectura} photo={photo} setFile={setFile} name="foto" component={renderImagePicker} className="foto-container"/>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between flex-row">
          <h3><strong>{`${usuario.first_name} ${usuario.last_name}`}</strong></h3>
          {solo_lectura ?
            (<a onClick={toggleForm} className="btn btn-default m-1 d-none d-sm-inline-block align-self-center"><strong>{t.translate('Editar')}</strong></a>) :
            (<button type="submit" className="btn btn-default m-1 d-none d-sm-inline-block align-self-center"><strong>{t.translate('Guardar')}</strong></button>)
          }
        </div>
        <div className="form-group">
          <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
            <div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap">
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="first_name">{solo_lectura ? t.translate('Nombre') : t.translate('Nombre *')}</label>
                <Field disabled={solo_lectura} name="first_name" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="last_name">{solo_lectura ? t.translate('Apellidos') : t.translate('Apellidos *')}</label>
                <Field disabled={solo_lectura} name="last_name" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="username">{solo_lectura ? t.translate('Correo'): t.translate('Correo *')}</label>
                <Field disabled={solo_lectura} name="username" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
              <div className="flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="nit">{solo_lectura ? t.translate('Nit') : t.translate('Nit *')}</label>
                <Field disabled={solo_lectura} name="nit" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
                <Field
                    disabled={solo_lectura}
                    name="nit_check"
                    component="input"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked==true)
                        change('nit' ,"CF"),
                        vaciarNit = !vaciarNit
                      else
                        change('nit' ,"")
                    }}
                    />
              <label id={"Popover1"} htmlFor="CF" disabled={mostrar1}>{t.translate('CF')}</label>

              </div>

                

              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="direccion">{solo_lectura ? t.translate('Dirección'): t.translate('Dirección *')}</label>
                <Field disabled={solo_lectura} name="direccion" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="ciudad">{solo_lectura ? t.translate('Ciudad') : t.translate('Ciudad *')}</label>
                <Field disabled={solo_lectura} name="ciudad" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="telefono">{solo_lectura? t.translate('Teléfono') : t.translate('Teléfono *')}</label>
                <Field disabled={solo_lectura} name="telefono" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="codigo_postal">{solo_lectura ? t.translate('Código postal') : t.translate('Código postal *')}</label>
                <Field disabled={solo_lectura} name="codigo_postal" component={renderField} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px", maxWidth: "250px"}}>
              <label htmlFor="pais">{t.translate('País *')}</label>
              <Field name="pais" disabled={solo_lectura}  component={renderSelectField2} options={PAISES} styles={solo_lectura ? ESTILOS_SELECT_PERFIL_READ: ESTILOS_SELECT_PERFIL_WRITE} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "300px", maxWidth: "350px"}}>
                <label htmlFor="razon_fiscal">{solo_lectura ? t.translate('Razón Social'): t.translate('Razón Social *')}</label>
                <Field disabled={solo_lectura} name="razon_fiscal" component={renderField} styles={solo_lectura ? ESTILOS_SELECT_PERFIL_READ: ESTILOS_SELECT_PERFIL_WRITE} options={PAISES} type="text" className={`form-control ${solo_lectura ? "solo_lectura" : ""}`}/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  )
};

const PerfilForm = reduxForm({
  // a unique name for the form
  form : 'PerfilForm',
  validate: (data, props) => {
    const valido = validarNIT(data.nit)
    let correo = validators.exists()(t.translate("Este campo es requerido"));
    if (!!data.correo) {
      correo = validators.regex(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)(t.translate('Debe ser un correo válido'));
    }
    let nit
    if(data.nit !=="CF"){
      nit = validatorFromFunction(() => (valido))()(t.translate("nit invalido"));
    }
    let telefono = validators.exists()(t.translate("Este campo es requerido"));
    if(!!data.telefono) {
      telefono = validatorFromFunction(() => (data.telefono.length <= 15))()(t.translate("Longitud máxima de 15 caracteres"))
    }
    return validate(data, {
      first_name: validators.exists()(t.translate("Este campo es requerido")),
      last_name: validators.exists()(t.translate("Este campo es requerido")),
      username: validators.exists()(t.translate("Este campo es requerido")),
      correo,
      direccion: validators.exists()(t.translate("Este campo es requerido")),
      telefono,
      nit,
      codigo_postal: validators.exists()(t.translate("Este campo es requerido")),
      pais: validators.exists()(t.translate("Este campo es requerido")),
      ciudad: validators.exists()(t.translate("Este campo es requerido")),
    })
  }
})(Form);

export default PerfilForm;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t} from "../../../../../utility/traducer";

import "./planta.css";


class Espacio extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    filtrar: PropTypes.func.isRequired,
    filtros: PropTypes.array.isRequired,
  };

  render() {
    const { data, filtrar, filtros } = this.props;
    return (
      <div id="seleccion_espacio" className="d-flex flex-column">
        {/*region Selecciona espacio*/}
        <div className="d-flex flex-column">
          <h3 className="text-verde mb-3 font-weight-bold">{t.translate('Selecciona el espacio que deseas ver')}</h3>
          <div className="d-flex flex-row text-gris justify-content-between">
            <div className="d-flex flex-row">
              <div className="font-weight-bold espacios disponible">{data.disponibles}</div>
              <div className="d-flex flex-column ml-2 justify-content-center font-weight-bold" style={{fontSize: "75%"}}>
                <div>{t.translate('Espacios')}</div>
                <div>{t.translate('Disponibles')}</div>
              </div>
            </div>
            &nbsp;
            <div className="d-flex flex-row">
              <div className="font-weight-bold espacios reservado">{data.reservados}</div>
              <div className="d-flex flex-column ml-2 justify-content-center font-weight-bold" style={{fontSize: "75%"}}>
                <div>{t.translate('Espacios')}</div>
                <div>{t.translate('Reservados')}</div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row  text-gris">
            <div className="font-weight-bold espacios comprado">{data.comprados}</div>
            <div className="d-flex flex-column ml-2 justify-content-center font-weight-bold" style={{fontSize: "75%"}}>
              <div>{t.translate('Espacios')}</div>
              <div>{t.translate('Comprados')}</div>
            </div>
          </div>
        </div>
        {/*endregion Selecciona espacio*/}
        {/* region Visualizar*/}
        <div className="d-flex flex-column mt-4">
          <h3 className="text-verde mb-0 font-weight-bold">{t.translate('Visualizar')}</h3>
          <div className="d-flex flex-wrap">
            <div className="checkbox c-checkbox mt-2 w-100">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("todos")}} checked={filtros.includes("todos")}/>
                <span className="" />
                &nbsp;&nbsp;{t.translate('Ver todos')}
              </label>
            </div>

            <div className="checkbox c-checkbox mt-2 w-50">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("2x2")}} checked={filtros.includes("2x2")}/>
                <span className="" />
                &nbsp;&nbsp;2X2 {t.translate('Mts')}
              </label>
            </div>

            <div className="checkbox c-checkbox mt-2 w-50">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("4x2")}} checked={filtros.includes("4x2")}/>
                <span className="" />
                &nbsp;&nbsp;4X2 {t.translate('Mts')}
              </label>
            </div>

            <div className="checkbox c-checkbox mt-2 w-50">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("3x2")}} checked={filtros.includes("3x2")}/>
                <span className="" />
                &nbsp;&nbsp;3X2 {t.translate('Mts')}
              </label>
            </div>

            <div className="checkbox c-checkbox mt-2 w-50">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("3x3")}} checked={filtros.includes("3x3")}/>
                <span className="" />
                &nbsp;&nbsp;3X3 {t.translate('Mts')}
              </label>
            </div>

            <div className="checkbox c-checkbox mt-2 w-50">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("3x1.5")}} checked={filtros.includes("3x1.5")}/>
                <span className="" />
                &nbsp;&nbsp;3X1.5 {t.translate('Mts')}
              </label>
            </div>

            <div className="checkbox c-checkbox mt-2 w-50">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("5x4")}} checked={filtros.includes("5x4")}/>
                <span className="" />
                &nbsp;&nbsp;5X4 {t.translate('Mts')}
              </label>
            </div>

            <div className="checkbox c-checkbox mt-2 w-50">
              <label className="check-label">
                <input type="checkbox" onChange={() => {filtrar("4x3")}} checked={filtros.includes("4x3")}/>
                <span className="" />
                &nbsp;&nbsp;3X4 {t.translate('Mts')}
              </label>
            </div>

          </div>
        </div>
        {/* endregion Visualizar*/}
      </div>
    );
  }
}

export default Espacio;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import { RenderCurrency, RenderDateTime, RenderDate } from "Utils/renderField/renderReadField";
import { Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import _ from "lodash";
import "./detalle.css";

class DetalleCompra extends Component{
    static propTypes = {
        compra: PropTypes.object.isRequired,
      };
    componentWillMount() {
        try {
          this.props.getCompra(this.props.match.params.id);
          this.props.getArchivo(this.props.match.params.id);
        } catch (e) {}
      }
    render(){
        const { compra, loading_entradas: loading, pageCompras: page, idioma, archivo } = this.props;  
        const options = {
            hideSizePerPage: true,
            noDataText: loading ? `${t.translate('Cargando')}...` : t.translate('No hay registros'),
            page: page,
            onPageChange: this.props.getCompra,
          };

        return(
            <div id="historial-compras">
                <div className="d-flex flex-column align-self-center ml-5 mr-5 mt-3 bordes">
                    <div className="d-flex flex-column align-items-center bordes w-100"><strong><h2>{t.translate('Detalle Compra')}</h2></strong></div>                    
                    <div className="d-flex flex-sm-row flex-column align-items-start">
                        <div className="p-2 flex-1 bordes w-100"><strong>{t.translate("Fecha de compra")}</strong></div>
                        <div className="p-2 flex-1 bordes w-100"><RenderDateTime value={compra.creado}/></div>
                    </div>
                    <div className="d-flex flex-sm-row flex-column align-items-start">
                        <div className="p-2 flex-1 bordes w-100"><strong>{t.translate("Código de transacción")}</strong></div>
                        <div className="p-2 flex-1 bordes w-100">{compra.transferencia ? compra.transferencia : compra.id}</div>
                    </div>
                    <div className="d-flex flex-sm-row flex-column align-items-start">
                        <div className="p-2 flex-1 bordes w-100"><strong>{t.translate("Moneda")}</strong></div>
                        <div className="p-2 flex-1 bordes w-100">{compra.moneda}</div>
                    </div>
                    <div className="d-flex flex-sm-row flex-column align-items-start">
                        <div className="p-2 flex-1 bordes w-100"><strong>{t.translate("Oportunidad")}</strong></div>
                        <div className="p-2 flex-1 bordes w-100">{t.translate(compra.es_oportunidad ? "Si": "No")}</div>
                    </div>
                    {
                        compra.es_oportunidad && (
                            <div className="d-flex flex-sm-row flex-column align-items-start">
                                <div className="p-2 flex-1 bordes w-100"><strong>{t.translate("Descripción de oportunidad")}</strong></div>
                                <div className="p-2 flex-1 bordes w-100">{t.translate(compra.texto_oportunidad ? compra.texto_oportunidad: "Oportunidad")}</div>
                            </div>
                        )
                    }                    
                </div>
                {!compra.es_oportunidad && (<div className="d-flex flex-column align-items-center ml-5 mr-5 p-2 bordes"><strong>{t.translate('Productos')}</strong></div>)}
                {!compra.es_oportunidad && (<BootstrapTable
                data={loading ? [] : compra.detalle_compra}
                remote
                hover
                options={options}
                pagination
                className = "ml-5 mr-5"
                >
                    <TableHeaderColumn
                            dataField="texto_comercial"
                            isKey
                            dataSort
                            dataFormat={(cell,row) => {
                            if (cell){
                                if(idioma === "es")
                                    return cell
                                else
                                    return row.texto_comercial_ingles
                            }
                            return "--------"
                            }}
                        >
                            {t.translate("Producto")}
                    </TableHeaderColumn>                    
                    <TableHeaderColumn
                            dataAlign="right"
                            dataField="precio"
                            dataSort
                            dataFormat={(cell) => {
                            if (cell)
                                return <RenderCurrency value={cell} prefix={compra.moneda === "GTQ" ? "Q": "$"} />;
                            return "0.00"
                            }}
                        >
                            {t.translate("Precio Unitario")}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                            dataAlign="center"
                            dataField="cantidad"
                            dataSort
                            dataFormat={(cell) => {
                            if (cell)
                                return cell
                            return "0.00"
                            }}
                        >
                            {t.translate("Cantidad")}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                            dataAlign="right"
                            dataField="precio"
                            dataSort
                            dataFormat={(cell,row) => {
                            if (cell){
                                const total = cell * row.cantidad;
                                return <RenderCurrency value={total} prefix={compra.moneda === "GTQ" ? "Q": "$"} />;
                            }
                            return "0.00"
                            }}
                        >
                            {t.translate("Total")}
                    </TableHeaderColumn>
                </BootstrapTable>)}
                <div className="d-flex flex-row d-flex ml-5 mr-5 bordes">
                    <div className="p-2 flex-1 bordes"><strong>{t.translate("Total")}</strong></div>
                    <div className="d-flex flex-column align-items-end p-2 flex-1 bordes"><strong><RenderCurrency value={compra.total} prefix={compra.moneda === "GTQ" ? "Q": "$"} /></strong></div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mt-5 mb-5">
                    <Link className="mr-1 btn btn-secondary" to="/tienda/perfil"><strong>{t.translate('Regresar')}</strong></Link>
                    {
                        archivo.count<2 ? 
                        <div>
                            {                      
                                archivo.results.map(arch => {
                                    return(
                                        <a key={arch.id} className="mr-1 btn btn-default" href={arch.archivo} download target="_blank"><em className="fa fa-download"/> <strong>{t.translate('Descargar recibo')}</strong></a>                                                                        
                                    )
                                })
                            }
                        </div>
                        :<div>
                            <button className="mr-1 btn btn-default" onClick={()=>{
                                this.props.getZip(this.props.match.params.id)                                
                              }}><em className="fa fa-download"/> <strong>{t.translate('Descargar recibo')}</strong>
                            </button>
                        </div>
                    }                    
                </div>
            </div>
        );
    }
}

export default DetalleCompra;

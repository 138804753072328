import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Oportunidades from "./Oportunidades/CodigoOportunidad";
import Entradas from "./Entradas";
import Carrito from "./Carrito";
import { translator as t } from "traducer";


const logo_en = require("assets/img/logo_blanco_en.png");
const logo_es = require("assets/img/logo_blanco_es.png");


class Inicio extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };


  render() {
    const { data } = this.props;

    return (
      <div className="d-flex flex-column align-items-center justify-content-between justify-content-sm-start">
        <Oportunidades />
        <Entradas />
        <Carrito />
      </div>
    );
  }
}

export default Inicio;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Encabezado from './Secciones/Encabezado';
import Vecinos from './Secciones/Vecinos';
import Precio from './Secciones/Precio';
import Reserva from './Secciones/Reserva';
import Loader from 'Utils/Loader';

import './reserva.css';
import {DISPONIBLE, RESERVADO} from "variables";


class Espacio extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    idioma: PropTypes.object.isRequired,
    envioContacto: PropTypes.func.isRequired,     

  };

  componentWillMount() {    
    this.props.getStands();
    
  }
  
  render() {
    const { data, idioma, loaderStand, cargaStand, verStand } = this.props;
    return (
      <div id="reserva" className="d-flex flex-column flex-sm-column">
        <Encabezado {...this.props} />
        <div id="cuerpo-espacio" className="d-flex flex-column p-1 p-lg-4 align-items-center justify-content-start justify-content-sm-center h-100">
          <Loader active={loaderStand} />
          {!loaderStand && (
            <div>
              {(data.estado === DISPONIBLE) ? (
                <Precio data={data} envioContacto={this.props.envioContacto} idioma={idioma.codigo} cargaStand={cargaStand} />
              ) : (
                <Reserva {...this.props}/>
              )}
              <Vecinos {...this.props} verStand={verStand} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Espacio;

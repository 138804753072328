import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./datos.css";
import { translator as t } from "traducer";
import DatosForm from "./DatosForm";
import swal from 'sweetalert2';
import { Redirect } from "react-router-dom";


class Datos extends Component {
  static propTypes = {
    usuario: PropTypes.object.isRequired,
    getMe: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {solo_lectura: true, foto: null};
    this.toggleForm = this.toggleForm.bind(this);
    this.setFile = this.setFile.bind(this);
  }

  toggleForm() {
    this.setState({solo_lectura: !this.state.solo_lectura});
  }

  onSubmit = (data) => {
    this.props.editar(data, {name: "foto", file: this.state.foto});
    this.toggleForm();
  };

  setFile(foto) {
    this.setState({foto});
  }

  componentWillMount() {
    this.props.getMe();
  }

  render() {
    const { usuario } = this.props;
    if (!usuario.perfil && !!usuario.first_name) {
      swal(
        t.translate('ERROR'),
        t.translate('!El usuario de la sesión activa no puede acceder a transacciones o al perfil!'),
        'error'
      );
      return (
        <Redirect to='/tienda/inicio' />
      )
    }

    return (
      <div id="perfil-datos">
        <h4><strong>{t.translate("MI PERFIL")}</strong></h4>
        <DatosForm
          change={this.change}
          onSubmit={this.onSubmit}
          setFile={this.setFile}
          photo={this.props.photo}
          solo_lectura={this.state.solo_lectura}
          toggleForm={this.toggleForm}
          usuario={this.props.usuario}
        />
      </div>
    );
  }
}

export default Datos;

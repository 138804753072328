import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EdicionForm from './form';
import "../forms.css";

const logo_es = require("assets/img/logo_blanco_es.png");

class Edicion extends Component {
    constructor(props) {
      super(props);
      this.state = {contrasenia: false };
      this.modificado = this.modificado.bind(this);
      this.cambiarPass = this.cambiarPass.bind(this);
    }

     modificado(data){
       if(data){
         this.props.updateUsuarios({...data}, this.props.match.params.id);
       }
    }

    cambiarPass() {
      this.setState({contrasenia:true});
    }

    componentWillMount() {
      this.props.getUser(this.props.match.params.id);
    }

    render() {

        return (
            <div id="formulario">
                <div className="d-flex flex-column">
                    <div className="p-2 p-sm-3 encabezado-azul">
                        <h1>Editar Usuario</h1>
                    </div>
                    <div className="p-2 p-sm-3">                        
                        <EdicionForm cambiarPass={this.cambiarPass} onSubmit={this.modificado} contrasenia={this.state.contrasenia} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Edicion

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SolicitudForm from "./SolicitudForm";
import {logo_blanco_en, logo_blanco_es} from "utility/variables";


class Solicitud extends Component {
  static propTypes = {
    solicitarPass: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.cerrar = this.cerrar.bind(this);
  }

  cerrar(){
    this.props.setModal(false);
  }

  componentWillMount() {
  }

  render() {

    const { idioma, loading_recuperacion } = this.props;
    return (
      <div id="tienda-login">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={idioma === "es" ? logo_blanco_es : logo_blanco_en} alt="Agritrade"/>
        </div>
        <div className="d-flex flex-row barra justify-content-center">
          <div className="verde" />
          <div className="naranja" />
          <div className="rojo" />
          <div className="amarillo" />
          <div className="azul" />
        </div>
        <SolicitudForm onSubmit={this.props.solicitarPass} loader={loading_recuperacion} />
      </div>
    );
  }
}

export default Solicitud;

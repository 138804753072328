import { handleActions } from 'redux-actions';
import { api } from "api";
import { translator as t} from "traducer";
import _ from "lodash";
import {initialize as initializeForm} from 'redux-form';
import {ToastStore} from "react-toasts";
import { push } from "react-router-redux";
import { actions as printActions } from "Utils/Print";
import {TIPOS_ENTRADA} from "../../../utility/variables";
import swal from 'sweetalert2';


const LOADING = 'STAND_LOADING';
const LOADING_ENTRADAS = 'STAND_LOADING_ENTRADAS';
const STAND = 'STAND_STAND';
const ENTRADA = 'STAND_ENTRADA';
const FILTRO = 'STAND_FILTRO';
const EMPRESAS = 'STAND_EMPRESAS';

export const constants = {
};

// ------------------------------------
// Actions
// ------------------------------------
export const getEmpresas = () => (dispatch) => {
  dispatch(setLoading(true));
  api.get('data/empresas').then((data) => {
    dispatch(setEmpresas(data));
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const setLoading = load => (dispatch, getStore) => {
  const loading = getStore().stand.loading;
  if (load) {
    dispatch({type: LOADING, loading: loading + 1 });
  } else {
    if (loading > 0)
      dispatch({type: LOADING, loading: loading - 1 });
  }
};

export const getEntradas = (filtro, codigo) => (dispatch) => {
  dispatch(setLoadingEntradas(true));
  const params = {};
  if (filtro !== undefined)
    params.estado = filtro;
  if (codigo !== undefined)
    params.codigo = codigo;
  api.get("data/get_stand", params).then((data) => {
    dispatch(setLoading(true));
    if(parseFloat(data.empresa))
    {
      api.get('data/empresas').then((resultado) => {
        dispatch(setEmpresas(resultado));
        const empresa = _.find(resultado, {Id: parseFloat(data.empresa)});
        if (!!empresa) {
          data.empresa = empresa.Nombre;
        }
        dispatch(setStand(data));
        if (_.filter(data.results, {estado: false}).length > 0) {
          swal(
              t.translate('Entradas pendientes de edición'),
              t.translate('Por favor edite las entradas y luego imprima si es necesario'),
              'info'
            )
        }
      }).catch((e) => {
        ToastStore.error(e.detail);
      }).finally(() => {
        dispatch(setLoading(false));
      })
    }else{
      dispatch(setStand(data));
      dispatch(setLoading(false));
    }    
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  });
};

export const getEntrada = (id) => (dispatch) => {
  dispatch(setLoadingEntradas(true));
  api.get(`entradas/${id}`).then((data) => {
    data.tipo_entrada = _.find(TIPOS_ENTRADA, {id: data.tipo_entrada});
    dispatch({type: ENTRADA, entrada: data});
    dispatch(initializeForm('EntradaForm', data));
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  });
};

export const editarEntrada = (id, data, imprimir, lugar) => (dispatch) => {
  dispatch(setLoadingEntradas(true));
  api.put(`entradas/${id}`, {...data}).then((response) => {
    dispatch({type: ENTRADA, entrada: response});
    if (imprimir) {
      setTimeout(() => {
        printActions.print('reciboIngreso')
      }, 300);
    } else {
      if (lugar.includes("administrar"))
        dispatch(push("/administrar_entradas"));
      else
        dispatch(push("/tienda/perfil"));
      ToastStore.success("Edición exitosa");
    }
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  })
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setStand = stand => ({
  type: STAND,
  stand,
});

export const setFiltro = filtro => ({
  type: FILTRO,
  filtro,
});

export const setLoadingEntradas = loading_entradas => ({
  type: LOADING_ENTRADAS,
  loading_entradas,
});

export const setEmpresas = empresas => ({
  type: EMPRESAS,
  empresas,
});


export const actions = {
  getEntrada,
  editarEntrada,
  getEntradas,
  setFiltro,
  getEmpresas,
};

export const reducers = {
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [LOADING_ENTRADAS]: (state, { loading_entradas }) => {
    return {
      ...state,
      loading_entradas,
    };
  },
  [STAND]: (state, { stand }) => {
    return {
      ...state,
      stand,
    };
  },
  [ENTRADA]: (state, { entrada }) => {
    return {
      ...state,
      entrada,
    };
  },
  [FILTRO]: (state, { filtro }) => {
    return {
      ...state,
      filtro,
    };
  },
  [EMPRESAS]: (state, { empresas }) => {
    return {
      ...state,
      empresas,
    };
  },
};

export const initialState = {
  empresas:[],
  loading: 0,
  loading_entradas: false,
  stand: { entradas: []},
  entrada: {tipo_entrada: {}},
  filtro: undefined,
};

export default handleActions(reducers, initialState);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SeleccionEspacios from "./SeleccionEspacios";
import Planta1 from "./Planta1";
import Planta2 from "./Planta2";
import Loader from "Utils/Loader";

import { translator as t } from "../../../../../utility/traducer";
import "./planta.css";

class Plantas extends Component {
  static propTypes = {
    plantas: PropTypes.array.isRequired,
    planta: PropTypes.number.isRequired,
    getStands: PropTypes.func.isRequired,
    loader: PropTypes.bool.isRequired,
  };

  constructor (props) {
    super (props);
  }
  componentWillMount() {
  }

  render() {
    const { plantas, planta, seleccionado, reservados, disponibles, comprados, loader } = this.props;
    return (
      <div id="plantas" className="d-flex flex-row">

        <div className="flex-3 pt-3 contenedor-planta" id="contenedor-planta">
          <div className="planta d-none d-lg-flex flex-row flex-wrap position-absolute">
            <div className={`${planta === 1 ? "active" : ""}`}>
              <a className={`pl-3 pr-3 ${planta === 1 ? "active" : ""}`} onClick={() => {this.props.setPlanta(1)}}>{t.translate('Planta')} 1</a>
            </div>
            <div className={`${planta === 2 ? "active" : ""}`}>
              <a className={`pl-3 pr-3 ${planta === 2 ? "active" : ""}`} onClick={() => {this.props.setPlanta(2)}}>{t.translate('Planta')} 2</a>
            </div>
          </div>
          <div className={`loader-container ${loader ? "d-flex" : "d-none"} justify-content-center align-items-center`}>
            <Loader color={"#8CBF3A"} active={loader} />
          </div>

          {!loader && (
            <div>
              {(planta === 1) ? (
                <Planta1 verStand={this.props.verStand} data={plantas} seleccionado={seleccionado} filtros={this.props.filtros} />
              ) : (
                <Planta2 verStand={this.props.verStand} data={plantas} seleccionado={seleccionado} filtros={this.props.filtros} />
              )}
            </div>
          )}
        </div>

        <div className="flex-1 mt-4 ml-1 p-3 d-none d-lg-flex">
          <SeleccionEspacios data={{reservados, disponibles, comprados}} filtrar={this.props.filtrar} filtros={this.props.filtros} />
        </div>
      </div>
    );
  }
}

export default Plantas;

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/administracion/productos';
import detalle from './detalle';


const ms2p = (state) => {
  const loader = state.productos !== 0;
  let photo;
  try {
    photo = state.form.detalle.values.foto;
  } catch (e) {}
  return {
    ...state.productos,
    loader,
    photo
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(detalle);
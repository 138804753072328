import React from 'react';
import _ from "lodash";
import { Link } from "react-router-dom";
import { Field, reduxForm } from 'redux-form'
import { translator as t } from 'traducer';
import { validate, validators, validatorFromFunction } from 'validate-redux-form';
import { renderField, renderSelectField, renderSearchSelect } from "Utils/renderField/renderField";
import {TIPOS_ENTRADA} from "utility/variables";
import Loader from "Utils/Loader";
import { api } from "api";


const getUsuarios = (search) => {
    return api.get("cliente", {search}).catch(() => {}).then((data) => {
        data.results.forEach(item => {
            item.value = item.id;
            item.label = item.username;
        });
        return data.results;
    })
};


const Form = props =>{
  const { handleSubmit, loader } = props;
  return(

    <form className="form-entrada d-flex flex-column p-3 flex-1 m-1 recuadro">
      <div className="d-flex flex-column">
        <div className="form-group">
          <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
            <div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap">
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="nombre">Nombre *</label>
                <Field name="nombre" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="apellido">Apellido *</label>
                <Field name="apellido" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="empresa">Empresa *</label>
                <Field name="empresa" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="correo">Correo *</label>
                <Field name="correo" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="usuario">Usuario del sistema</label>
                <Field name="usuario" component={renderSearchSelect} loadOptions={getUsuarios} className="form-control" placeholder={"Escriba para buscar"}/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-2" style={{minWidth: "200px"}}>
                <label htmlFor="tipo_entrada">Tipo de entrada *</label>
                <Field name="tipo_entrada" component={renderSelectField} options={ TIPOS_ENTRADA } className="form-control"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center">
        <Link to={window.location.href.includes("administrar") ? "/administrar_entradas" : "/tienda/perfil"} className={"btn btn-secondary m-1"} ><strong>{t.translate("Cancelar")}</strong></Link>
        <button onClick={handleSubmit(data => {
          if (!loader)
            props.onSubmit(data, true);
        })} className="btn btn-default m-1"><Loader active={loader} small><strong>{t.translate("Guardar e Imprimir")}</strong></Loader></button>
      </div>
      <div className="d-flex justify-content-center">
        <a style={{textDecoration: "underline", color: "#324BD8"}}
          onClick={handleSubmit(data => {
          if (!loader)
            props.onSubmit(data);
        })} className="m-2 pointer text-uppercase"><Loader active={loader} small>{t.translate("Guardar")}</Loader></a>
      </div>
    </form>
  )
};

const CrearEntradaForm = reduxForm({
  // a unique name for the form
  form : 'CrearEntradaForm',
  validate: (data, props) => {
    let nombre = validators.exists()(t.translate("Este campo es requerido"));
    let apellido = validators.exists()(t.translate("Este campo es requerido"));
    let correo = validators.exists()(t.translate("Este campo es requerido"));
    if (!!data.correo) {
      correo = validators.regex(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)(t.translate('Debe ser un correo válido'));
    }
    return validate(data, {
      nombre,
      apellido,
      empresa: validators.exists()(t.translate("Este campo es requerido")),
      tipo_entrada: validators.exists()(t.translate("Este campo es requerido")),
      correo,
    })
  },
  initialValues: {
    tipo_entrada: TIPOS_ENTRADA[0]
  }
})(Form);

export default CrearEntradaForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./login.css";
import { translator as t } from "traducer";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";
import {logo_blanco_en, logo_blanco_es} from "utility/variables";


class Registro extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    recuperar: PropTypes.func.isRequired,
    idioma: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);    
    this.cerrar = this.cerrar.bind(this);
  }

  cerrar(){
    this.props.setModal(false);
  }

  componentWillMount() {
  }

  render() {

    const { idioma, loading } = this.props;
    return (
      <div id="tienda-login">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={idioma === "es" ? logo_blanco_es : logo_blanco_en} alt="Agritrade"/>
        </div>
        <div className="d-flex flex-row barra justify-content-center">
          <div className="verde" />
          <div className="naranja" />
          <div className="rojo" />
          <div className="amarillo" />
          <div className="azul" />
        </div>
        <LoginForm onSubmit={this.props.onSubmit} loader={loading} />
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex registrate flex-row justify-content-center align-self-center">
            <hr />
            <div className="mr-1 ml-1 pt-2">
              <p>{t.translate("O")}</p>
            </div>
            <hr />            
          </div>
          <div className="justify-content-center align-self-center">
            <Link to="/tienda/registro" className="enlace" onClick={() => this.cerrar()}>{t.translate("Registrarte")}</Link>
          </div>
          <small style={{color:'white'}} className="justify-content-center align-self-center mb-3">{t.translate("Crea tu cuenta para la aplicación Agritrade")}</small>
          <div className="registrate">
            <hr />
          </div>
          <div className="d-flex justify-content-center registrate">
            <small>{t.translate("¿Olvidaste tu contraseña?")} <a className="enlace pointer" onClick={this.props.recuperar}>{t.translate("Recuperar")}</a></small>
          </div>
        </div>
      </div>
    );
  }
}

export default Registro;

import { handleActions } from 'redux-actions';
import { api } from "api";
import { push } from "react-router-redux";
import {initialize as initializeForm} from 'redux-form';
import {ToastStore} from "react-toasts";
import {TIPOS_ENTRADA, UN_DIA} from "../../../utility/variables";
import _ from "lodash";

const DATA = 'PROUDCTOS_DATA';
const PAGE = 'PROUDCTOS_PAGE';
const SEARCH = 'PROUDCTOS_SEARCH';
const LOADING = 'PROUDCTOS_LOADING';
const PRODUCTO = 'PRODUCTOS_PRODUCTO';

export const constants = {
};

// ------------------------------------
// Actions
// ------------------------------------

export const getProductos = (page=1) => (dispatch,getStore) => {
  dispatch(setLoading(true));
  const search = getStore().productos.search;
  api.get('/producto', {page,search}).then((data) => {
    dispatch(setData(data));
    dispatch(setPage(page));
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const getProducto = (id) => (dispatch) => {
  dispatch(setLoading(true));
  api.get(`/producto/${id}`).then((data) => {
    data.tipo_entrada = _.find(TIPOS_ENTRADA, {id: data.tipo_entrada});
    dispatch(initializeForm('EdicionProducto', data));    
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const verProducto = (id) => (dispatch) => {
  dispatch(setLoading(true));
  api.get(`/producto/${id}`).then((data) => {
    dispatch(setProducto(data));
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const searchChange = search => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getProductos());
};

export const editar = (producto, id, foto) => (dispatch) => {
  dispatch(setLoading(true));
  if (producto.es_entrada && !producto.tipo_entrada) {
    producto.tipo_entrada = UN_DIA;
  } else {
    if(producto.es_entrada){    
      producto.tipo_entrada = producto.tipo_entrada.value;
    }
  }
  api.putAttachments(`/producto/${id}`, producto, [foto]).then(() => {
    ToastStore.success('Datos almacenados');
    dispatch(push("/administrar_productos"));
  }).catch((e) => {
    ToastStore.error('Ha ocurrido un error en la petición');
    Object.keys(e).forEach((key) => {
      e[key].forEach(problema => {
        ToastStore.error(problema);
      })
    });
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const sincronizar = () => (dispatch) => {
  dispatch(setLoading(true));
  api.get('producto/sincronizar').then(() => {
    dispatch(getProductos());
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const setLoading = load => (dispatch, getStore) => {
  const loading = getStore().productos.loading;
  if (load) {
    dispatch({type: LOADING, loading: loading + 1 });
  } else {
    dispatch({type: LOADING, loading: loading - 1 });
  }
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setSearch = search => ({
  type: SEARCH,
  search,
});

export const setData = data => ({
  type: DATA,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

export const setProducto = producto => ({
  type: PRODUCTO,
  producto,
});

export const actions = {
  getProductos,
  editar,
  sincronizar,
  searchChange,
  getProducto,
  verProducto,
};

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [PRODUCTO]: (state, { producto }) => {
    return {
      ...state,
      producto,
    };
  },
};

export const initialState = {
  data: {
  },
  producto: {
    precios: []
  },
  loading: 0,
  search: '',
  page: 1,
};

export default handleActions(reducers, initialState);

import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/tienda/cuenta';
import EdicionEntrada from './EdicionEntrada';
import EntradaForm from "./EdicionEntradaForm";


const ms2p = (state) => {
  let entrada = {tipo_entrada: {}};
  try {
    if (state.form.EntradaForm.values.tipo_entrada)
      entrada = state.form.EntradaForm.values;
    if (!!state.cuenta.entrada.qr)
      entrada.qr = state.cuenta.entrada.qr;
  } catch (e) {

  }

  return {
    ...state.cuenta,
    entrada,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EdicionEntrada);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Modal from 'react-responsive-modal';
import { DISPONIBLE, RESERVADO, STANDS, ESTILOS_SELECT_STANDS } from "variables";
import Select from 'react-select';
import EdicionStand from "./Edicion";
import "./stands.css";
import classNames from "classnames";
import {COMPRADO} from "../../../../utility/variables";


class Stands extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    idioma: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {open: false, stand:null, filtro: {}};
    this.closeModal = this.closeModal.bind(this);
    this.editar = this.editar.bind(this);
    this.getEmpresa = this.getEmpresa.bind(this);
  }

  componentWillMount() {
    this.props.getStands();
    this.props.getEmpresas();
  }

  editar(stand) {
    this.props.editar(stand);
    this.setState({stand: stand.id, open:true, enlace: stand.codigo});
  }

  closeModal(data) {
    if (data) {
      this.props.updateStand({...data}, this.state.stand);
    }
    this.setState({open: false});
  }
  getEmpresa(id) {
    if (!id) {
      return "-------";
    }
    const empresas = this.props.empresas;
    const empresa = _.find(empresas, {Id: parseFloat(id)});
    if (empresa) {
      return empresa.Nombre;
    }
    return <div className="text-danger">{id}</div>;
  }

  render() {
    const { nuevaEmpresa, loading, data, search, empresas } = this.props;
    let { results } = data;

    const filtros = [
      {label: "Todos", value: undefined},
      {label: "Comprados", value: COMPRADO},
      {label: "Reservados", value: RESERVADO},
      {label: "Disponibles", value: DISPONIBLE},
    ];
    if (this.state.filtro.value !== undefined)
      results = _.filter(results, {estado: this.state.filtro.value});

    const options = {
      hideSizePerPage: true,
      noDataText: 'No hay registros',
    };
    options.noDataText = loading ? "Cargando..." : options.noDataText;
    return (
      <div id="administracion">

        <div className="cuerpo">
          <div className="pt-5 pb-5 pl-1 pr-1 p-sm-5">
            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={this.state.open} onClose={this.closeModal} classNames={{modal:"modal-lg p-0"}} >
              <div className="">
                <div className="grid-titulo">Edición de Stand No. {this.state.stand}</div>
                <EdicionStand
                  nuevaEmpresa={nuevaEmpresa}
                  styles={{modal: {minWidth: "100%"}}}
                  empresas={empresas}
                  espacio={this.state.stand}
                  enlace={this.state.enlace}
                  onSubmit={this.closeModal}
                  close={() => this.closeModal()}
                />
              </div>
            </Modal>
            <div className="buscador d-flex flex-column flex-sm-row justify-content-between p-3 encabezado-azul">
              <div  className="d-flex align-items-center mr-2">
                <h2 className="d-none d-sm-inline-block m-0">Stands</h2>
                <h4 className="d-inline-block d-sm-none m-0">Stands</h4>
              </div>
              <div style={{minWidth: "150px", color: "#505050 !important"}}>
                <Select
                        options={filtros}
                        styles = {ESTILOS_SELECT_STANDS}
                        value={this.state.filtro}
                        onChange={(e) => {
                          this.setState({filtro: e});
                        }}
                        placeholder={"Filtrar"}
                >
                </Select>
              </div>
              <div className="d-flex flex-column flex-sm-row">
                <div className="toolbar-search">
                  <input className="form-control" type="text" placeholder="Buscar"
                         ref={node => {
                           this.buscar = node;
                           if (this.buscar) {
                             this.buscar.value = search;
                           }
                         }}
                         onChange={() => {
                           if (this.buscar) {
                             this.props.searchChange(this.buscar.value);
                           }
                         }}
                         autoComplete="off"
                  />
                  <span className="fa fa-search icon-search" />
                </div>
              </div>
            </div>
            <BootstrapTable
              data={loading ? [] : results}
              remote
              hover
              fetchInfo={{ dataTotalSize: data.count }}
              options={options}
            >
              <TableHeaderColumn
                dataAlign="center"
                dataField="id"
                isKey
              >
                No. Stand
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                dataFormat={(cell) => {
                  const stand = _.find(STANDS, {espacio: cell});
                  if (stand) {
                    return <div>{stand.area} metros</div>
                  }
                }}
              >
                Medida
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="empresa"
                dataFormat={(cell) => {return this.getEmpresa(cell)}}
              >
                Empresa
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="estado"
                dataFormat={(cell) => {
                  if (cell === DISPONIBLE) {
                    return <div className="d-flex align-items-center"><span className="disponible" />&nbsp;Disponible</div>
                  } else if (cell === RESERVADO) {
                    return <div className="d-flex align-items-center"><span className="reservado" />&nbsp;Reservado</div>
                  }
                  return <div className="d-flex align-items-center"><span className="comprado" />&nbsp;Comprado</div>
                }}
              >
                Estado
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                dataFormat={(cell, row) => {
                  return <img className="action-img" title="Editar" src={require("assets/img/editar.png")} onClick={()=> {
                    this.editar(row);
                  }} alt="Editar"/>
                }}
              >
                Acciones
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}

export default Stands;

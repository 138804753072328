import { handleActions } from 'redux-actions';
import _ from "lodash";
import { api } from "api";
import {ToastStore} from 'react-toasts';
import {initialize as initializeForm} from 'redux-form';
import {COMPRADO, DISPONIBLE, RESERVADO, STANDS} from "variables";
import { translator } from "../../../utility/traducer";


const DATA = 'PLANTA_DATA';
const IDIOMA = 'PLANTA_IDIOMA';
const LOADER = 'PLANTA_LOADER';
const LOADER_STAND = 'PLANTA_LOADER_STAND';
const PLANTAS = 'PLANTA_PLANTAS';
const SELECCIONADO = 'PLANTA_SELECCIONADO';
const DISPONIBLES = 'PLANTA_DISPONIBLES';
const COMPRADOS = 'PLANTA_COMPRADOS';
const RESERVADOS = 'PLANTA_RESERVADOS';
const FILTRO = 'PLANTA_FILTRO';
const PLANTA = 'PLANTA_PLANTA';
const EMPRESAS = 'PLANTA_EMPRESAS';

export const constants = {
  IDIOMA,
};

// ------------------------------------
// Actions
// ------------------------------------

export const getEmpresas = () => (dispatch) => {
  dispatch(setLoaderStand(true));  
  api.get('data/empresas').then((data) => {
    dispatch(setEmpresas(data));
    dispatch(verStand(1));
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoaderStand(false));    
  })
};

export const getStands = () => (dispatch) => {
  dispatch(setLoader(true));
  api.get('data').then((data) => {
    dispatch(setPlantas(data.results));
    dispatch(getEmpresas());
    const disponibles  = _.filter(data.results, {estado: DISPONIBLE}).length;
    dispatch(setDisponibles(disponibles));
    const reservados = _.filter(data.results, {estado: RESERVADO}).length;
    dispatch(setReservados(reservados));
    const comprados= _.filter(data.results, {estado: COMPRADO}).length;
    dispatch(setComprados(comprados));    
  }).catch(e => {

  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const envioContacto = data => (dispatch) => {
  api.post("/data/correo", data)
    .then(() => {
      ToastStore.success("Solicitud de reserva enviada");
    })
    .catch(() => {

    })
    .finally(() => {

    })
};

export const cargaStand = () => (dispatch, getStore) => {
  const no_stand = getStore().planta.seleccionado;
  dispatch(initializeForm('Contacto', {no_stand}));
};

export const verStand = (id) => (dispatch, getStore) => {
  dispatch(setLoaderStand(true));
  const planta = getStore().planta;
  const { plantas, empresas } = planta;
  const espacio = _.cloneDeep(_.find(plantas, {id}));
  const data = _.find(STANDS, {espacio: id});
  if (data && espacio) {
    const empresa = _.find(empresas, {Id: parseFloat(espacio.empresa)});
    const vecinos = [];
    if (espacio.vecinos) {
      const vecinos_s = JSON.parse(espacio.vecinos);
      vecinos_s.forEach(vecino => {
        const temp = _.find(plantas, {id: vecino});
        if (temp){
          const v = _.find(empresas, {Id: parseFloat(temp.empresa)});
          if (v) {
            temp.img = `http://appconnecting.export.com.gt/InterconexionCbm/api/Empresa/Imagen/${v.Id}`;
          }
          vecinos.push(temp);
        }
      });
    }
    espacio.area = data.area;
    espacio.vecinos = vecinos;
    espacio.espacio = espacio.id;    
    if (empresa) {
      // peticion a backend de la imagen
      // empresa.Id
      espacio.ocupante = empresa
      api.get('data/imagen', {empresa: empresa.Id}).then((respuesta) => {
        espacio.ocupante.tiene_imagen = respuesta.tiene_imagen; 
        espacio.ocupante.url = respuesta.url;               
      }).catch((e) => {

      }).finally(() => {
        dispatch(setSeleccionado(id));
        dispatch(setLoaderStand(false));
        dispatch(setData(espacio));
      });
      
    } else {
      dispatch(setSeleccionado(id));
      dispatch(setLoaderStand(false));
      dispatch(setData(espacio));
    }
  } else {
    dispatch(setLoaderStand(false));
  }
};

export const standAnterior = (numero) => (dispatch) => {
  if (numero === 1) {
    numero = 185;
    dispatch(setPlanta(2));
  } else if (numero === 107)
    numero = 106;
  else if (numero === 78)
    numero = 77;
  else if (numero === 81)
    dispatch(setPlanta(1));
  dispatch(verStand(numero - 1));
};

export const standSiguiente = (numero) => (dispatch) => {
  if (numero === 184) {
    numero = 0;
    dispatch(setPlanta(1));
  } else if (numero === 105)
    numero = 107;
  else if (numero === 76)
    numero = 77;
  else if (numero === 80)
    dispatch(setPlanta(2));
  dispatch(verStand(numero + 1));
};

export const setIdioma = (codigo) => (dispatch) => {
  translator.useLanguage(codigo);
  let refrescar = false;
  if (localStorage.getItem('lang') !== codigo)
    refrescar = true;
  localStorage.setItem('lang', codigo);
  if (codigo === "es") {
    dispatch(_setIdioma({
      img: "España",
      nombre: "ESPAÑOL",
      codigo,
    }));
  } else {
    dispatch(_setIdioma({
      img: "Usa",
      nombre: "ENGLISH",
      codigo,
    }));
  }
  if (refrescar)
    location.reload();
};

export const setPlanta = (planta) => (dispatch) => {
  dispatch({type: PLANTA, planta});
  if (planta === 1) {
    dispatch(verStand(1));
  } else {
    dispatch(verStand(81));
  }
};

export const filtrar = (filtro) => (dispatch, getStore) => {
  if (filtro === "todos") {
    dispatch({type: FILTRO, filtros: [
    "todos",
    "2x2",
    "4x2",
    "3x2",
    "3x3",
    "3x1.5",
    "5x4",
    "4x3",
  ]});
  } else {
    const filtros = [filtro];
    dispatch({type: FILTRO, filtros});
  }
};

export const setLoaderStand = load => (dispatch, getStore) => {
  const loaderStand = getStore().planta.loaderStand;
  if (load) {
    dispatch({type: LOADER_STAND, loaderStand: loaderStand + 1 });
  } else {
    dispatch({type: LOADER_STAND, loaderStand: loaderStand - 1 });
  }
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setEmpresas = empresas => ({
  type: EMPRESAS,
  empresas,
});

export const setData = data => ({
  type: DATA,
  data,
});

export const setLoader = loader => ({
  type: LOADER,
  loader,
});


export const setSeleccionado = seleccionado => ({
  type: SELECCIONADO,
  seleccionado,
});

export const setComprados = comprados => ({
  type: COMPRADOS,
  comprados,
});

export const setDisponibles = disponibles => ({
  type: DISPONIBLES,
  disponibles,
});

export const setReservados = reservados => ({
  type: RESERVADOS,
  reservados,
});

export const setPlantas = plantas => ({
  type: PLANTAS,
  plantas,
});

export const _setIdioma = idioma => ({
  type: IDIOMA,
  idioma,
});

export const actions = {
  setData,
  envioContacto,
  setIdioma,
  cargaStand,
  standAnterior,
  standSiguiente,
  setPlanta,
  filtrar,
  getStands,
  verStand,
  getEmpresas,  
};

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [IDIOMA]: (state, { idioma }) => {
    return {
      ...state,
      idioma,
    };
  },
  [PLANTAS]: (state, { plantas }) => {
    return {
      ...state,
      plantas,
    };
  },
  [PLANTA]: (state, { planta }) => {
    return {
      ...state,
      planta,
    };
  },
  [SELECCIONADO]: (state, { seleccionado }) => {
    return {
      ...state,
      seleccionado,
    };
  },
  [DISPONIBLES]: (state, { disponibles }) => {
    return {
      ...state,
      disponibles,
    };
  },
  [RESERVADOS]: (state, { reservados }) => {
    return {
      ...state,
      reservados,
    };
  },
  [COMPRADOS]: (state, { comprados }) => {
    return {
      ...state,
      comprados,
    };
  },
  [FILTRO]: (state, { filtros }) => {
    return {
      ...state,
      filtros,
    };
  },
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [LOADER_STAND]: (state, { loaderStand }) => {
    return {
      ...state,
      loaderStand,
    };
  },
  [EMPRESAS]: (state, { empresas }) => {
    return {
      ...state,
      empresas,
    };
  },

};

export const initialState = {
  data: {
    estado: DISPONIBLE,
    vecinos: [
      {
        img: "https://agropartner.cl/wp-content/uploads/2017/07/85.png",
        nombre: "agropartner",
        id: 90,
      },
      {
        img: "https://agropartner.cl/wp-content/uploads/2017/07/85.png",
        nombre: "agropartner",
        id: 89,
      },
      {
        img: "https://agropartner.cl/wp-content/uploads/2017/07/85.png",
        nombre: "agropartner",
        id: 74,
      },
      {
        img: "https://agropartner.cl/wp-content/uploads/2017/07/85.png",
        nombre: "agropartner",
        id: 75,
      },
    ],
    espacio: 1,
    area: "3x3 mt2",
    precio: 50000,
    ocupante: {
      img: "https://agropartner.cl/wp-content/uploads/2017/07/85.png",
      sitio: "https://agroparter.cl",
      slogan: "Agropartner, innovación en la gestión de agroinsumos",
    }
  },
  idioma: {
    img: "España",
    nombre: "ESPAÑOL",
    codigo: "es",
  },
  plantas: [
    {
      nombre : "Planta 1",
      stands: [

      ]
    },
    {
      nombre : "Planta 2",
      stands: [

      ]
    },
  ],
  filtros: [
    "todos",
    "2x2",
    "4x2",
    "3x2",
    "3x3",
    "3x1.5",
    "5x4",
    "4x3",
  ],
  seleccionado: 1,
  comprados: 0,
  reservados: 0,
  disponibles: 0,
  loader: false,  
  loaderStand: 0,
  planta: 1,
  empresas: [],
};

export default handleActions(reducers, initialState);

import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

// maquetado base
import { Header, Footer } from "./common/components/Tienda";

// funciona para determinar si puede acceder a la vista
function puedeEntrar() {
  return true;
}

export class Tienda extends Component {
  render () {
    return (
      <Redirect
        to={{pathname: "/tienda/inicio", state: { from: this.props.location }}}
       />
    )
  }
}

class TiendaRoute extends Component {
  render() {
    const { component: Component, loading_global, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => (puedeEntrar() ? (
          <div>
            <Header />
            <div className="content-wrapper d-flex flex-column">
              <Component {...props} />
              <Footer />
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ))
                }
      />
    );
  }
}

const mstp = state => ({loading: state.usuario.loading_global});

const mdtp = {};

const ProtectedRoute = connect(
    mstp,
    mdtp
)(TiendaRoute);

export default ProtectedRoute;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";
import "./genericos.css";
import {logo_en, logo_es} from "utility/variables";
import swal from 'sweetalert2';

const genericos = {background:"#FFFFFF"}

class Genericos extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);    
    this.ventanaCarrito = this.ventanaCarrito.bind(this);
  }

  componentWillMount() {
    this.props.getGenericos();
  }

  ventanaCarrito(){
    swal.fire({
      title: t.translate('Agregado al carrito'),
      type: 'info',
      html:
        t.translate('El producto se ha agregado')+
        '<br />'+t.translate('al carrito  exitosamente'),
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<a style="color: white"href="/#/tienda/carrito">'+t.translate('Ir al Carrito')+'</a>',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        t.translate('Seguir comprando'),
      cancelButtonAriaLabel: 'Thumbs down',
    })
  }

  render() {
    const { data, idioma, usuario } = this.props;
    const logo = idioma === "es" ? logo_es : logo_en;

    return (
      <div id="genericos">
        <div className="difuminado" />
        <div className="w-100 pt-4 pb-4 pl-2 pr-2 p-sm-5 d-flex d-sm-none flex-wrap justify-content-around position-relative">
          {data.results.map((entrada, index) => {            
            const precios = [];
            const precios_socios = [];
            entrada.precios.forEach((precio) => {
              if (precio.lista_precios.toLowerCase().includes("no"))
                precios.push(precio);
              else
                precios_socios.push(precio);
            });
            return (<div style={genericos}key={entrada.id} className="d-flex entrada-small flex-column ml-3 mr-3 mt-3 principal mb-3">
              <div className="align-self-center mb-3">
                <img src={entrada.foto} onError={(e)=>{e.target.onerror = null; e.target.src=logo}} alt=""/>
              </div>
              <div className="d-flex flex-row barra justify-content-center">
                <div className="verde" />
                <div className="naranja" />
                <div className="rojo" />
                <div className="amarillo" />
                <div className="azul" />
              </div>
              <div className="d-flex p-2 flex-column align-items-center mt-3 mb-3 h-100">
                <center>
                  <h5><strong>{idioma === "es" ? entrada.texto_comercial : entrada.texto_comercial_ingles}</strong></h5>
                </center>
                <small className="mt-3 mb-5"style={{lineHeight:"14px", maxHeight: "112px", overflow: "hidden"}}>{idioma === "es" ? entrada.descripcion : entrada.descripcion_ingles}</small>
                <div className="d-flex flex-row flex-wrap precios-entrada mt-auto mb-3">
                  {usuario.es_socio ? precios_socios.map((precio, index) => {
                    let clase = "pr-2";
                    if (index !== (precios.length - 1)) {
                      clase = clase + " precio-entrada";
                    }
                    if (index > 0) {
                      clase = clase + " pl-2 precio-entrada-fin"
                    }
                    return (
                      <h3 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h3>
                    )
                  }) : precios.map((precio, index) => {
                    let clase = "pr-2";
                    if (index !== (precios.length - 1)) {
                      clase = clase + " pl-2 precio-entrada";
                    }
                    if (index > 0) {
                      clase = clase + " pl-2 precio-entrada-fin"
                    }
                    return (
                      <h3 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h3>
                    )
                  })}
                </div>
                <button className="btn btn-default mt-2" onClick={() => {
                  this.props.agregarCarrito(entrada)
                }}>{t.translate("Agregar al carrito")}</button>
              </div>
            </div>)
          })}
        </div>
        <div className="w-100 pt-4 pb-4 pl-2 pr-2 p-sm-5 d-none d-sm-flex flex-wrap justify-content-around position-relative">
          {data.results.map((entrada, index) => {
            const precios = [];
            const precios_socios = [];
            entrada.precios.forEach((precio) => {
              if (precio.lista_precios.toLowerCase().includes("no"))
                precios.push(precio);
              else
                precios_socios.push(precio);
            });
            return (
              <div style={genericos} key={entrada.id} className="entrada d-flex flex-column flex-sm-row m-3 m-sm-3">
                <div className="align-self-center">                  
                  <img src={entrada.foto} onError={(e)=>{e.target.onerror = null; e.target.src=logo}} alt=""/>
                </div>
                <div className="barra d-flex flex-column justify-content-center">
                  <div className="azul" />
                  <div className="amarillo" />
                  <div className="rojo" />
                  <div className="naranja" />
                  <div className="verde" />
                </div>
                <div className="d-flex p-2 flex-column align-self-stretch ">
                  <div><strong>{idioma === "es" ? entrada.texto_comercial : entrada.texto_comercial_ingles}</strong></div>
                  <small className="align-items-stretch" style={{lineHeight:"14px"}}>{idioma === "es" ? entrada.descripcion : entrada.descripcion_ingles}</small>
                  <div className="d-flex flex-row flex-wrap precios-entrada mt-3 mb-3">
                    {usuario.es_socio ? precios_socios.map((precio, index) => {
                      let clase = "pr-2";
                      if (index !== (precios.length - 1)) {
                        clase = clase + " precio-entrada";
                      }
                      if (index > 0) {
                        clase = clase + " pl-2 precio-entrada-fin"
                      }
                      return (
                        <h4 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h4>
                      )
                    }) : precios.map((precio, index) => {
                      let clase = "pr-2";
                      if (index !== (precios.length - 1)) {
                        clase = clase + " precio-entrada";
                      }
                      if (index > 0) {
                        clase = clase + " pl-2 precio-entrada-fin"
                      }
                      return (
                        <h4 className={clase} key={precio.id}><RenderCurrency className="font-weight-bold" value={precio.importe} prefix={`${precio.codigo_moneda === "USD" ? "$" : "Q"} `} /></h4>
                      )
                    })}
                  </div>
                  <button className="btn btn-default align-self-start mt-auto" onClick={() => {
                    this.props.agregarCarrito(entrada);
                    this.ventanaCarrito();
                  }}>{t.translate("Agregar al carrito")}</button>
                </div>
              </div>
            )
          })}
        </div>
        <img className="verduras" src={require("assets/img/marco_verduras.png")} alt=""/>
      </div>
    );
  }
}

export default Genericos;

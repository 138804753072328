import React, { Component } from 'react';
import PropTypes from 'prop-types';
import panzoom from "panzoom";
import SVG2 from "./SVG2";
import "./planta.css";
import {COMPRADO, DISPONIBLE, RESERVADO} from "variables";

class Planta2 extends Component {
  static propTypes = {
    data: PropTypes.any.isRequired,
    verStand: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {height: window.innerHeight, width: window.innerWidth};
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    const scene = document.getElementById('scene');
    const planta = document.getElementById('svg-parent');
    const contenedor = document.getElementById('contenedor-planta');
    let zoom;
    let z;
    let x;
    let y;
    let bottom;
    let right;
    if (this.state.width >= 992) {
      z = (this.state.height * 0.62) / 780;
      x = 20;
      y = -1400;
      bottom = planta.clientHeight - 20;
      right = contenedor.clientWidth - 20;
      zoom = 5
    }
    else if (this.state.width >= 576) {
      z = (this.state.height * 0.5) / 780;
      x = 144;
      y = -250;
      bottom = this.state.height - 20;
      right = this.state.width - 20;
      zoom = 5
    }
    else {
      z = 0.3;
      x = 0;
      y = -200;
      bottom = this.state.height - 20;
      right = this.state.width - 20;
      zoom = 7
    }
    panzoom(scene,
      {
        maxZoom: zoom,
        minZoom: 0.2,
        zoomDoubleClickSpeed: 1,
        bounds: {
          top: 20,
          right,
          bottom,
          left: 20,
        },
        boundsDisabledForZoom: true
      }
    ).zoomAbs(
      x, y, z
    );
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  updateDimensions() {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
  }

  render() {
    return (
      <div id="planta2">
        <svg id="svg-parent" width="100%" height="100%">
          <g id={"scene"}>
            <SVG2 {...this.props} />
          </g>
        </svg>
      </div>
    );
  }
}

export default Planta2;

import React from 'react';
import Select, { AsyncCreatable, Async } from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import FileUploader from '../FileUploader/FileUploader';


export const renderField = ({
                              input, disabled, label, type, meta: { touched, error }, className,
                            }) => {
  const invalid = touched && error;
  return (
    <div>
      <input
        {...input}
        disabled={disabled}
        placeholder={label}
        type={type}
        className={classNames(!!className ? className : "form-control", { 'is-invalid': invalid })}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderTextArea = ({
                                 input, label, rows, meta: { touched, error },
                               }) => {
  const invalid = touched && error;
  return (
    <div>
      <textarea
        {...input}
        placeholder={label}
        style={{ resize: 'none' }}
        rows={rows || 3}
        className={classNames('form-control', { 'is-invalid': invalid })}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};
export const renderSearchSelect = ({
                                     input, disabled, loadOptions, valueKey, labelKey, meta: { touched, error },
                                   }) => {
  const invalid = touched && error;
  return (
    <div>
      <Async
        disabled={disabled}
        defaultOptions
        isClearable
        value={input.value}
        className={classNames('', { 'is-invalid': invalid })}
        onChange={(e) => { input.onChange(e); }}
        placeholder="Escriba para buscar"
        loadOptions={loadOptions}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
          {input.value}
        </div>
      )}
    </div>
  );
};

export const renderSearchCreateSelect = ({
                                           input, createLabel, placeholder, disabled, options, valueKey, labelKey, meta: { touched, error },
                                         }) => {
  const invalid = touched && error;
  options.forEach(option => {
    option.value = option[valueKey].toString();
    option.label = option[labelKey].toString();
  });
  return (
    <div>
      <Creatable
        className={classNames('search-select', { 'is-invalid': invalid })}
        formatCreateLabel={(inputValue) => {
          return `${createLabel} "${inputValue}"`
        }}
        isClearable
        options={options}
        onChange={(e) => {
          input.onChange(e);
        }}
        placeholder={placeholder}
        value={input.value}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
          {input.value}
        </div>
      )}
    </div>
  );
};
export const renderSelectField = ({ valueKey, styles, placeholder, labelKey, input, disabled, options, meta: { touched, error }, }) => {
  const invalid = touched && error;
  return (
    <div>
      <Select isDisabled={disabled} className={classNames('', { 'is-invalid': invalid })}
              options={options}
              value={input.value}
              styles={styles}              
              getOptionValue={(option) => option[valueKey]?option[valueKey]:option.value}
              getOptionLabel={(option) => option[labelKey]?option[labelKey]:option.label}
              onChange={(e) => {
                input.onChange(e);
              }}
              placeholder={placeholder}
              >
      </Select>
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

// export const renderSelectField = ({ input, disabled, options, meta: { touched, error }, }) => {
//   const invalid = touched && error;
//   return (
//     <div>
//       <select {...input} disabled={disabled} className={classNames('form-control', { 'is-invalid': invalid })}>
//         {options.map((opcion) => {
//           return (
//             <option
//               key={typeof (opcion) === 'string' ? opcion : opcion.id}
//               value={typeof (opcion) === 'string' ? opcion : opcion.value}
//             >
//               {typeof (opcion) === 'string' ? opcion : opcion.label}
//             </option>
//           );
//         })}
//       </select>
//       {invalid && (
//         <div className="invalid-feedback">
//           {error}
//         </div>
//       )}
//     </div>
//   );
// };

export const multiSelect = ({ valueKey, labelKey, input, disabled, options, meta: { touched, error }, }) => {
  const invalid = touched && error;
  return (
    <div>
      <Select disabled={disabled} className={classNames('', { 'is-invalid': invalid })}
              options={options}
              value={input.value}
              getOptionValue={(option) => option[valueKey]}
              getOptionLabel={(option) => option[labelKey]}
              onChange={(e) => {
                input.onChange(e);
              }}
              isMulti
              placeholder="buscar">
      </Select>
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderNumber = ({
                               input, decimalScale, meta: { touched, error },
                             }) => {
  const invalid = touched && error;
  return (
    <div>
      <NumberFormat
        className={classNames('form-control', { 'is-invalid': invalid })}
        decimalScale={decimalScale || 0}
        fixedDecimalScale
        value={input.value}
        thousandSeparator
        prefix=""
        onValueChange={(values) => {
          input.onChange(values.value);
        }}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderCardNumber = ({
                               input, meta: { touched, error },
                             }) => {
  const invalid = touched && error;
  return (
    <div>
      <NumberFormat
        format="#### #### #### ####"
        className={classNames('form-control', { 'is-invalid': invalid })}
        decimalScale={0}
        fixedDecimalScale
        allowNegative={false}
        thousandSeparator=""
        value={input.value}
        prefix=""
        onValueChange={(values) => {
          input.onChange(values.value);
        }}
          />
          {invalid && (
            <div className="invalid-feedback">
              {error}
            </div>
          )}
        </div>
      );
    };



    
export const renderCcv = ({
  input, decimalScale, meta: { touched, error },
}) => {
const invalid = touched && error;
return (
<div>
<NumberFormat
className={classNames('form-control', { 'is-invalid': invalid })}
decimalScale={decimalScale || 0}
fixedDecimalScale
format="###"
value={input.value}
thousandSeparator
prefix=""
onValueChange={(values) => {
input.onChange(values.value);
}}
/>
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderCurrency = ({
                                 input, meta: { touched, error }, prefix="Q "
                               }) => {
  const invalid = touched && error;
  return (
    <div>
      <NumberFormat
        className={classNames('form-control', { 'is-invalid': invalid })}
        decimalScale={2}
        fixedDecimalScale
        value={input.value}
        thousandSeparator
        prefix={prefix}
        onValueChange={(values) => {
          input.onChange(values.value);
        }}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderSwitch = ({
                               input, meta: { touched, error },
                             }) => {
  const invalid = touched && error;
  return (
    <div>
      <Switch
        onChange={(value) => {
          input.onChange(value);
        }}
        checked={input.value ? input.value : false}
        id="normal-switch"
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderImagePicker = ({photo, setFile, className, placeholder, disabled, input, label, meta: { touched, error } }) => {
  const invalid = touched && error;
  return (
    <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
      <FileUploader
        disabled={disabled}
        img= {!!photo ? photo : null}
        onFileChange={(e, file) => {
          file = file || e.target.files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            input.onChange(reader.result);
            if (!!setFile) {
              setFile(file);
            }
          };

          reader.readAsDataURL(file);
        }} />
      {invalid && <div className="invalid-feedback">
        {error}
      </div>}
    </div>
  )
};

export const RenderField = {
  renderField,
  renderTextArea,
  renderSearchSelect,
  renderSearchCreateSelect,
  renderSelectField,
  renderNumber,
  renderCurrency,
  renderSwitch,
};

import { handleActions } from 'redux-actions';
import { api } from "api";
import { translator as t} from "traducer";
import _ from "lodash";
import {initialize as initializeForm, change} from "redux-form";
import {ToastStore} from "react-toasts";
import { push } from "react-router-redux";
import {buscarOportunidad, setCarrito} from "./tienda";
import { actions as printActions } from "Utils/Print";
import {TIPOS_ENTRADA, UN_DIA} from "../../../utility/variables";
import swal from 'sweetalert2';
import { PAISES } from 'variables';


const LOADING = 'CUENTA_LOADING';
const LOADING_ENTRADAS = 'CUENTA_LOADING_ENTRADAS';
const LOADING_RECUPERACION = 'CUENTA_LOADING_RECUPERACION';
const LOADING_GLOBAL = 'CUENTA_LOADING_GLOBAL';
const USUARIO = 'CUENTA_USUARIO';
const MODAL = 'CUENTA_MODAL';
const ENTRADAS = 'CUENTA_ENTRADAS';
const ENTRADA = 'CUENTA_ENTRADA';
const PRODUCTOS = 'CUENTA_PRODUCTOS';
const COMPRAS = 'CUENTA_COMPRAS';
const COMPRA = 'CUENTA_COMPRA';
const PAGE_PERFIL = "CUENTA_PAGE_PERFIL";
const PAGE_ENTRADAS = 'CUENTA_PAGE_ENTRADAS';
const PAGE_PRODUCTOS = 'CUENTA_PAGE_PRODUCTOS';
const PAGE_COMPRAS = 'CUENTA_PAGE_COMPRAS';
const ACCION_MODAL = 'CUENTA_ACCION_MODAL';
const FILTRO = 'CUENTA_FILTRO';
const TAB = 'CUENTA_TAB';
const ARCHIVO = 'CUENTA_ARCHIVO';
const PRODUCTOS_GENERICOS = 'CUENTA_PRODUCTOS_GENERCIOS';

export const constants = {
  ENTRADA
};

// ------------------------------------
// Actions
// ------------------------------------

export const setLoading = load => (dispatch, getStore) => {
  const loading = getStore().cuenta.loading;
  if (load) {
    dispatch({type: LOADING, loading: loading + 1 });
  } else {
    if (loading > 0)
      dispatch({type: LOADING, loading: loading - 1 });
  }
};

export const inicializarRegistro = () => (dispatch) => {
  dispatch(initializeForm("TiendaRegistro", {codigo_postal: "01001", pais: {value:'GT', id:	'GT', label:	'Guatemala'}}))
};

export const registrar = (data) => (dispatch) => {
  dispatch(setLoading(true));
  const body = {
    username: data.username,
    email: data.username,
    first_name: data.first_name,
    last_name: data.last_name,
    password: data.password,
    perfil: {
      nit: data.nit,
      nombre: data.first_name,
      direccion: data.direccion,
      telefono: data.telefono,
      ciudad: data.ciudad,
      codigo_postal: data.codigo_postal,
      pais: data.pais.value,
      razon_fiscal: data.razon_fiscal
    },
  };
  api.post("cliente", body).then(() => {
    ToastStore.success(t.translate('Registro exitoso'));
    dispatch(login({password: data.password,username: data.username}));    
  }).catch((err) => {
    Object.keys(err).forEach((llave) => {
      ToastStore.error(t.translate(err[llave]));
    })
  }).finally(() =>{
    dispatch(setLoading(false));
  })
};

export const login = (body) => (dispatch, getStore) => {
  dispatch(setLoading(true));
  api.post('token', body).then((response) => {
    const store = getStore();
    localStorage.setItem('token', response.token);
    if (store.cuenta.accionModal === "Oportunidad") {
      dispatch(buscarOportunidad(store.form.CodigoOportunidadForm.values));
    }
    dispatch(getMe());
  }).catch((err) => {
    Object.keys(err).forEach((llave) => {
      ToastStore.error(t.translate(err[llave]));
    });
    dispatch(setLoading(false));
  }).finally(() => {
    dispatch(setLoading(false));
  });
};

export const getMe = (global = false) => (dispatch) => {
  if (!!localStorage.getItem('token')) {
    dispatch(setLoading(true));
    if (!!global)
      dispatch(setLoadingGlobal(true));
    api.get('cliente/me').then((usuario) => {
      if (!!localStorage.getItem('sessionCart')) {
        dispatch(setCarrito(JSON.parse(localStorage.getItem('sessionCart'))));
      } else if (!!localStorage.getItem('cart')) {
        dispatch(setCarrito(JSON.parse(localStorage.getItem('cart'))));
      }
      api.get('cliente/es_asociado').then((socio) => {
        dispatch(setUsuario({...usuario, ...socio}));
        usuario.perfil.pais = _.find(PAISES, {id: usuario.perfil.pais});
        dispatch(initializeForm('PerfilForm', {...usuario, ...usuario.perfil, ...socio}))
      }).catch((err) => {
        try {
          if (err.status === 500) {

          } else {
            Object.keys(err).forEach((llave) => {
              ToastStore.error(t.translate(err[llave]));
            });
          }
        } catch (e) {
        }
      }).finally(() => {
        dispatch(setLoading(false));
        dispatch(setLoadingGlobal(false));
      })
    }).catch(() => {
      dispatch(setLoading(false));
      dispatch(setLoadingGlobal(false));
    }).finally(() => {
    })
  } else {
    if (!!localStorage.getItem('cart')) {
      dispatch(setCarrito(JSON.parse(localStorage.getItem('cart'))));
    }
  }
};

export const getEntradas = (page = 1, filtro) => (dispatch) => {
  dispatch(setLoadingEntradas(true));
  const params = {page};
  if (filtro !== undefined)
    params.estado = filtro;
  api.get("entradas", params).then((data) => {
    dispatch(setEntradas(data));
    if (_.filter(data.results, {estado: false}).length > 0) {
      swal(
          t.translate('Entradas pendientes de edición'),
          t.translate('Por favor edite las entradas y luego imprima si es necesario'),
          'info'
        )
    }
    dispatch(setPageEntradas(page));
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  });
};

export const getCompras = (page = 1) => (dispatch) => {
  dispatch(setLoadingEntradas(true));
  api.get('/compras',{ estado: 'AUTHORIZED', page }).then((data) => {
    dispatch(setCompras(data));
    dispatch(setPageCompras(page));
  }).catch(() => {
    
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  });
};

export const getEntrada = (id, codigo) => (dispatch) => {
  dispatch(setLoadingEntradas(true));
  const params = {};
  if (!!codigo) {
    params.codigo = codigo;
  }
  api.get(`entradas/${id}`, params).then((data) => {
    data.tipo_entrada = _.find(TIPOS_ENTRADA, {id: data.tipo_entrada});
    dispatch({type: ENTRADA, entrada: data});
    dispatch(initializeForm('EntradaForm', data));
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  });
};

export const getCompra = (id) => (dispatch) =>{
  dispatch(setLoadingEntradas(true));
  api.get(`compras/${id}`).then((data) => {
    dispatch(setCompra(data))
  }).catch(()=>{    
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  })
}

export const getArchivo = (id) => (dispatch) =>{
  dispatch(setLoadingEntradas(true));
  api.get('archivos/',{ compra: id }).then((data) => {      
    dispatch(setArchivo(data))
  }).catch(()=>{    
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  })
}

export const getZip = (id) => (dispatch,getStore) =>{
  const usuario = getStore().cuenta.usuario.id;
  dispatch(setLoadingEntradas(true));
  api.get('archivos/recibos_zip',{ usuario: usuario,compra: id }).then((data) => {
    const url=data.req.url;
    window.open(url, '_blank')
  }).catch(()=>{    
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  })
}

export const editarEntrada = (id, data, imprimir, lugar) => (dispatch) => {
  dispatch(setLoadingEntradas(true));
  const params = {};
  if (lugar.includes("stand")) {
    params.codigo = lugar.split("stand/")[1].split("/")[0];
  }
  api.put(`entradas/${id}`, {...data}, params).then((response) => {
    dispatch({type: ENTRADA, entrada: response});
    if (imprimir) {
      setTimeout(() => {
        printActions.print('reciboIngreso')
      }, 300);
    } else {
      if (lugar.includes("administrar"))
        dispatch(push("/administrar_entradas"));
      else
        dispatch(push("/tienda/perfil"));
      ToastStore.success("Edición exitosa");
    }
  }).catch(() => {
    swal(
      "ERROR",
      t.translate("Ha ocurrido un error al actualizar la información, por favor espera un momento y vuelve a intentar"),
      "error"
    );
  }).finally(() => {
    dispatch(setLoadingEntradas(false));
  })
};

export const setFiltro = (filtro) => (dispatch) => {
  dispatch({type: FILTRO, filtro});
  dispatch(getEntradas(1 , filtro));
};

export const editar = (data, foto) => (dispatch, getStore) => {
  const { cuenta: {usuario : {id}}} = getStore();
  dispatch(setLoading(true));
  data.perfil.nit = data.nit;
  data.perfil.nombre = data.nombre;
  data.perfil.direccion = data.direccion;
  data.perfil.ciudad = data.ciudad;
  data.perfil.telefono = data.telefono;
  data.perfil.codigo_postal = data.codigo_postal;
  data.perfil.pais = data.pais.value;
  data.perfil.razon_fiscal = data.razon_fiscal;
  api.putAttachments(`/cliente/${id}`, data, [foto]).then(() => {
    ToastStore.success(t.translate('Datos almacenados'));
  }).catch((e) => {
    ToastStore.error(t.translate('Ha ocurrido un error en la petición'));
    Object.keys(e).forEach((key) => {
      e[key].forEach(problema => {
        ToastStore.error(problema);
      })
    });
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const solicitarPass = (data) => (dispatch) => {
  dispatch(setLoadingRecuperacion(true));
  api.post("user/pass_reset_mail", {...data, idioma: localStorage.getItem('lang')}).then(() => {
    swal(
      t.translate("Éxito"),
      t.translate("Se te ha enviado un correo con los siguientes pasos para restablecer tu contraseña"),
      "info"
    )
  }).catch(() => {
    swal(
      "ERROR",
      t.translate('Ha ocurrido un error, verifica que la dirección de correo que has escrito es correcta y sea la asociada a tu cuenta de Agritrade.'),
      'error'
    );
  }).finally(() => {
    dispatch(setLoadingRecuperacion(false));
  })
};

export const restablecerPass = (data) => (dispatch) => {
  // TODO ver si usar otro loader
  dispatch(setLoading(true));
  api.post('user/pass_reset', data).then(() => {
    swal(
      t.translate("Éxito"),
      t.translate('Tu contraseña a sido restablecida con éxito.'),
      'success'
    );
    dispatch(push('/tienda/inicio'));
  }).catch(() => {
    swal(
      "ERROR",
      t.translate('El enlace no es válido o ha expirado.'),
      'error'
    );
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const getProductosGenericos = () => (dispatch) => {
  dispatch(setLoading(true));
  api.get('/producto', {vendible: true, es_entrada: false}).then((data) => {
    if(data.count > 0)
      dispatch(setProductosGenericos(true));
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setUsuario = usuario => ({
  type: USUARIO,
  usuario,
});

export const cerrarSesion = (usuario = { }) => ({
  type: USUARIO,
  usuario,
});

export const setModal = modal => ({
  type: MODAL,
  modal,
});

export const setEntradas = entradas => ({
  type: ENTRADAS,
  entradas,
});

export const setProductos = productos => ({
  type: PRODUCTOS,
  productos,
});

export const setProductosGenericos = productos_genericos => ({
  type: PRODUCTOS_GENERICOS,
  productos_genericos,
});

export const setCompras = compras =>({
  type: COMPRAS,
  compras,
});

export const setCompra = compra =>({
  type: COMPRA,
  compra,
});

export const setPageEntradas = page_entradas => ({
  type: PAGE_ENTRADAS,
  page_entradas,
});


export const setLoadingEntradas = loading_entradas => ({
  type: LOADING_ENTRADAS,
  loading_entradas,
});

export const setLoadingGlobal = loading_global => ({
  type: LOADING_GLOBAL,
  loading_global,
});

export const setLoadingRecuperacion = loading_recuperacion => ({
  type: LOADING_RECUPERACION,
  loading_recuperacion,
});

export const setPageProductos = page_productos => ({
  type: PAGE_PRODUCTOS,
  page_productos,
});

export const setPageCompras = page_compras =>({
  type: PAGE_COMPRAS,
  page_compras,
});

export const setAccionModal = accionModal => ({
  type: ACCION_MODAL,
  accionModal,
});

export const setPagePerfil = page_perfil =>({
  type: PAGE_PERFIL,
  page_perfil,
});
export const tabChange = tab => ({
  type: TAB,
  tab,
});
export const setArchivo = archivo => ({
  type: ARCHIVO,
  archivo,
});

export const actions = {
  getMe,
  editar,
  getEntrada,
  registrar,
  restablecerPass,
  solicitarPass,
  editarEntrada,
  login,
  cerrarSesion,
  setModal,
  setAccionModal,
  getEntradas,
  setFiltro,
  getCompras,
  inicializarRegistro,
  getCompra,
  tabChange,
  getArchivo,
  getZip,
  getProductosGenericos,
};

export const reducers = {
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [LOADING_ENTRADAS]: (state, { loading_entradas }) => {
    return {
      ...state,
      loading_entradas,
    };
  },
  [LOADING_GLOBAL]: (state, { loading_global }) => {
    return {
      ...state,
      loading_global,
    };
  },
  [LOADING_RECUPERACION]: (state, { loading_recuperacion }) => {
    return {
      ...state,
      loading_recuperacion,
    };
  },
  [USUARIO]: (state, { usuario }) => {
    return {
      ...state,
      usuario,
    };
  },
  [MODAL]: (state, { modal }) => {
    return {
      ...state,
      modal,
    };
  },
  [ACCION_MODAL]: (state, { accionModal }) => {
    return {
      ...state,
      accionModal,
    };
  },
  [ENTRADAS]: (state, { entradas }) => {
    return {
      ...state,
      entradas,
    };
  },
  [ENTRADA]: (state, { entrada }) => {
    return {
      ...state,
      entrada,
    };
  },
  [PRODUCTOS]: (state, { productos }) => {
    return {
      ...state,
      productos,
    };
  },
  [PRODUCTOS_GENERICOS]: (state, { productos_genericos }) => {
    return {
      ...state,
      productos_genericos,
    };
  },
  [COMPRAS]: (state, { compras }) => {
    return {
      ...state,
      compras,
    };
  },
  [COMPRA]: (state, { compra }) => {
    return {
      ...state,
      compra,
    };
  },
  [PAGE_PRODUCTOS]: (state, { page_productos }) => {
    return {
      ...state,
      page_productos,
    };
  },
  [PAGE_ENTRADAS]: (state, { page_entradas }) => {
    return {
      ...state,
      page_entradas,
    };
  },
  [PAGE_COMPRAS]: (state, { page_compras }) => {
    return {
      ...state,
      page_compras,
    };
  },
  [PAGE_PERFIL]: (state, { page_perfil }) => {
    return {
      ...state,
      page_perfil,
    };
  },
  [FILTRO]: (state, { filtro }) => {
    return {
      ...state,
      filtro,
    };
  },
  [TAB]: (state, { tab }) => {
    return {
      ...state,
      tab,
    };
  },
  [ARCHIVO]: (state, { archivo }) => {
    return {
      ...state,
      archivo,
    };
  },  
};

export const initialState = {
  loading: 0,
  loading_entradas: false,
  loading_global: false,
  loading_recuperacion: false,
  modal: false,
  page_productos: 1,
  page_entradas: 1,
  page_compras: 1,
  page_perfil: 1,
  accionModal: null,
  usuario: {},
  compras: {},
  compra: {},
  entradas: {},
  archivo: {
    results: []
  },  
  entrada: {tipo_entrada: {}},
  tab: "1",
  filtro: undefined,
  productos_genericos: false,
};

export default handleActions(reducers, initialState);

import React from 'react';
import { Field, reduxForm } from 'redux-form'
import {validate, validators, optional, validatorFromFunction} from 'validate-redux-form';
import { renderField, renderSelectField, multiSelect } from "Utils/renderField/renderField";
import { translator as t } from "../../../../../../utility/traducer";


const Form = props => {
  const { handleSubmit, close, tipo } = props;
  return (
    <form onSubmit={handleSubmit} style={{padding: "2rem"}}>
      <div >
        <div className="form-group">
          <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="nombre_empresa">{t.translate('Nombre de la empresa *')}</label>
                <Field name="nombre_empresa" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="contacto_administrativo">{t.translate('Nombre del contacto administrativo *')}</label>
                <Field name="contacto_administrativo" component={renderField} type="text" className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="correo_contacto">{t.translate('Correo de contacto *')}</label>
                <Field name="correo_contacto" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="telefono">{t.translate('Teléfono *')}</label>
                <Field name="telefono" component={renderField} type="text" className="form-control"/>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="no_stand">{t.translate('No. de stand de su interés *')}</label>
                <Field name="no_stand" component={renderField} type="text" disabled={true} className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group flex-1 m-1">
                <label htmlFor="actividad_principal">{t.translate('Actividad principal de su empresa *')}</label>
                <Field name="actividad_principal" component={renderField} type="text" className="form-control"/>
              </div>
            </div>
            <br/>
            <div className="d-flex justify-content-center flex-row">
              <button type="button" className="btn btn-secondary m-1 align-self-center" onClick={close}>{t.translate('Cancelar')}</button>
              <button type="submit" className="btn btn-default m-1 align-self-center">{t.translate('Enviar datos')}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

const Contacto = reduxForm({
  // a unique name for the form
  form: 'Contacto',
  validate: data => {
    let correo_contacto = validators.exists()("Campo requerido");
    if (data.correo_contacto) {
      correo_contacto = optional(validators.regex(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)('Debe ser un correo válido'));
    }
    let telefono = validators.exists()(t.translate("Este campo es requerido"));
    if(!!data.telefono) {
      telefono = validatorFromFunction(() => (data.telefono.length <= 15))()(t.translate("Longitud máxima de 15 caracteres"))
    }
    return validate(data, {
      no_stand: validators.exists()("Campo requerido"),
      nombre_empresa: validators.exists()("Campo requerido"),
      contacto_administrativo: validators.exists()("Campo requerido"),
      telefono,
      actividad_principal: validators.exists()("Campo requerido"),
      correo_contacto,
    })
  }
})(Form);

export default Contacto

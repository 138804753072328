import { handleActions } from 'redux-actions';
import { api } from "api";
import moment from "moment";
import {ToastStore} from "react-toasts";
import {TIPOS_ENTRADA, UN_DIA} from "variables";

const DATA = "ADMIN_TRANSACCIONES_DATA";
const PAGE = "ADMIN_TRANSACCIONES_PAGE";
const LOADING = "ADMIN_TRANSACCIONES_LOADING";
const SEARCH = "ADMIN_TRANSACCIONES_SEARCH";
const FECHA_INICIAL = "ADMIN_TRANSACCIONES_FECHA_INICIAL";
const FECHA_FINAL = "ADMIN_TRANSACCIONES_FECHA_FINAL";
const FILTRO = "ADMIN_TRANSACCIONES_FILTRO";
const DETALLE = "ADMIN_TRANSACCIONES_DETALLE";
const TOTAL = "ADMIN_TRANSACCIONES_TOTAL";

export const constants = {

};

// ------------------------------------
// Actions
// ------------------------------------

export const getTransacciones = (page=1) =>(dispatch,getStore) =>{
  const { search, fecha_inicial, fecha_final, filtro } = getStore().transacciones;
  const params = { estado: 'AUTHORIZED', page, search, fecha_inicial, fecha_final};
  if (filtro !== undefined)
    params.es_oportunidad = filtro;
  dispatch(setLoading(true));
  api.get('/compras', params).then((data) => {
    dispatch(setData(data));
    dispatch(setPage(page));
    api.get('/compras/total_compra', params).then(response => {
      dispatch(setTotal(response));
    }).catch(() => {
    }).finally(() => {
      dispatch(setLoading(false));
    })
  }).catch(() => {
    dispatch(setLoading(false));
  }).finally(() => {
  });
};

export const getDetalle = (id) => (dispatch) =>{
  dispatch(setLoading(true));
  api.get(`compras/${id}`).then((data) => {
    dispatch(setDetalle(data))
  }).catch(()=>{
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const descargarRecibo = (id) => (dispatch) => {
  dispatch(setLoading(true));
  api.get(`compras/${id}/descargarRecibo/`).then((data) => {
    if(data.recibo){
      var link = document.createElement("a");
      link.href = `media/${data.recibo}`;
      link.download = data.recibo.split("/")[1];
      link.target = '_blank';
      link.click();
    }
  }).catch(()=>{
    ToastStore.error("Ha ocurrido un error.");
  }).finally(() => {
    dispatch(setLoading(false));
  })
}

export const searchChange = search => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getTransacciones());
};

export const setLoading = load => (dispatch, getStore) => {
  const loading = getStore().transacciones.loading;
  if (load) {
    dispatch({type: LOADING, loading: loading + 1 });
  } else {
    dispatch({type: LOADING, loading: loading - 1 });
  }
};
export const setFecha = (key, value) => (dispatch, getStore) => {
  const store = getStore();
  const transacciones = store.transacciones;
  if (key === "Inicial"){
    const fecha_inicial = new Date(value);
    const fecha_final = new Date(transacciones.fecha_final);
    if (fecha_final >= fecha_inicial)
      dispatch(setFechaInicial(value));
  } else {
    const fecha_inicial = new Date(transacciones.fecha_inicial);
    const fecha_final = new Date(value);
    if (fecha_final >= fecha_inicial)
      dispatch(setFechaFinal(value));
  }
  dispatch(getTransacciones());
};

export const setFiltro = (filtro) => (dispatch) => {
  dispatch({type: FILTRO, filtro});
  dispatch(getTransacciones());
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setFechaInicial = fecha_inicial => ({
  type: FECHA_INICIAL,
  fecha_inicial,
});

export const setFechaFinal = fecha_final => ({
  type: FECHA_FINAL,
  fecha_final,
});

export const setData = data => ({
  type: DATA,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

export const setSearch = search => ({
  type: SEARCH,
  search,
});

export const setDetalle = detalle => ({
  type: DETALLE,
  detalle,
});

export const setTotal = total => ({
  type: TOTAL,
  total,
});

export const actions = {
  getTransacciones,
  descargarRecibo,
  setFecha,
  searchChange,
  getDetalle,
  setFiltro,
};

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [DETALLE]: (state, { detalle }) => {
    return {
      ...state,
      detalle,
    };
  },
  [FECHA_FINAL]: (state, { fecha_final }) => {
    return {
      ...state,
      fecha_final,
    };
  },
  [FECHA_INICIAL]: (state, { fecha_inicial }) => {
    return {
      ...state,
      fecha_inicial,
    };
  },
  [FILTRO]: (state, { filtro }) => {
    return {
      ...state,
      filtro,
    };
  },
  [TOTAL]: (state, { total }) => {
    return {
      ...state,
      total,
    };
  },
};

export const initialState = {
  data: {
  },
  loading: 0,
  total: {},
  page: 1,
  filtro: undefined,
  search: '',
  fecha_inicial: moment().format('YYYY-MM-DD'),
  fecha_final: moment().format('YYYY-MM-DD'),
  detalle:{
    usuario:{}
  }
};

export default handleActions(reducers, initialState);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import _ from "lodash";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {TIPOS_ENTRADA} from "../../../../../../../utility/variables";
import { Link } from "react-router-dom";


class Entradas extends Component {
  static propTypes = {
    usuario: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.getEntradas();
  }

  render() {
    const { entradas, loading_entradas: loading, pageEntradas: page, filtro} = this.props;
    const options = {
      hideSizePerPage: true,
      noDataText: loading ? `${t.translate('Cargando')}...` : t.translate('No hay registros'),
      page: page,
      onPageChange: this.props.getEntradas,
    };

    return (
      <div id="perfil-entradas">
        <div className="form-group d-flex">
          <div className="radio c-radio c-radio-nofont d-flex ml-3 ml-md-5">
            <label className="negro font-weight-normal">
              <input type="radio" name="filtro"
                     ref={node => {
                       this.editados = node;
                     }}
                     onChange={() => {
                       this.props.setFiltro()
                     }}
                     checked={filtro === undefined}
                     value="todos"/>
              <span />{t.translate('Todos')}
            </label>
          </div>
          <div className="radio c-radio c-radio-nofont d-flex ml-10">
            <label className="negro font-weight-normal">
              <input type="radio" name="filtro"
                     ref={node => {
                       this.editados = node;
                     }}
                     onChange={() => {
                       this.props.setFiltro(false)
                     }}
                     checked={filtro === false}
                     value="no_editados"/>
              <span />{t.translate('No editados')}
            </label>
          </div>
          <div className="radio c-radio c-radio-nofont d-flex ml-10">
            <label className="negro font-weight-normal">
              <input type="radio" name="filtro"
                     ref={node => {
                       this.editados = node;
                     }}
                     onChange={() => {
                       this.props.setFiltro(true)
                     }}
                     checked={filtro === true}
                     value="editados"/>
              <span />{t.translate('Editados')}
            </label>
          </div>
        </div>
        <BootstrapTable
          data={loading ? [] : entradas.results}
          remote
          hover
          fetchInfo={{ dataTotalSize: entradas.count }}
          options={options}
        >
          <TableHeaderColumn
            dataAlign="center"
            dataField="tipo_entrada"
            dataFormat={(cell) => {
              const tipo = _.find(TIPOS_ENTRADA, {id: cell});
              return (<img src={tipo.img} alt={tipo.label} />)
            }}
          >
            Img
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataField="tipo_entrada"
            dataFormat={(cell) => {
              const entrada = _.find(TIPOS_ENTRADA, {id: cell});
              return entrada.label;
            }}
          >
            {t.translate("Tipo de entrada")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataField="nombre"
            dataFormat={(cell) => {
              if (!!cell)
                return cell;
              return "---------"
            }}
          >
            {t.translate("Asignado a")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataField="apellido"
            isKey
            dataFormat={(cell) => {
              if (!!cell)
                return cell;
              return "---------"
            }}
          >
            {t.translate("Apellido")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataField="estado"
            dataFormat={(cell) => {
              return cell ? t.translate("Editado") : (<strong style={{color: "#C60B1E"}}>{t.translate("Sin editar")}</strong>)
            }}
          >
            {t.translate("Estado")}
          </TableHeaderColumn>
          {
            /*
              SE COMENTA LA COLUMNA DE ACCIONES PARA QUE EL USUARIO NO PUEDA INGRESAR
              A EDITAR SU GAFETE
            */
          /*<TableHeaderColumn
            dataAlign="center"
            dataField="id"
            isKey
            dataFormat={(cell) => {
                return (<Link className="btn btn-success" to={`/tienda/entrada/${cell}/editar`}>{t.translate("Editar")}</Link>)
            }}
          >
            {t.translate("Acciones")}
          </TableHeaderColumn>*/
          }
        </BootstrapTable>
      </div>
    );
  }
}

export default Entradas;

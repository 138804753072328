import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/planta/planta';
import { actions as cuentaActions } from "../../../../redux/modules/tienda/cuenta";
import Header from './Header';


const ms2p = (state) => {
  const loginLoader = state.cuenta.loading;
  const loading_recuperacion = state.cuenta.loading_recuperacion;
  const url = window.location.href;
  const productos_genericos = state.cuenta.productos_genericos;
  return {
    ...state.planta,
    loginLoader,
    loading_recuperacion,
    usuario: state.cuenta.usuario,
    modal: state.cuenta.modal,
    url,
    productos_genericos,
  };
};

const md2p = { ...actions, ...cuentaActions };

export default connect(ms2p, md2p)(Header);

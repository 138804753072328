import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { validate, validators, validatorFromFunction } from 'validate-redux-form';
import { renderField } from "Utils/renderField/renderField";
import { Link } from "react-router-dom";



const Form = props =>{
  const { handleSubmit } = props;
  return(
    <form onSubmit={handleSubmit}>
      <div >
        <div className="form-group">
          <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column justify-content-center">
            <div className="d-flex flex-wrap flex-column flex-sm-row">
              <div className="d-flex flex-column form-group ml-2 mr-2 mr-md-4 mr-lg-5 ml-md-4 ml-lg-5" style={{minWidth: "300px"}}>
                <label htmlFor="first_name">Nombre *</label>
                <Field name="first_name" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group ml-2 mr-2 mr-md-4 mr-lg-5 ml-md-4 ml-lg-5" style={{minWidth: "300px"}}>
                <label htmlFor="username">Usuario del sistema *</label>
                <Field name="username" component={renderField} type="text" className="form-control"/>
              </div>
              <div className="d-flex flex-column form-group ml-2 mr-2 mr-md-4 mr-lg-5 ml-md-4 ml-lg-5" style={{minWidth: "300px"}}>
                <label htmlFor="password">Contraseña *</label>
                <Field name="password" component={renderField} type="text" className="form-control"/>
              </div>   
            </div>
            <div className="d-flex justify-content-center flex-row mt-3">
              <Link to="/usuarios" className="btn btn-secondary m-1 align-self-center" >Cancelar</Link>
              <button type="submit" className="btn btn-agregar m-1 align-self-center">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </form>

  )
}
const NuevoUser = reduxForm({
  // a unique name for the form
  form : 'NuevoUser',
  validate: data => {
    return validate(data, {
      username: validators.exists()("Campo requerido"),
      first_name: validators.exists()("Campo requerido"),
      password: validators.exists()("Campo requerido"),
    })
  }
})(Form);

export default NuevoUser;

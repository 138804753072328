import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/login';
import planta from './modules/planta/planta';
import tienda from './modules/tienda/tienda';
import stand from './modules/tienda/stand';
import cuenta from './modules/tienda/cuenta';
import administracion from './modules/administracion/stands';
import entradas from './modules/administracion/entradas';
import productos from './modules/administracion/productos';
import usuario from './modules/usuarios/usuarios';
import transacciones from './modules/administracion/transacciones';

export default combineReducers({
  form: formReducer,
  login,
  planta,
  tienda,
  stand,
  cuenta,
  administracion,
  entradas,
  productos,
  routing,
  usuario,
  transacciones,
});

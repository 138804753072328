import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/planta/planta';
import Espacio from './Espacio';


const ms2p = (state) => {
  const loaderStand = state.planta.loaderStand !== 0;
  return {
    ...state.planta,
    loaderStand,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Espacio);

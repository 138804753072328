export { default as Header } from "./Header";
export { default as Footer } from "./Footer";
export { default as Inicio } from "./Inicio";
export Pagos from "./Pagos";
export { default as Registro } from "./Registro";
export { default as Caja }  from "./Caja";
export Perfil from "./Perfil";
export Productos from "./Productos/";
export { Reset, SolicitudPass } from "./PassReset";


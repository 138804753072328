import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";

import "./footer.css";

const logo = require("assets/img/logo_agexport.png");


class Footer extends Component {
  static propTypes = {
    // idioma: PropTypes.object.isRequired,
  };

  render() {
    // const { idioma } = this.props;

    return (
      <footer id="footer" >
        <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-around w-100 p-5 mt-auto">
        
        <img className="logo" src={logo} alt="AGEXPORT" />
        <div className="d-flex flex-column ml-1 mr-1">
          <div className="pt-2 pb-2">
            {t.translate("Dirección: 15 avenida 14 73, Zona 13 Guatemala")}
          </div>
          <div className="pt-2 pb-2">
            {t.translate("Teléfono: (502) 2422-3559")}
          </div>
          <div className="pt-2 pb-2">
            {t.translate("Email: Agritrade@agexport.org.gt")}
          </div>
          <div className="pt-2 pb-2">
            {t.translate("Horario: Lunes a Viernes: 8am-5pm")}
          </div>
        </div>
        <div className="d-flex flex-column ml-1 mr-1">
          <a style={{color: "white"}} href="https://store.agritradecentralamerica.org">
            <div  className="pt-2 pb-2">
              {t.translate("Tienda en línea")}
            </div>
          </a>
          <a style={{color: "white"}} href="https://mapa.agritradecentralamerica.org">
            <div  className="pt-2 pb-2">
              {t.translate("Mapa virtual")}
            </div>
          </a>          
        </div>
        <div className="d-flex flex-row">
          <a style={{color: "white"}} href="https://twitter.com/AGEXPORTGT">
            <i className="p-2 fa fa-twitter fa-2x" />
          </a>
          <a style={{color: "white"}} href="https://www.facebook.com/PlataformaAgritrade/">
            <i className="p-2 fa fa-facebook fa-2x" />
          </a>
        </div>
        </div>
        <div className="d-flex flex-column align-items-start justify-content-around p-3 p-md-5 w-100">
          <div  className="pb-2">{t.translate('© Copyright 2018 AGRITRADE. TODOS LOS DERECHOS RESERVADOS.')}</div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { translator as t } from 'traducer';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderImagePicker, renderCurrency, renderTextArea, renderSwitch } from "Utils/renderField/renderField";
import Loader from "Utils/Loader";


const Form = props =>{
  const { handleSubmit, loader } = props;
  return(

    <form onSubmit={handleSubmit}>

      <div className="form-group">
        <div className="padding-15 p-sm-0 pt-sm-1 d-flex flex-column">
            <div className="d-flex flex-column flex-1 w-100">
              <label htmlFor="email">{t.translate('Ingresa el correo de tu cuenta de agritrade *')}</label>
              <Field name="email" component={renderField} type="text" className="form-control"/>
            </div>
            <div className="d-flex flex-column flex-1 w-100">
              <small className="text-white mt-2">{t.translate('Se te enviará un correo con los siguientes pasos para restablecer tu contraseña')}</small>
            </div>
          <br/>
          <div className="d-flex justify-content-center flex-row">
            <button type="submit" className="btn btn-default m-1 align-self-center">
              <Loader active={loader} small>
                <strong>{t.translate('Enviar')}</strong>
              </Loader>
            </button>
          </div>
        </div>
      </div>
    </form>
  )
};

const SolicitudForm = reduxForm({
  // a unique name for the form
  form : 'TiendaSolicitudForm',
  validate: data => {
    let email = validators.exists()(t.translate("Este campo es requerido"));
    if (!!data.email) {
      email = validators.regex(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)(t.translate('Debe ser un correo válido'));
    }
    return validate(data, {
      email,
    })
  }
})(Form);

export default SolicitudForm;

import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/tienda/cuenta';
import { setIdioma } from '../../../../redux/modules/planta/planta'
import Registro from './Registro';


const ms2p = (state) => {
  const { idioma } = state.planta;
  return {
    ...state.cuenta,
    idioma: idioma,
    loading: state.cuenta.loading !== 0,
  };
};

const md2p = { ...actions, setIdioma };

export default connect(ms2p, md2p)(Registro);

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/administracion/transacciones';
import ListadoTransacciones from './ListadoTransacciones';


const ms2p = (state) => {
  const loading = state.transacciones.loading !== 0;
  return {
    ...state.transacciones,
    loading,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoTransacciones);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translator as t } from "traducer";
import swal from 'sweetalert2';
import { Link, Redirect } from "react-router-dom";
import "./Exito.css";


class Exito extends Component {
    static propTypes = {
        usuario: PropTypes.object.isRequired,
        getMe: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        
    }

    componentWillMount() {
        this.props.getMe();
    }
    
    componentWillUnmount(){
        this.props.setVenta(false);
    }

    render() {
        const { usuario,venta } = this.props;
        if(!venta){
            return(
                <Redirect to="/tienda/perfil" />
            )
        }
        return(
            <div id="pago-exitoso" >
                <div className="d-flex flex-column justify-content-center align-items-center align-self-center mt-3 mt-sm-5">
                    <div className="p-2">
                        <img className="img-exito" src={require("assets/img/exito.png")} alt="info" />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center mt-3 mt-sm-5">
                        <div className="p-1">
                            <h5>{t.translate('Gracias por realizar tu compra, se estará enviando')}</h5>
                        </div>
                        <div className="p-1">
                            <h5>{t.translate('al correo: ')}<strong>{usuario.username}</strong></h5>
                        </div>
                        <div className="p-1">
                            <h5>{t.translate('el documento por el pago realizado.')}</h5>
                        </div>
                        <div className="p-2">
                            <Link className="btn btn-success mt-2 mt-md-0" to="/tienda/perfil"><strong>{t.translate('Aceptar')}</strong></Link>
                        </div>                    
                    </div>                    
                </div>
                <img className="verduras" src={require("assets/img/marco_verduras.png")} alt=""/>
            </div>
        );
    }
}
export default Exito;
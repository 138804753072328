import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {DISPONIBLE, RESERVADO} from "variables";
import { translator as t } from "../../../../../../utility/traducer";

const placeholder = require('assets/img/img_placeholder.png');

class Vecinos extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  estadoVecino(estado) {
    if (estado === DISPONIBLE) {
      return "Disponible"
    } else if (estado === RESERVADO) {
      return "Reservado"
    } else {
      return "Comprado"
    }
  }

  render() {
    const { data } = this.props;
    return (
      <div id="vecinos" className="d-none d-lg-flex flex-column mt-3">
        <h3 className="font-weight-bold m-2 align-self-center">{t.translate('Vecinos')}</h3>
        <div className="d-flex flex-wrap flex-row m-2 justify-content-between">
          {data.vecinos.map((vecino) => {
            return (
              <a style={{cursor: "pointer"}} key={vecino.id} className="d-flex flex-row p-2" onClick={() => this.props.verStand(vecino.id)}>
                <div className="vecino mr-1">
                  <img className="rounded-circle" src={vecino.img ? vecino.img : placeholder} onError={(e)=>{e.target.onerror = null; e.target.src=placeholder}} alt="" />
                </div>
                <div className="d-flex flex-column">
                  <div>{t.translate(this.estadoVecino(vecino.estado))}</div>
                  <small>
                    {t.translate('Espacio No.')}&nbsp;
                    {vecino.id}
                  </small>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Vecinos;

import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/tienda/tienda';
import { login } from "../../../../../../../redux/modules/tienda/cuenta";
import Detalle from './Detalle';


const ms2p = (state) => {
  const { idioma } = state.planta;
  const loginLoader = state.cuenta.loading;
  return {
    ...state.tienda,
    idioma: idioma.codigo,
    usuario: state.cuenta.usuario,
    loginLoader,
  };
};

const md2p = { ...actions, login };

export default connect(ms2p, md2p)(Detalle);
import { handleActions } from 'redux-actions';
import { api } from "api";
import _ from "lodash";
import {initialize as initializeForm} from 'redux-form';
import { STANDS } from "variables";
import {ToastStore} from "react-toasts";
import {ESTADOS_STAND} from "../../../utility/variables";

const DATA = 'ADMINISTRACION_DATA';
const SEARCH = 'ADMINISTRACION_SEARCH';
const LOADING = 'ADMINISTRACION_LOADING';
const EMPRESAS = 'ADMINISTRACION_EMPRESAS';

export const constants = {
};

// ------------------------------------
// Actions
// ------------------------------------

export const getEmpresas = () => (dispatch) => {
  dispatch(setLoading(true));
  api.get('data/empresas').then((data) => {
    dispatch(setEmpresas(data));
  }).catch((e) => {
    ToastStore.error(e.detail);
  }).finally(() => {
    dispatch(setLoading(false));
  })
};

export const getStands = () => (dispatch, getStore) => {
  const search = getStore().administracion.search;
  dispatch(setLoading(true));
  api.get('data', {search}).then((data) => {
    dispatch(setData(data));
  }).catch(e => {

  }).finally(() => {
    dispatch(setLoading(false));
  });
};

export const updateStand = (data, id) => (dispatch) => {
  dispatch(setLoading(true));
  const vecinos = [];
  if (data.vecinos) {
    data.vecinos.forEach((vecino) => {
      vecinos.push(vecino.espacio);
    });
  }
  if (data.empresa) {
    data.empresa = data.empresa.value;
  }
  data.estado = data.estado.value;
  data.full_access = data.full_access ? data.full_access: 0;
  data.apoyo = data.apoyo ? data.apoyo:0;
  data.comite_organizador = data.comite_organizador ? data.comite_organizador:0;
  data.comprador = data.comprador ? data.comprador:0;
  data.conferencia = data.conferencia ? data.conferencia:0;
  data.estudiante = data.estudiante ? data.estudiante: 0;
  data.invitado_especial = data.invitado_especial ? data.invitado_especial:0;
  data.marbetes_parqueo = data.marbetes_parqueo ? data.marbetes_parqueo:0;
  data.piso_exhibicion = data.piso_exhibicion ? data.piso_exhibicion:0;
  data.piso_exhibicion_sabado = data.piso_exhibicion_sabado ? data.piso_exhibicion_sabado:0;
  data.prensa = data.prensa ? data.prensa:0;
  data.productor = data.productor ? data.productor:0;
  data.staff = data.staff ? data.staff:0;
  data.un_dia = data.un_dia ? data.un_dia:0;
  api.put(`/data/${id}`, {...data, vecinos: JSON.stringify(vecinos)})
    .then((data) => {
    })
    .catch(() => {

    })
    .finally(() => {
      dispatch(setLoading(false));
      dispatch(getStands());
    })
};

export const searchChange = search => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getStands());
};

export const editar = (stand) => (dispatch, getStore) => {
  stand = _.cloneDeep(stand);
  const empresas = getStore().administracion.empresas;
  const empresa = _.find(empresas, {Id: parseFloat(stand.empresa)});
  if (empresa) {
    empresa.value = empresa.Id;
    empresa.label = empresa.Nombre;
    stand.empresa = empresa;
  } else if (stand.empresa) {
      stand.empresa = {label: stand.empresa, value: stand.empresa, __isNew__: true}
  }
  stand.estado = _.find(ESTADOS_STAND, {id: stand.estado});
  if (stand.vecinos) {
    try {
      stand.vecinos = JSON.parse(stand.vecinos);
      const vecinos = [];
      stand.vecinos.forEach((vecino) => {
        const temp = _.find(STANDS, {espacio: vecino});
        if (temp)
          vecinos.push(temp)
      });
      stand.vecinos = vecinos;
    } catch (e) { }
  }
  dispatch(initializeForm('EdicionStand', stand));
};

export const setLoading = load => (dispatch, getStore) => {
  const loading = getStore().administracion.loading;
  if (load) {
    dispatch({type: LOADING, loading: loading + 1 });
  } else {
    dispatch({type: LOADING, loading: loading - 1 });
  }
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setSearch = search => ({
  type: SEARCH,
  search,
});

export const setEmpresas = empresas => ({
  type: EMPRESAS,
  empresas,
});

export const setData = data => ({
  type: DATA,
  data,
});

export const actions = {
  getStands,
  editar,
  searchChange,
  updateStand,
  getEmpresas,
};

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [LOADING]: (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [EMPRESAS]: (state, { empresas }) => {
    return {
      ...state,
      empresas,
    };
  },
};

export const initialState = {
  data: {},
  loading: 0,
  search: '',
  empresas: [],
};

export default handleActions(reducers, initialState);

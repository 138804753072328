import { translator as t } from "traducer";

export const DISPONIBLE = 0;
export const RESERVADO = 5;
export const COMPRADO = 10;


export const UN_DIA = 10;
export const ESTUDIANTE = 5;
export const FULL_ACCESS = 1;
export const APOYO = 15;
export const MARBETES_PARQUEO = 20;
export const PISO_EXHIBICION = 25;
export const PISO_EXHIBICION_SABADO = 30;
export const CONFERENCIA = 35;
export const CONFERENCISTA = 37;
export const STAFF = 40;
export const PRENSA = 45;
export const INVITADO_ESPECIAL = 50;
export const COMITE_ORGANIZADOR = 55;
export const COMPRADOR = 60;
export const PRODUCTOR = 65;
export const FULL_ACCESS_CONGRESO_AGUACATE = 70
export const APOYO_CONGRESO_AGUACATE = 75
export const ENTRADA_VIRTUAL_CONGRESO_AGUACATE = 80

export const logo_en = require("assets/img/logo_azul_en.png");
export const logo_blanco_en = require("assets/img/logo_blanco_en.png");
export const logo_es = require("assets/img/logo_azul_es.png");
export const logo_negro_es = require("assets/img/logo_negro_es.png");
export const logo_blanco_es = require("assets/img/logo_blanco_es.png");
export const logo_agexport_blanco = require("assets/img/gafete/logo_agexport.png");
export const logo_agexport_azul = require("assets/img/gafete/logo_agexport_azul.png");
export const logo_agexport_negro = require("assets/img/gafete/logo_agexport_negro.png");

export const ENERO = 1;
export const FEBRERO = 2;
export const MARZO = 3;
export const ABRIL = 4;
export const MAYO = 5;
export const JUNIO = 6;
export const JULIO = 7;
export const AGOSTO = 8;
export const SEPTIEMBRE = 9;
export const OCTUBRE = 10;
export const NOVIEMBRE = 11;
export const DICIEMBRE = 12;

export const MESES = [
  { value: ENERO, id: ENERO, label: 'Enero (01)'},
  { value: FEBRERO, id: FEBRERO, label: 'Febrero (02)'},
  { value: MARZO, id: MARZO, label: 'Marzo (03)'},
  { value: ABRIL, id: ABRIL, label: 'Abril (04)'},
  { value: MAYO, id: MAYO, label: 'Mayo (05)'},
  { value: JUNIO, id: JUNIO, label: 'Junio (06)'},
  { value: JULIO, id: JULIO, label: 'Julio (07)'},
  { value: AGOSTO, id: AGOSTO, label: 'Agosto (08)'},
  { value: SEPTIEMBRE, id: SEPTIEMBRE, label: 'Septiembre (09)'},
  { value: OCTUBRE, id: OCTUBRE, label: 'Octubre (10)'},
  { value: NOVIEMBRE, id: NOVIEMBRE, label: 'Noviembre (11)'},
  { value: DICIEMBRE, id: DICIEMBRE, label: 'Diciembre (12)'},
];

export const MONTHS = [
  { value: ENERO, id: ENERO, label: 'January (01)'},
  { value: FEBRERO, id: FEBRERO, label: 'February (02)'},
  { value: MARZO, id: MARZO, label: 'March (03)'},
  { value: ABRIL, id: ABRIL, label: 'April (04)'},
  { value: MAYO, id: MAYO, label: 'May (05)'},
  { value: JUNIO, id: JUNIO, label: 'June (06)'},
  { value: JULIO, id: JULIO, label: 'July (07)'},
  { value: AGOSTO, id: AGOSTO, label: 'August (08)'},
  { value: SEPTIEMBRE, id: SEPTIEMBRE, label: 'September (09)'},
  { value: OCTUBRE, id: OCTUBRE, label: 'October (10)'},
  { value: NOVIEMBRE, id: NOVIEMBRE, label: 'November (11)'},
  { value: DICIEMBRE, id: DICIEMBRE, label: 'December (12)'},
];

export const ESTILOS_SELECT_REGISTRO = {
 control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: '0',
      borderBottom: '2px solid #757575',
      borderRadius: '0',
  }),
  option: (base) => ({
      ...base,
      color:'black',
  }),
  dropdownIndicator:(base) =>({
      ...base,
      color: '#757575',
  }),
  placeholder:(base)=>({
    ...base,
    color: 'black',
  })
};

export const ESTILOS_SELECT_PERFIL_READ = {
  singleValue: (base) =>({
      ...base,
      color: 'white',
      fontSize: '13',
  }),
  control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: '0',
      borderBottom: 'solid 3px #4373D1',
      borderRadius: '0',
  }),
  option: (base) => ({
      ...base,
      color:'black',
      fontSize: '12',
  }),
  dropdownIndicator:(base) =>({
      ...base,
      color: '#4373D1',
  }),
  placeholder:(base)=>({
    ...base,
    color: 'white',
    fontSize: '13',
  })
};

export const ESTILOS_SELECT_PERFIL_WRITE = {
  singleValue: (base) =>({
      ...base,
      color: 'white',
      fontSize: '13',
  }),
  control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'solid 2px #FFFFFF',
      borderRadius: '0',
  }),
  option: (base) => ({
      ...base,
      color:'black',
      fontSize: '12',
  }),
  dropdownIndicator:(base) =>({
      ...base,
      color: 'white',
  }),
  placeholder:(base)=>({
    ...base,
    color: 'white',
    fontSize: '13',
  })
};

export const ESTILOS_SELECT = {
  singleValue: (base) =>({
      ...base,
      color: 'white',
      fontSize: '13',
  }),
  control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      width: '160',
      border: '0',
      borderBottom: 'solid 2px #FFFFFF',
      borderRadius: '0',
  }),
  option: (base) => ({
      ...base,
      color:'black',
      fontSize: '12',
  }),
  dropdownIndicator:(base) =>({
      ...base,
      color: 'white',
  }),
  placeholder:(base)=>({
    ...base,
    color: 'white',
    fontSize: '13',
  })
};

export const ESTILOS_SELECT_STANDS = {
    singleValue: (base) =>({
        ...base,
        color: 'black',
    }),
    option: (base) => ({
        ...base,
        color:'black',
    }),
    placeholder:(base)=>({
      ...base,
      color: 'black',
    })
  };

export const ESTILOS_SELECT_MONTH = {
  singleValue: (base) =>({
      ...base,
      color: 'white',
      fontSize: '13',
  }),
  control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      width: '270',
      border: '0',
      borderBottom: 'solid 2px #FFFFFF',
      borderRadius: '0',
  }),
  option: (base) => ({
      ...base,
      color:'black',
      fontSize: '12',
  }),
  dropdownIndicator:(base) =>({
      ...base,
      color: 'white',
  }),
  placeholder:(base)=>({
    ...base,
    color: 'white',
    fontSize: '13',
  })
};

export const ESTADOS_STAND = [
  {value: DISPONIBLE, id: DISPONIBLE, label: "Disponible"},
  {value: RESERVADO, id: RESERVADO, label: "Reservado"},
  {value: COMPRADO, id: COMPRADO, label: "Comprado"},
];


export const TIPOS_MONEDA = [
  { value: 'GTQ', label: t.translate('Quetzales (GTQ)') },
  { value: 'USD', label: t.translate('Dólares (USD)') },
]

export const full_access = {background: "linear-gradient(to right, #FFA900, #FFD53A)"};
export const full_access_congreso_aguacate = {background: "linear-gradient(to right, #204827, #3F8C4D)"};
export const apoyo_congreso_aguacate = {background: "linear-gradient(to right, #66CC00, #73e600)"};
export const un_dia = {background: "linear-gradient(to right, #E77A04, #FBC032)"};
export const estudiante = {background: "linear-gradient(to right, #E70049, #FB4E87)"};
export const apoyo = {background: "linear-gradient(to right, #2D5ED9, #2482D9)"};
export const marbetes_parqueo = {background: "linear-gradient(to right, #0095E9, #52D3FA)"};
export const piso_exhibicion = {background: "linear-gradient(to right, #338EDE, #60D3F8)",color: "#423F38"};
export const piso_exhibicion_sabado = {background: "linear-gradient(to right, #916843, #CCA17B)", color: "#423F38"};
export const conferencia = {background: "linear-gradient(to right, #E11D03, #F63C01)"};
export const conferencista = {background: "linear-gradient(to right, #FAB29F, #FBBFA9)"};
export const staff = {background: "linear-gradient(to right, #FAC054, #E1F681)",color: "#423F38"};
export const prensa = {background: "linear-gradient(to right, #BF4C8D, #7E6DB9)"};
export const invitado_especial = {background: "linear-gradient(to right, #5E5E5E, #737374)"};
export const comite_organizador = {background: "linear-gradient(to right, #7C9D48, #96C54C)"};
export const comprador = {background: "linear-gradient(to right, #404D94, #656CC8)"};
export const productor = {background: "linear-gradient(to right, #466738, #21850B)"};

export const foto_full_access = require("assets/img/full_access.png");
export const foto_full_access_congreso_aguacate = require("assets/img/full_access_congreso-aguacate.png");
export const foto_apoyo_congreso_aguacate = require("assets/img/apoyo_congreso-aguacate.png");
export const foto_un_dia = require("assets/img/un_dia.png");
export const foto_estudiante = require("assets/img/estudiante.png");
export const foto_apoyo = require("assets/img/apoyo.png");
export const foto_marbetes_parqueo = require("assets/img/parqueo.png");
export const foto_piso_exhibicion = require("assets/img/piso_exhibicion.png");
export const foto_piso_exhibicion_sabado = require("assets/img/piso_exhibicion_sabado.png");
export const foto_conferencia = require("assets/img/conferencia.png");
export const foto_staff = require("assets/img/staff.png");
export const foto_prensa = require("assets/img/prensa.png");
export const foto_invitado_especial = require("assets/img/invitado_especial.png");
export const foto_comite_organizador = require("assets/img/comite_organizador.png");
export const foto_comprador = require("assets/img/comprador.png");
export const foto_productor = require("assets/img/productor.png");

export const foto_full_access_s = require("assets/img/full_access_s.png");
export const foto_full_access_congreso_aguacate_s = require("assets/img/full_access_congreso-aguacate_s.png");
export const foto_un_dia_s = require("assets/img/un_dia_s.png");
export const foto_estudiante_s = require("assets/img/estudiante_s.png");
export const foto_apoyo_s = require("assets/img/apoyo_s.png");
export const foto_apoyo_congreso_aguacate_s = require("assets/img/apoyo_congreso-aguacate_s.png");
export const foto_marbetes_parqueo_s = require("assets/img/parqueo_s.png");
export const foto_piso_exhibicion_s = require("assets/img/piso_exhibicion_s.png");
export const foto_piso_exhibicion_sabado_s = require("assets/img/piso_exhibicion_sabado_s.png");
export const foto_conferencia_s = require("assets/img/conferencia_s.png");
export const foto_conferencista_s = require("assets/img/conferencia_s.png");
export const foto_staff_s = require("assets/img/staff_s.png");
export const foto_prensa_s = require("assets/img/prensa_s.png");
export const foto_invitado_especial_s = require("assets/img/invitado_especial_s.png");
export const foto_comite_organizador_s = require("assets/img/comite_organizacion_s.png");
export const foto_comprador_s = require("assets/img/comprador_s.png");
export const foto_productor_s = require("assets/img/productor_s.png");

export const foto_apoyo_c = require("assets/img/ayuda_de_stand.png")
export const foto_full_access_c = require("assets/img/full_access_c.png");
export const foto_full_access_congreso_aguacate_c = require("assets/img/full_access_congreso-aguacate_c.png");
export const foto_apoyo_congreso_aguacate_c = require("assets/img/apoyo_congreso-aguacate_c.png")
export const foto_un_dia_c = require("assets/img/un_dia_c.png");
export const foto_estudiante_c = require("assets/img/estudiante_c.png");
export const foto_marbetes_parqueo_c = require("assets/img/parqueo_c.png");
export const foto_piso_exhibicion_c = require("assets/img/piso_exhibicion_c.png");
export const foto_piso_exhibicion_sabado_c = require("assets/img/piso_exhibicion_sabado_c.png");
export const foto_conferencia_c = require("assets/img/conferencia_c.png");
export const foto_staff_c = require("assets/img/staff_c.png");
export const foto_prensa_c = require("assets/img/prensa_c.png");
export const foto_invitado_especial_c = require("assets/img/invitado_especial_c.png");
export const foto_comite_organizador_c = require("assets/img/comite_organizador_c.png");
export const foto_comprador_c = require("assets/img/comprador_c.png");
export const foto_productor_c = require("assets/img/productor_c.png");

export const foto_full_access_blanco = require("assets/img/full_access_blanco.png");
export const foto_un_dia_blanco = require("assets/img/un_dia_blanco.png");
export const foto_estudiante_blanco = require("assets/img/estudiante_blanco.png");
export const foto_apoyo_blanco = require("assets/img/apoyo_blanco.png");
export const foto_marbetes_parqueo_blanco = require("assets/img/parqueo_blanco.png");
export const foto_piso_exhibicion_blanco = require("assets/img/piso_exhibicion_blanco.png");
export const foto_piso_exhibicion_sabado_blanco = require("assets/img/piso_exhibicion_sabado_blanco.png");
export const foto_conferencia_blanco = require("assets/img/conferencia_blanco.png");
export const foto_staff_blanco = require("assets/img/staff.png");
export const foto_prensa_blanco = require("assets/img/prensa_blanco.png");
export const foto_invitado_especial_blanco = require("assets/img/invitado_especial_blanco.png");
export const foto_comite_organizador_blanco = require("assets/img/comite_organizador_blanco.png");
export const foto_comprador_blanco = require("assets/img/comprador_blanco.png");
export const foto_productor_blanco = require("assets/img/productor_blanco.png");

const un_dia_top = require("assets/img/gafete/un_dia_top.png");
const estudiante_top = require("assets/img/gafete/estudiante_top.png");
const full_access_top = require("assets/img/gafete/full_access_top.png");
const apoyo_top = require("assets/img/gafete/apoyo_top.png");
const un_dia_bottom = require("assets/img/gafete/un_dia_bottom.png");
const estudiante_bottom = require("assets/img/gafete/estudiante_bottom.png");
const full_access_bottom = require("assets/img/gafete/full_access_bottom.png");
const apoyo_bottom = require("assets/img/gafete/apoyo_bottom.png");
const marbetes_parqueo_top = require("assets/img/gafete/parqueo_top.png");
const piso_exhibicion_top = require("assets/img/gafete/piso_exhibicion_top.png");
const piso_exhibicion_sabado_top = require("assets/img/gafete/piso_exhibicion_sabado_top.png");
const conferencia_top = require("assets/img/gafete/conferencia_top.png");
const conferencista_top = require("assets/img/gafete/conferencista_top.png");
const staff_top = require("assets/img/gafete/staff_top.png");
const prensa_top = require("assets/img/gafete/prensa_top.png");
const invitado_especial_top = require("assets/img/gafete/invitado_especial_top.png");
const comite_organizador_top = require("assets/img/gafete/comite_organizador_top.png");
const comprador_top = require("assets/img/gafete/comprador_top.png");
const productor_top = require("assets/img/gafete/productor_top.png");
const marbetes_parqueo_bottom = require("assets/img/gafete/parqueo_bottom.png");
const piso_exhibicion_bottom = require("assets/img/gafete/piso_exhibicion_bottom.png");
const piso_exhibicion_sabado_bottom = require("assets/img/gafete/piso_exhibicion_sabado_bottom.png");
const conferencia_bottom = require("assets/img/gafete/conferencia_bottom.png");
const conferencista_bottom = require("assets/img/gafete/conferencista_bottom.png");
const staff_bottom = require("assets/img/gafete/staff_bottom.png");
const prensa_bottom = require("assets/img/gafete/prensa_bottom.png");
const invitado_especial_bottom = require("assets/img/gafete/invitado_especial_bottom.png");
const comite_organizador_bottom = require("assets/img/gafete/comite_organizador_bottom.png");
const comprador_bottom = require("assets/img/gafete/comprador_bottom.png");
const productor_bottom = require("assets/img/gafete/productor_bottom.png");
const full_access_top_CONGRESO_AGUACATE = require("assets/img/gafete/full_access_top-CONGRESO_AGUACATE.png")
const entrada_virtual_top_CONGRESO_AGUACATE = require("assets/img/gafete/entrada_virtual_top-CONGRESO_AGUACATE.png")
const entrada_virtual_bottom_CONGRESO_AGUACATE = require("assets/img/gafete/entrada_virtual_bottom-CONGRESO_AGUACATE.png")
const full_access_bottom_CONGRESO_AGUACATE = require("assets/img/gafete/full_access_bottom-CONGRESO_AGUACATE.png")
const apoyo_top_CONGRESO_AGUACATE = require("assets/img/gafete/apoyo_top-CONGRESO_AGUACATE.png")
const apoyo_bottom_CONGRESO_AGUACATE = require("assets/img/gafete/apoyo_bottom-CONGRESO_AGUACATE.png")

export const TIPOS_ENTRADA = [
  {top: entrada_virtual_top_CONGRESO_AGUACATE, bottom: entrada_virtual_bottom_CONGRESO_AGUACATE, value: ENTRADA_VIRTUAL_CONGRESO_AGUACATE, id: ENTRADA_VIRTUAL_CONGRESO_AGUACATE, label: t.translate('Entrada Virtual - Congreso de Aguacate'), img: foto_full_access_congreso_aguacate_s},
  {top: full_access_top_CONGRESO_AGUACATE, bottom: full_access_bottom_CONGRESO_AGUACATE, value: FULL_ACCESS_CONGRESO_AGUACATE, id: FULL_ACCESS_CONGRESO_AGUACATE, label: t.translate('Full Access - Congreso de Aguacate'), img: foto_full_access_congreso_aguacate_s},
  {top: apoyo_top_CONGRESO_AGUACATE, bottom: apoyo_bottom_CONGRESO_AGUACATE, value: APOYO_CONGRESO_AGUACATE, id: APOYO_CONGRESO_AGUACATE, label: t.translate('Apoyo Stand - Congreso de Aguacate'), img: foto_apoyo_congreso_aguacate_s},
  {top: un_dia_top, bottom: un_dia_bottom, value: UN_DIA, id: UN_DIA, label: t.translate('Programa de Conferencias y Piso de Exhibición'), img: foto_un_dia_s},
  {top: estudiante_top, bottom: estudiante_bottom, value: ESTUDIANTE, id: ESTUDIANTE, label: t.translate('Estudiante'), img: foto_estudiante_s},
  {top: full_access_top, bottom: full_access_bottom, value: FULL_ACCESS, id: FULL_ACCESS, label: t.translate('Full Access'), img: foto_full_access_s},
  {top: apoyo_top, bottom: apoyo_bottom, value: APOYO, id: APOYO, label: t.translate('Apoyo Stand'), img: foto_apoyo_s},
  {top: marbetes_parqueo_top, bottom: marbetes_parqueo_bottom, value: MARBETES_PARQUEO, id: MARBETES_PARQUEO, label: t.translate('Marbetes Parqueo'), img: foto_marbetes_parqueo_s },
  {top: piso_exhibicion_top, bottom: piso_exhibicion_bottom, value: PISO_EXHIBICION, id: PISO_EXHIBICION, label: t.translate('Piso Exhibición'), img: foto_piso_exhibicion_s},
  {top: piso_exhibicion_sabado_top, bottom: piso_exhibicion_sabado_bottom, value: PISO_EXHIBICION_SABADO, id: PISO_EXHIBICION_SABADO, label: t.translate('Piso Exhibición Sábado'), img: foto_piso_exhibicion_sabado_s},
  {top: conferencia_top, bottom: conferencia_bottom, value: CONFERENCIA, id: CONFERENCIA, label: t.translate('Conferencias'), img: foto_conferencia_s},
  {top: conferencista_top, bottom: conferencista_bottom, value: CONFERENCISTA, id: CONFERENCISTA, label: t.translate('Conferencista'), img: foto_conferencista_s},
  {top: staff_top, bottom: staff_bottom, value: STAFF, id: STAFF, label: t.translate('Staff'), img: foto_staff_s},
  {top: prensa_top, bottom: prensa_bottom, value: PRENSA, id: PRENSA, label: t.translate('Prensa'), img: foto_prensa_s},
  {top: invitado_especial_top, bottom: invitado_especial_bottom, value: INVITADO_ESPECIAL, id: INVITADO_ESPECIAL, label: t.translate('Invitado Especial'), img: foto_invitado_especial_s},
  {top: comite_organizador_top, bottom: comite_organizador_bottom, value: COMITE_ORGANIZADOR, id: COMITE_ORGANIZADOR, label: t.translate('Comité Organizador'), img: foto_comite_organizador_s},
  {top: comprador_top, bottom: comprador_bottom, value: COMPRADOR, id: COMPRADOR, label: t.translate('Comprador'), img: foto_comprador_s},
  {top: productor_top, bottom: productor_bottom, value: PRODUCTOR, id: PRODUCTOR, label: t.translate('Productor'), img: foto_productor_s},
];

export const STANDS = [
  {espacio: 1, area: "3x2", vecinos: []},
  {espacio: 2, area: "3x2", vecinos: []},
  {espacio: 3, area: "3x2", vecinos: []},
  {espacio: 4, area: "3x2", vecinos: []},
  {espacio: 5, area: "3x2", vecinos: []},
  {espacio: 6, area: "3x2", vecinos: []},
  {espacio: 7, area: "3x1.5", vecinos: []},
  {espacio: 8, area: "3x2", vecinos: []},
  {espacio: 9, area: "3x1.5", vecinos: []},
  {espacio: 10, area: "3x2", vecinos: []},
  {espacio: 11, area: "3x1.5", vecinos: []},
  {espacio: 12, area: "3x2", vecinos: []},
  {espacio: 13, area: "3x1.5", vecinos: []},
  {espacio: 14, area: "3x2", vecinos: []},
  {espacio: 15, area: "3x1.5", vecinos: []},
  {espacio: 16, area: "3x2", vecinos: []},
  {espacio: 17, area: "3x2", vecinos: []},
  {espacio: 18, area: "3x1.5", vecinos: []},
  {espacio: 19, area: "3x2", vecinos: []},
  {espacio: 20, area: "3x2", vecinos: []},
  {espacio: 21, area: "3x2", vecinos: []},
  {espacio: 22, area: "3x2", vecinos: []},
  {espacio: 23, area: "3x2", vecinos: []},
  {espacio: 24, area: "3x2", vecinos: []},
  {espacio: 25, area: "3x2", vecinos: []},
  {espacio: 26, area: "3x2", vecinos: []},
  {espacio: 27, area: "3x2", vecinos: []},
  {espacio: 28, area: "3x2", vecinos: []},
  {espacio: 29, area: "3x2", vecinos: []},
  {espacio: 30, area: "3x2", vecinos: []},
  {espacio: 31, area: "3x2", vecinos: []},
  {espacio: 32, area: "3x2", vecinos: []},
  {espacio: 33, area: "3x2", vecinos: []},
  {espacio: 34, area: "3x2", vecinos: []},
  {espacio: 35, area: "3x2", vecinos: []},
  {espacio: 36, area: "3x2", vecinos: []},
  {espacio: 37, area: "3x2", vecinos: []},
  {espacio: 38, area: "3x2", vecinos: []},
  {espacio: 39, area: "3x2", vecinos: []},
  {espacio: 40, area: "3x2", vecinos: []},
  {espacio: 41, area: "3x2", vecinos: []},
  {espacio: 42, area: "3x2", vecinos: []},
  {espacio: 43, area: "3x3", vecinos: []},
  {espacio: 44, area: "3x2", vecinos: []},
  {espacio: 45, area: "3x2", vecinos: []},
  {espacio: 46, area: "3x2", vecinos: []},
  {espacio: 47, area: "3x2", vecinos: []},
  {espacio: 48, area: "3x2", vecinos: []},
  {espacio: 49, area: "3x2", vecinos: []},
  {espacio: 50, area: "3x2", vecinos: []},
  {espacio: 51, area: "3x2", vecinos: []},
  {espacio: 52, area: "3x2", vecinos: []},
  {espacio: 53, area: "3x2", vecinos: []},
  {espacio: 54, area: "3x2", vecinos: []},
  {espacio: 55, area: "3x2", vecinos: []},
  {espacio: 56, area: "3x4", vecinos: []},
  {espacio: 57, area: "4x2", vecinos: []},
  {espacio: 58, area: "3x2", vecinos: []},
  {espacio: 59, area: "3x2", vecinos: []},
  {espacio: 60, area: "3x2", vecinos: []},
  {espacio: 61, area: "3x2", vecinos: []},
  {espacio: 62, area: "3x2", vecinos: []},
  {espacio: 63, area: "3x2", vecinos: []},
  {espacio: 64, area: "3x2", vecinos: []},
  {espacio: 65, area: "3x2", vecinos: []},
  {espacio: 66, area: "4x2", vecinos: []},
  {espacio: 67, area: "3x2", vecinos: []},
  {espacio: 68, area: "3x2", vecinos: []},
  {espacio: 69, area: "3x2", vecinos: []},
  {espacio: 70, area: "3x2", vecinos: []},
  {espacio: 71, area: "3x2", vecinos: []},
  {espacio: 72, area: "3x2", vecinos: []},
  {espacio: 73, area: "3x2", vecinos: []},
  {espacio: 74, area: "3x2", vecinos: []},
  {espacio: 75, area: "3x2", vecinos: []},
  {espacio: 76, area: "3x2", vecinos: []},
  {espacio: 77, area: "3x2", vecinos: []},
  {espacio: 78, area: "3x2", vecinos: []},
  {espacio: 79, area: "3x2", vecinos: []},
  {espacio: 80, area: "3x2", vecinos: []},
  {espacio: 81, area: "3x2", vecinos: []},
  {espacio: 82, area: "2x2", vecinos: []},
  {espacio: 83, area: "3x2", vecinos: []},
  {espacio: 84, area: "3x2", vecinos: []},
  {espacio: 85, area: "3x2", vecinos: []},
  {espacio: 86, area: "3x2", vecinos: []},
  {espacio: 87, area: "3x2", vecinos: []},
  {espacio: 88, area: "3x2", vecinos: []},
  {espacio: 89, area: "3x2", vecinos: []},
  {espacio: 90, area: "3x2", vecinos: []},
  {espacio: 91, area: "3x2", vecinos: []},
  {espacio: 92, area: "3x2", vecinos: []},
  {espacio: 93, area: "3x2", vecinos: []},
  {espacio: 94, area: "3x2", vecinos: []},
  {espacio: 95, area: "2x2", vecinos: []},
  {espacio: 96, area: "3x2", vecinos: []},
  {espacio: 97, area: "3x2", vecinos: []},
  {espacio: 98, area: "3x2", vecinos: []},
  {espacio: 99, area: "3x2", vecinos: []},
  {espacio: 100, area: "3x2", vecinos: []},
  {espacio: 101, area: "3x2", vecinos: []},
  {espacio: 102, area: "3x2", vecinos: []},
  {espacio: 103, area: "3x2", vecinos: []},
  {espacio: 104, area: "3x2", vecinos: []},
  {espacio: 105, area: "3x2", vecinos: []},
  {espacio: 106, area: "3x2", vecinos: []},
  {espacio: 107, area: "5x4", vecinos: []},
  {espacio: 108, area: "5x4", vecinos: []},
  {espacio: 109, area: "2x2", vecinos: []},
  {espacio: 110, area: "2x2", vecinos: []},
  {espacio: 111, area: "2x2", vecinos: []},
  {espacio: 112, area: "2x2", vecinos: []},
  {espacio: 113, area: "3x2", vecinos: []},
  {espacio: 114, area: "3x2", vecinos: []},
  {espacio: 115, area: "3x2", vecinos: []},
  {espacio: 116, area: "3x2", vecinos: []},
  {espacio: 117, area: "3x2", vecinos: []},
  {espacio: 118, area: "3x2", vecinos: []},
  {espacio: 119, area: "3x2", vecinos: []},
  {espacio: 120, area: "3x2", vecinos: []},
  {espacio: 121, area: "3x2", vecinos: []},
  {espacio: 122, area: "3x2", vecinos: []},
  {espacio: 123, area: "3x2", vecinos: []},
  {espacio: 124, area: "3x2", vecinos: []},
  {espacio: 125, area: "3x2", vecinos: []},
  {espacio: 126, area: "3x2", vecinos: []},
  {espacio: 127, area: "3x2", vecinos: []},
  {espacio: 128, area: "3x2", vecinos: []},
  {espacio: 129, area: "3x2", vecinos: []},
  {espacio: 130, area: "3x2", vecinos: []},
  {espacio: 131, area: "3x2", vecinos: []},
  {espacio: 132, area: "3x2", vecinos: []},
  {espacio: 133, area: "3x2", vecinos: []},
  {espacio: 134, area: "3x2", vecinos: []},
  {espacio: 135, area: "3x2", vecinos: []},
  {espacio: 136, area: "3x2", vecinos: []},
  {espacio: 137, area: "3x2", vecinos: []},
  {espacio: 138, area: "3x2", vecinos: []},
  {espacio: 139, area: "3x2", vecinos: []},
  {espacio: 140, area: "3x2", vecinos: []},
  {espacio: 141, area: "3x2", vecinos: []},
  {espacio: 142, area: "3x2", vecinos: []},
  {espacio: 143, area: "3x2", vecinos: []},
  {espacio: 144, area: "3x2", vecinos: []},
  {espacio: 145, area: "3x2", vecinos: []},
  {espacio: 146, area: "3x2", vecinos: []},
  {espacio: 147, area: "3x2", vecinos: []},
  {espacio: 148, area: "3x2", vecinos: []},
  {espacio: 149, area: "3x2", vecinos: []},
  {espacio: 150, area: "3x2", vecinos: []},
  {espacio: 151, area: "3x2", vecinos: []},
  {espacio: 152, area: "3x2", vecinos: []},
  {espacio: 153, area: "3x2", vecinos: []},
  {espacio: 154, area: "3x2", vecinos: []},
  {espacio: 155, area: "3x2", vecinos: []},
  {espacio: 156, area: "3x2", vecinos: []},
  {espacio: 157, area: "3x2", vecinos: []},
  {espacio: 158, area: "3x2", vecinos: []},
  {espacio: 159, area: "3x2", vecinos: []},
  {espacio: 160, area: "4x2", vecinos: []},
  {espacio: 161, area: "3x2", vecinos: []},
  {espacio: 162, area: "3x2", vecinos: []},
  {espacio: 163, area: "3x2", vecinos: []},
  {espacio: 164, area: "3x2", vecinos: []},
  {espacio: 165, area: "3x2", vecinos: []},
  {espacio: 166, area: "3x2", vecinos: []},
  {espacio: 167, area: "3x2", vecinos: []},
  {espacio: 168, area: "3x2", vecinos: []},
  {espacio: 169, area: "3x2", vecinos: []},
  {espacio: 170, area: "3x2", vecinos: []},
  {espacio: 171, area: "3x2", vecinos: []},
  {espacio: 172, area: "3x2", vecinos: []},
  {espacio: 173, area: "3x2", vecinos: []},
  {espacio: 174, area: "3x2", vecinos: []},
  {espacio: 175, area: "3x2", vecinos: []},
  {espacio: 176, area: "3x2", vecinos: []},
  {espacio: 177, area: "3x2", vecinos: []},
  {espacio: 178, area: "3x2", vecinos: []},
  {espacio: 179, area: "3x2", vecinos: []},
  {espacio: 180, area: "3x2", vecinos: []},
  {espacio: 181, area: "3x2", vecinos: []},
  {espacio: 182, area: "3x2", vecinos: []},
  {espacio: 183, area: "3x2", vecinos: []},
  {espacio: 184, area: "3x2", vecinos: []},
];

export const PAISES = [
  {value:'AD', id:	'AD', label:	'Andorra'},
  {value:'AE', id:	'AE', label:	'Emiratos Árabes Unidos'},
  {value:'AF', id:	'AF', label:	'Afganistán'},
  {value:'AG', id:	'AG', label:	'Antigua y Barbuda'},
  {value:'AI', id:	'AI', label:	'Anguila'},
  {value:'AL', id:	'AL', label:	'Albania'},
  {value:'AM', id:	'AM', label:	'Armenia'},
  {value:'AN', id:	'AN', label:	'Antillas Neerlandesas'},
  {value:'AO', id:	'AO', label:	'Angola'},
  {value:'AQ', id:	'AQ', label:	'Antártida'},
  {value:'AR', id:	'AR', label:	'Argentina'},
  {value:'AS', id:	'AS', label:	'Samoa Americana'},
  {value:'AT', id:	'AT', label:	'Austria'},
  {value:'AU', id:	'AU', label:	'Australia'},
  {value:'AW', id:	'AW', label:	'Aruba'},
  {value:'AX', id:	'AX', label:	'Islas Áland'},
  {value:'AZ', id:	'AZ', label:	'Azerbaiyán'},
  {value:'BA', id:	'BA', label:	'Bosnia y Herzegovina'},
  {value:'BB', id:	'BB', label:	'Barbados'},
  {value:'BD', id:	'BD', label:	'Bangladesh'},
  {value:'BE', id:	'BE', label:	'Bélgica'},
  {value:'BF', id:	'BF', label:	'Burkina Faso'},
  {value:'BG', id:	'BG', label:	'Bulgaria'},
  {value:'BH', id:	'BH', label:	'Bahréin'},
  {value:'BI', id:	'BI', label:	'Burundi'},
  {value:'BJ', id:	'BJ', label:	'Benin'},
  {value:'BL', id:	'BL', label:	'San Bartolomé'},
  {value:'BM', id:	'BM', label:	'Bermudas'},
  {value:'BN', id:	'BN', label:	'Brunéi'},
  {value:'BO', id:	'BO', label:	'Bolivia'},
  {value:'BR', id:	'BR', label:	'Brasil'},
  {value:'BS', id:	'BS', label:	'Bahamas'},
  {value:'BT', id:	'BT', label:	'Bhután'},
  {value:'BV', id:	'BV', label:	'Isla Bouvet'},
  {value:'BW', id:	'BW', label:	'Botsuana'},
  {value:'BY', id:	'BY', label:	'Belarús'},
  {value:'BZ', id:	'BZ', label:	'Belice'},
  {value:'CA', id:	'CA', label:	'Canadá'},
  {value:'CC', id:	'CC', label:	'Islas Cocos'},
  {value:'CF', id:	'CF', label:	'República Centro-Africana'},
  {value:'CG', id:	'CG', label:	'Congo'},
  {value:'CH', id:	'CH', label:	'Suiza'},
  {value:'CI', id:	'CI', label:	'Costa de Marfil'},
  {value:'CK', id:	'CK', label:	'Islas Cook'},
  {value:'CL', id:	'CL', label:	'Chile'},
  {value:'CM', id:	'CM', label:	'Camerún'},
  {value:'CN', id:	'CN', label:	'China'},
  {value:'CO', id:	'CO', label:	'Colombia'},
  {value:'CR', id:	'CR', label:	'Costa Rica'},
  {value:'CU', id:	'CU', label:	'Cuba'},
  {value:'CV', id:	'CV', label:	'Cabo Verde'},
  {value:'CX', id:	'CX', label:	'Islas Christmas'},
  {value:'CY', id:	'CY', label:	'Chipre'},
  {value:'CZ', id:	'CZ', label:	'República Checa'},
  {value:'DE', id:	'DE', label:	'Alemania'},
  {value:'DJ', id:	'DJ', label:	'Yibuti'},
  {value:'DK', id:	'DK', label:	'Dinamarca'},
  {value:'DM', id:	'DM', label:	'Domínica'},
  {value:'DO', id:	'DO', label:	'República Dominicana'},
  {value:'DZ', id:	'DZ', label:	'Argel'},
  {value:'EC', id:	'EC', label:	'Ecuador'},
  {value:'EE', id:	'EE', label:	'Estonia'},
  {value:'EG', id:	'EG', label:	'Egipto'},
  {value:'EH', id:	'EH', label:	'Sahara Occidental'},
  {value:'ER', id:	'ER', label:	'Eritrea'},
  {value:'ES', id:	'ES', label:	'España'},
  {value:'ET', id:	'ET', label:	'Etiopía'},
  {value:'FI', id:	'FI', label:	'Finlandia'},
  {value:'FJ', id:	'FJ', label:	'Fiji'},
  {value:'FK', id:	'FK', label:	'Islas Malvinas'},
  {value:'FM', id:	'FM', label:	'Micronesia'},
  {value:'FO', id:	'FO', label:	'Islas Faroe'},
  {value:'FR', id:	'FR', label:	'Francia'},
  {value:'GA', id:	'GA', label:	'Gabón'},
  {value:'GB', id:	'GB', label:	'Reino Unido'},
  {value:'GD', id:	'GD', label:	'Granada'},
  {value:'GE', id:	'GE', label:	'Georgia'},
  {value:'GF', id:	'GF', label:	'Guayana Francesa'},
  {value:'GG', id:	'GG', label:	'Guernsey'},
  {value:'GH', id:	'GH', label:	'Ghana'},
  {value:'GI', id:	'GI', label:	'Gibraltar'},
  {value:'GL', id:	'GL', label:	'Groenlandia'},
  {value:'GM', id:	'GM', label:	'Gambia'},
  {value:'GN', id:	'GN', label:	'Guinea'},
  {value:'GP', id:	'GP', label:	'Guadalupe'},
  {value:'GQ', id:	'GQ', label:	'Guinea Ecuatorial'},
  {value:'GR', id:	'GR', label:	'Grecia'},
  {value:'GS', id:	'GS', label:	'Georgia del Sur e Islas Sandwich del Sur'},
  {value:'GT', id:	'GT', label:	'Guatemala'},
  {value:'GU', id:	'GU', label:	'Guam'},
  {value:'GW', id:	'GW', label:	'Guinea-Bissau'},
  {value:'GY', id:	'GY', label:	'Guayana'},
  {value:'HK', id:	'HK', label:	'Hong Kong'},
  {value:'HM', id:	'HM', label:	'Islas Heard y McDonald'},
  {value:'HN', id:	'HN', label:	'Honduras'},
  {value:'HR', id:	'HR', label:	'Croacia'},
  {value:'HT', id:	'HT', label:	'Haití'},
  {value:'HU', id:	'HU', label:	'Hungría'},
  {value:'ID', id:	'ID', label:	'Indonesia'},
  {value:'IE', id:	'IE', label:	'Irlanda'},
  {value:'IL', id:	'IL', label:	'Israel'},
  {value:'IM', id:	'IM', label:	'Isla de Man'},
  {value:'IN', id:	'IN', label:	'India'},
  {value:'IO', id:	'IO', label:	'Territorio Británico del Océano Índico'},
  {value:'IQ', id:	'IQ', label:	'Irak'},
  {value:'IR', id:	'IR', label:	'Irán'},
  {value:'IS', id:	'IS', label:	'Islandia'},
  {value:'IT', id:	'IT', label:	'Italia'},
  {value:'JE', id:	'JE', label:	'Jersey'},
  {value:'JM', id:	'JM', label:	'Jamaica'},
  {value:'JO', id:	'JO', label:	'Jordania'},
  {value:'JP', id:	'JP', label:	'Japón'},
  {value:'KE', id:	'KE', label:	'Kenia'},
  {value:'KG', id:	'KG', label:	'Kirguistán'},
  {value:'KH', id:	'KH', label:	'Camboya'},
  {value:'KI', id:	'KI', label:	'Kiribati'},
  {value:'KM', id:	'KM', label:	'Comoros'},
  {value:'KN', id:	'KN', label:	'San Cristóbal y Nieves'},
  {value:'KP', id:	'KP', label:	'Corea del Norte'},
  {value:'KR', id:	'KR', label:	'Corea del Sur'},
  {value:'KW', id:	'KW', label:	'Kuwait'},
  {value:'KY', id:	'KY', label:	'Islas Caimán'},
  {value:'KZ', id:	'KZ', label:	'Kazajstán'},
  {value:'LA', id:	'LA', label:	'Laos'},
  {value:'LB', id:	'LB', label:	'Líbano'},
  {value:'LC', id:	'LC', label:	'Santa Lucía'},
  {value:'LI', id:	'LI', label:	'Liechtenstein'},
  {value:'LK', id:	'LK', label:	'Sri Lanka'},
  {value:'LR', id:	'LR', label:	'Liberia'},
  {value:'LS', id:	'LS', label:	'Lesotho'},
  {value:'LT', id:	'LT', label:	'Lituania'},
  {value:'LU', id:	'LU', label:	'Luxemburgo'},
  {value:'LV', id:	'LV', label:	'Letonia'},
  {value:'LY', id:	'LY', label:	'Libia'},
  {value:'MA', id:	'MA', label:	'Marruecos'},
  {value:'MC', id:	'MC', label:	'Mónaco'},
  {value:'MD', id:	'MD', label:	'Moldova'},
  {value:'ME', id:	'ME', label:	'Montenegro'},
  {value:'MG', id:	'MG', label:	'Madagascar'},
  {value:'MH', id:	'MH', label:	'Islas Marshall'},
  {value:'MK', id:	'MK', label:	'Macedonia'},
  {value:'ML', id:	'ML', label:	'Mali'},
  {value:'MM', id:	'MM', label:	'Myanmar'},
  {value:'MN', id:	'MN', label:	'Mongolia'},
  {value:'MO', id:	'MO', label:	'Macao'},
  {value:'MQ', id:	'MQ', label:	'Martinica'},
  {value:'MR', id:	'MR', label:	'Mauritania'},
  {value:'MS', id:	'MS', label:	'Montserrat'},
  {value:'MT', id:	'MT', label:	'Malta'},
  {value:'MU', id:	'MU', label:	'Mauricio'},
  {value:'MV', id:	'MV', label:	'Maldivas'},
  {value:'MW', id:	'MW', label:	'Malawi'},
  {value:'MX', id:	'MX', label:	'México'},
  {value:'MY', id:	'MY', label:	'Malasia'},
  {value:'MZ', id:	'MZ', label:	'Mozambique'},
  {value:'NA', id:	'NA', label:	'Namibia'},
  {value:'NC', id:	'NC', label:	'Nueva Caledonia'},
  {value:'NE', id:	'NE', label:	'Níger'},
  {value:'NF', id:	'NF', label:	'Islas Norkfolk'},
  {value:'NG', id:	'NG', label:	'Nigeria'},
  {value:'NI', id:	'NI', label:	'Nicaragua'},
  {value:'NL', id:	'NL', label:	'Países Bajos'},
  {value:'NO', id:	'NO', label:	'Noruega'},
  {value:'NP', id:	'NP', label:	'Nepal'},
  {value:'NR', id:	'NR', label:	'Nauru'},
  {value:'NU', id:	'NU', label:	'Niue'},
  {value:'NZ', id:	'NZ', label:	'Nueva Zelanda'},
  {value:'OM', id:	'OM', label:	'Omán'},
  {value:'PA', id:	'PA', label:	'Panamá'},
  {value:'PE', id:	'PE', label:	'Perú'},
  {value:'PF', id:	'PF', label:	'Polinesia Francesa'},
  {value:'PG', id:	'PG', label:	'Papúa Nueva Guinea'},
  {value:'PH', id:	'PH', label:	'Filipinas'},
  {value:'PK', id:	'PK', label:	'Pakistán'},
  {value:'PL', id:	'PL', label:	'Polonia'},
  {value:'PM', id:	'PM', label:	'San Pedro y Miquelón'},
  {value:'PN', id:	'PN', label:	'Islas Pitcairn'},
  {value:'PR', id:	'PR', label:	'Puerto Rico'},
  {value:'PS', id:	'PS', label:	'Palestina'},
  {value:'PT', id:	'PT', label:	'Portugal'},
  {value:'PW', id:	'PW', label:	'Islas Palaos'},
  {value:'PY', id:	'PY', label:	'Paraguay'},
  {value:'QA', id:	'QA', label:	'Qatar'},
  {value:'RE', id:	'RE', label:	'Reunión'},
  {value:'RO', id:	'RO', label:	'Rumanía'},
  {value:'RS', id:	'RS', label:	'Serbia y Montenegro'},
  {value:'RU', id:	'RU', label:	'Rusia'},
  {value:'RW', id:	'RW', label:	'Ruanda'},
  {value:'SA', id:	'SA', label:	'Arabia Saudita'},
  {value:'SB', id:	'SB', label:	'Islas Solomón'},
  {value:'SC', id:	'SC', label:	'Seychelles'},
  {value:'SD', id:	'SD', label:	'Sudán'},
  {value:'SE', id:	'SE', label:	'Suecia'},
  {value:'SG', id:	'SG', label:	'Singapur'},
  {value:'SH', id:	'SH', label:	'Santa Elena'},
  {value:'SI', id:	'SI', label:	'Eslovenia'},
  {value:'SJ', id:	'SJ', label:	'Islas Svalbard y Jan Mayen'},
  {value:'SK', id:	'SK', label:	'Eslovaquia'},
  {value:'SL', id:	'SL', label:	'Sierra Leona'},
  {value:'SM', id:	'SM', label:	'San Marino'},
  {value:'SN', id:	'SN', label:	'Senegal'},
  {value:'SO', id:	'SO', label:	'Somalia'},
  {value:'SR', id:	'SR', label:	'Surinam'},
  {value:'ST', id:	'ST', label:	'Santo Tomé y Príncipe'},
  {value:'SV', id:	'SV', label:	'El Salvador'},
  {value:'SY', id:	'SY', label:	'Siria'},
  {value:'SZ', id:	'SZ', label:	'Suazilandia'},
  {value:'TC', id:	'TC', label:	'Islas Turcas y Caicos'},
  {value:'TD', id:	'TD', label:	'Chad'},
  {value:'TF', id:	'TF', label:	'Territorios Australes Franceses'},
  {value:'TG', id:	'TG', label:	'Togo'},
  {value:'TH', id:	'TH', label:	'Tailandia'},
  {value:'TH', id:	'TH', label:	'Tanzania'},
  {value:'TJ', id:	'TJ', label:	'Tayikistán'},
  {value:'TK', id:	'TK', label:	'Tokelau'},
  {value:'TL', id:	'TL', label:	'Timor-Leste'},
  {value:'TM', id:	'TM', label:	'Turkmenistán'},
  {value:'TN', id:	'TN', label:	'Túnez'},
  {value:'TO', id:	'TO', label:	'Tonga'},
  {value:'TR', id:	'TR', label:	'Turquía'},
  {value:'TT', id:	'TT', label:	'Trinidad y Tobago'},
  {value:'TV', id:	'TV', label:	'Tuvalu'},
  {value:'TW', id:	'TW', label:	'Taiwán'},
  {value:'UA', id:	'UA', label:	'Ucrania'},
  {value:'UG', id:	'UG', label:	'Uganda'},
  {value:'US', id:	'US', label:	'Estados Unidos de América'},
  {value:'UY', id:	'UY', label:	'Uruguay'},
  {value:'UZ', id:	'UZ', label:	'Uzbekistán'},
  {value:'VA', id:	'VA', label:	'Ciudad del Vaticano'},
  {value:'VC', id:	'VC', label:	'San Vicente y las Granadinas'},
  {value:'VE', id:	'VE', label:	'Venezuela'},
  {value:'VG', id:	'VG', label:	'Islas Vírgenes Británicas'},
  {value:'VI', id:	'VI', label:	'Islas Vírgenes de los Estados Unidos de América'},
  {value:'VN', id:	'VN', label:	'Vietnam'},
  {value:'VU', id:	'VU', label:	'Vanuatu'},
  {value:'WF', id:	'WF', label:	'Wallis y Futuna'},
  {value:'WS', id:	'WS', label:	'Samoa'},
  {value:'YE', id:	'YE', label:	'Yemen'},
  {value:'YT', id:	'YT', label:	'Mayotte'},
  {value:'ZA', id:	'ZA', label:	'Sudáfrica'},
];

let año = [];
const actual = new Date();
const año_actual = actual.getFullYear();
for(var i=año_actual; i < año_actual+10;i++){
    año.push({value: i,id:i,label:i})
}

export const AÑOS = año;

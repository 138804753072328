import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/tienda/cuenta';
import Entradas from './Entradas';


const ms2p = (state) => {
  return {
    ...state.cuenta,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Entradas);

import React, { Component } from 'react';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import _ from "lodash";
import { Link } from "react-router-dom";
import {TIPOS_ENTRADA} from "../../../../../utility/variables";
const placeholder = require('assets/img/img_placeholder.png');
import "./detalle.css";

class Detalle extends Component {
  
  constructor(props) {
    super(props);
    
  }

  componentWillMount() {
    this.props.verProducto(this.props.match.params.id);
  }

  getTipoEntrada(tipo) {
    return _.find(TIPOS_ENTRADA, {id:tipo}).label;
  }

  render() {
    const { producto } = this.props;
    const descripcion = "Sin descripción";
    const descripcion_ingles= "Not description";
    const texto = "Sin texto";
    const texto_ingles = "Not text";

    return (
      <div id="detalle-productos-admin">
        <div className="">
          <div className="p-2 encabezado-azul"><h1>Detalle de producto</h1></div>
          <div className="p-2 p-sm-4">
            <div className="d-flex flex-column p-2">
              <div className="d-flex align-items-center justify-content-sm-start flex-column flex-sm-row flex-wrap">
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Nombre</h5></div>
                  <div className="p-2">{producto.nombre}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Venta al público</h5></div>
                  <div className="p-2">{producto.vendible ? 'Si' : 'No'}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Centro Costo</h5></div>
                  <div className="p-2">{producto.centro_costo}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Código Centro Costo</h5></div>
                  <div className="p-2">{producto.codigo_centro_costo}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Material</h5></div>
                  <div className="p-2">{producto.material}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Orden</h5></div>
                  <div className="p-2">{producto.orden}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Texto Comercial</h5></div>
                  <div className="p-2">{producto.texto_comercial ? producto.texto_comercial : texto}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Texto Comercial Inglés</h5></div>
                  <div className="p-2">{producto.texto_comercial_ingles ? producto.texto_comercial_ingles : texto_ingles}</div>
                </div>
                {producto.es_entrada && (<div className="d-flex align-self-start contenido flex-column form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Tipo Producto</h5></div>
                  <div className="p-2">{this.getTipoEntrada(producto.tipo_entrada)}</div>
                </div>)}
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Descripción</h5></div>
                  <div className="p-2">{producto.descripcion ? producto.descripcion : descripcion}</div>
                </div>
                <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Descripción Inglés </h5></div>
                  <div className="p-2">{producto.descripcion_ingles ? producto.descripcion_ingles : descripcion_ingles}</div>
                </div>
                {!producto.es_entrada && (<div className="d align-self-start-flex contenido flex-column form-group flex-1 m-2 w-100 ">
                  <div className="p-2"><h5>Foto</h5></div>
                  <div className="p-2"><img src={producto.foto ? producto.foto : placeholder} alt="" /></div>
                </div>)}
              </div>
              <div className="d-flex flex-column flex-sm-row mt-3">
                <div className="p-2"><h3>Precios</h3></div>
                <div className="p-sm-2 w-100"><hr /></div>
              </div>                            
              <div className="d-flex flex-column flex-sm-row justify-content-md-between justify-content-center flex-wrap">
                {
                  producto.precios.map((precio)=>{
                    return(
                      <div key={precio.id} className="p-2">
                        <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                            <div className="p-2"><h5>Lista de Precios Para</h5></div>
                            <div className="p-2">{precio.lista_precios}</div>
                        </div>
                        <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                            <div className="p-2"><h5>Codigo de Lista</h5></div>
                            <div className="p-2">{precio.codigo_lista}</div>
                        </div>
                        <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                            <div className="p-2"><h5>Codgio de Moneda</h5></div>
                            <div className="p-2">{precio.codigo_moneda}</div>
                        </div>
                        <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                            <div className="p-2"><h5>Importe</h5></div>
                            <div className="d-flex flex-column align-items-end">
                              <RenderCurrency className="precio p-2" value={precio.importe}  prefix={precio.codigo_moneda ==="USD" ? "$":"Q" } />
                            </div>
                        </div>
                        <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                            <div className="p-2"><h5>Nombre de Material</h5></div>
                            <div className="p-2">{precio.material_name}</div>
                        </div>
                        <div className="d-flex contenido flex-column align-self-start form-group flex-1 m-2 w-100 ">
                            <div className="p-2"><h5>Moneda</h5></div>
                            <div className="p-2">{precio.moneda}</div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className="d-flex flex-colum justify-content-center">
                <Link to="/administrar_productos" className="btn btn-default m-3">Regresar</Link><br />
              </div>
            </div>                        
          </div>
        </div>
      </div>
    )
  }
}

export default Detalle

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/cuenta';
import Datos from './Datos';


const ms2p = (state) => {
  let photo;
  try {
    photo = state.form.PerfilForm.values.foto;
  } catch (e) {}
  return {
    ...state.cuenta,
    photo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Datos);

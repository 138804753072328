import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./ball-pulse.css";

class Loader extends Component {
  static propTypes = {
    color: PropTypes.string,
    active: PropTypes.bool.isRequired,
  };

  render() {
    const { active, small } = this.props;
    const color = this.props.color ? this.props.color : "#fff";

    if (active)
      return (
        <div style={{color, display: active ? "inline-block" : "none"}} className={`la-ball-pulse ${!!small ? "ml-2 mr-2" : "ml-2 mr-2 la-2x"}`}>
          <div />
          <div />
          <div />
        </div>
      );
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default Loader;

import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tienda/stand';
import { setModal, setAccionModal } from "../../../../../redux/modules/tienda/cuenta";
import Entradas from "./EntradasStand";


const ms2p = (state) => {
  const loadingLoader = state.stand.loading!==0;
    return {
    ...state.stand,
    loadingLoader,
    usuario: state.cuenta.usuario,
  };
};

const md2p = { ...actions, setAccionModal, setModal };

export default connect(ms2p, md2p)(Entradas);
